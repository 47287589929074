import { z } from "zod";

import { orgManagerRoleSchema } from "api/clients/manager";
import { type ApiResource, makeApiResource } from "./resource";

export const appModeSchema = z.enum(["GIGSTER", "STAFFER", "BOTH"]);
export const APP_MODE = appModeSchema.Enum;

const userRoleSchema = z.enum(["TEAM_MEMBER", "ADMIN"]);
export type UserRole = z.infer<typeof userRoleSchema>;

export const userSchema = makeApiResource({
  app_mode: appModeSchema.optional().catch(() => undefined),
  username: z.string(),
  phone_number: z
    .string()
    .optional()
    .catch(() => undefined),
  email: z
    .string()
    .optional()
    .catch(() => undefined),
  first_name: z.string(),
  last_name: z.string(),
  is_gigpro_admin: z.boolean().optional(),
  settings: z.object({ sms_opt_in: z.boolean().optional() }).nullish(),
  licenses: z.array(
    z.object({
      license_name: z.enum(["marketplace", "hire"]),
      license_id: z.number(),
      location_id: z.number(),
    }),
  ),
  managed_locations: z.array(
    z.object({
      id: z.number(),
      role: userRoleSchema,
      location_id: z.number(),
    }),
  ),
  organization_roles: z
    .array(
      z.object({
        role: orgManagerRoleSchema,
        organization_id: z.string(),
      }),
    )
    .nullish(),
  is_sso_login: z.boolean().optional(),
});

export const linkableAccountSchema = z.object({ connection: z.string() });

export type LicenseName = User["licenses"][number]["license_name"];

export const hasLicense = ({ licenses }: User, locationId: number | undefined, licenseName: LicenseName) =>
  licenses.some((license) => license.license_name === licenseName && license.location_id === locationId);

export type User = z.infer<typeof userSchema>;
export type LegacyUser = Omit<User, "id" | "licenses" | "managed_locations"> & { id: "me" | number };
export type UserSetting = ApiResource & User["settings"];
