import React from "react";

import type { IconProps } from "./types";

export const ExternalLink = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16675 5.83341C3.94573 5.83341 3.73377 5.92121 3.57749 6.07749C3.42121 6.23377 3.33341 6.44573 3.33341 6.66675V15.8334C3.33341 16.0544 3.42121 16.2664 3.57749 16.4227C3.73377 16.579 3.94573 16.6667 4.16675 16.6667H13.3334C13.5544 16.6667 13.7664 16.579 13.9227 16.4227C14.079 16.2664 14.1667 16.0544 14.1667 15.8334V10.8334C14.1667 10.3732 14.5398 10.0001 15.0001 10.0001C15.4603 10.0001 15.8334 10.3732 15.8334 10.8334V15.8334C15.8334 16.4965 15.57 17.1323 15.1012 17.6012C14.6323 18.07 13.9965 18.3334 13.3334 18.3334H4.16675C3.50371 18.3334 2.86782 18.07 2.39898 17.6012C1.93014 17.1323 1.66675 16.4965 1.66675 15.8334V6.66675C1.66675 6.00371 1.93014 5.36782 2.39898 4.89898C2.86782 4.43014 3.50371 4.16675 4.16675 4.16675H9.16675C9.62699 4.16675 10.0001 4.53984 10.0001 5.00008C10.0001 5.46032 9.62699 5.83341 9.16675 5.83341H4.16675Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 2.50008C11.6667 2.03984 12.0398 1.66675 12.5001 1.66675H17.5001C17.9603 1.66675 18.3334 2.03984 18.3334 2.50008V7.50008C18.3334 7.96032 17.9603 8.33341 17.5001 8.33341C17.0398 8.33341 16.6667 7.96032 16.6667 7.50008V3.33341H12.5001C12.0398 3.33341 11.6667 2.96032 11.6667 2.50008Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0893 1.91083C18.4147 2.23626 18.4147 2.7639 18.0893 3.08934L8.92259 12.256C8.59715 12.5814 8.06951 12.5814 7.74408 12.256C7.41864 11.9306 7.41864 11.4029 7.74408 11.0775L16.9107 1.91083C17.2362 1.58539 17.7638 1.58539 18.0893 1.91083Z"
        fill={color}
      />
    </svg>
  );
});
ExternalLink.displayName = "ExternalLink";
