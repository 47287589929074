import { z } from "zod";

export const networkProSchema = z.object({
  id: z.string(),
  organization_id: z.string(),
  pro: z.object({
    uuid: z.string(),
    profile_id: z.number().nullish(),
    user_id: z.number().nullish(),
    bio: z.string().nullish(),
    headshot_public_id: z.string().nullish(),
    resume_public_id: z.string().nullish(),
    display_name: z.string(),
    pro_preferences: z
      .object({
        pro_contractor_type: z.array(
          z.object({
            name: z.string(),
          }),
        ),
      })
      .nullish(),
  }),
});
