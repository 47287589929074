import type { QueryClient } from "@tanstack/react-query";
import { type LDClient, type LDContext, type LDOptions, initialize } from "launchdarkly-react-client-sdk";

import { authManagerQueryOptions, authUserQueryOptions } from "api/clients/users";
import { locationQueryOptions } from "api/queries/locations";
import type { Location } from "api/resources/Location";
import { env } from "config/env";
import { getActiveLocationId } from "services/globalState";
import { type AnalyticsUserTraits, getAnalyticsUserTraits } from "utils/analytics";

import { type FeatureFlagKey, type FeatureFlagValue, defaultFlags } from "./featureFlags";

let bootstrap: LDOptions["bootstrap"] = "localStorage";
// Allow QA to set feature flags
if (env.REACT_APP_E2E_LAUNCH_DARKLY_FLAG_SOURCE != null) {
  const flagsRaw = localStorage.getItem(env.REACT_APP_E2E_LAUNCH_DARKLY_FLAG_SOURCE);
  if (flagsRaw != null) bootstrap = JSON.parse(flagsRaw);
}

export const context: LDContext = { anonymous: true };
export const options: LDOptions = { fetchGoals: false, bootstrap, sendEvents: !env.IS_LOCAL };

export const ldClientPromise = new Promise<LDClient>((resolve) => {
  const client = initialize(env.REACT_APP_LAUNCH_DARKLY_ID, context, options);
  client.waitUntilReady().then(() => resolve(client));
});

type IdentifyParams = {
  user: AnalyticsUserTraits;
  location?: Location;
};

export const identify = async ({ user, location }: IdentifyParams) => {
  if (!env.REACT_APP_LAUNCH_DARKLY_ID) return;
  const ldClient = await ldClientPromise;
  return await ldClient.identify({
    kind: "multi",
    user: {
      key: `${user.id}`,
      name: `${user.firstName} ${user.lastName}`,
      ...user,
      environment: env.REACT_APP_ENV,
    },
    location:
      location ?
        {
          key: location?.id,
          name: location?.name,
          market_id: location?.market_id,
          organization_id: location?.organization_id,
        }
      : undefined,
  });
};

export const getFeatureFlag = async <FlagKey extends FeatureFlagKey>(
  flagKey: FlagKey,
  queryClient: QueryClient,
): Promise<FeatureFlagValue[FlagKey]> => {
  try {
    const activeLocationId = getActiveLocationId();
    const [client, user, manager, location] = await Promise.all([
      ldClientPromise,
      queryClient.fetchQuery(authUserQueryOptions),
      queryClient.fetchQuery(authManagerQueryOptions),
      activeLocationId ? queryClient.fetchQuery(locationQueryOptions(activeLocationId)) : Promise.resolve(undefined),
    ]);
    if (!client.getContext() || client.getContext().anonymous) {
      await identify({ user: getAnalyticsUserTraits(user, manager), location });
    }
    return client.variation(flagKey, defaultFlags[flagKey]);
  } catch (e) {
    return defaultFlags[flagKey];
  }
};
