import React from "react";

import type { IconProps } from "./types";

export const Message = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M15.0117 11.25C15.0117 11.4609 14.9297 11.6367 14.7656 11.7773C14.625 11.918 14.4492 11.9883 14.2383 11.9883H5.23828C5.05078 11.9883 4.875 12.0703 4.71094 12.2344L2.98828 13.957V3.76172C2.98828 3.55078 3.05859 3.375 3.19922 3.23438C3.36328 3.07031 3.55078 2.98828 3.76172 2.98828H14.2383C14.4492 2.98828 14.625 3.07031 14.7656 3.23438C14.9297 3.375 15.0117 3.55078 15.0117 3.76172V11.25ZM16.4883 11.25V3.76172C16.4883 3.12891 16.2656 2.60156 15.8203 2.17969C15.3984 1.73438 14.8711 1.51172 14.2383 1.51172H3.76172C3.12891 1.51172 2.58984 1.73438 2.14453 2.17969C1.72266 2.60156 1.51172 3.12891 1.51172 3.76172V15.75C1.51172 15.9609 1.58203 16.1367 1.72266 16.2773C1.86328 16.418 2.03906 16.4883 2.25 16.4883C2.46094 16.4883 2.63672 16.418 2.77734 16.2773L5.55469 13.5H14.2383C14.8711 13.5 15.3984 13.2773 15.8203 12.832C16.2656 12.3867 16.4883 11.8594 16.4883 11.25Z"
        fill={color}
      />
    </svg>
  );
});
Message.displayName = "Message";
