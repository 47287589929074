"use client";

import type { FieldPath, FieldPathValue, FieldValues } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "..";
import { DatePicker, type DatePickerProps } from "../../DatePicker";
import type { FormFieldProps, Transform } from "../types";

export const DateField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  optional,
  placeholder,
  className,
  transform,
  name,
  control,
  shouldUnregister,
  disabled,
  ...datePickerProps
}: FormFieldProps<TFieldValues, TName, Transform<Date | undefined, FieldPathValue<TFieldValues, TName>>> &
  Partial<DatePickerProps>) => {
  return (
    <FormField
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          <FormLabel optional={optional}>{label}</FormLabel>
          <FormControl>
            <DatePicker
              {...datePickerProps}
              value={transform && field.value != null ? transform.input(field.value) : field.value}
              onValueChange={transform ? (value) => field.onChange(transform.output(value)) : field.onChange}
              invalid={!!fieldState.error}
              placeholder={placeholder}
            />
          </FormControl>
          {!!description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

DateField.displayName = "DateField";
