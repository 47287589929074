import React from "react";

import type { IconProps } from "./types";

export const Phone = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M15.75 14.9414C15.75 15.1523 15.6679 15.3398 15.5039 15.5039C15.3398 15.6445 15.1523 15.7031 14.9414 15.6797C12.7148 15.4453 10.664 14.7188 8.78904 13.5C7.05467 12.3984 5.6367 10.9805 4.53513 9.24609C3.31638 7.34766 2.5781 5.28516 2.32029 3.05859C2.32029 2.91797 2.35545 2.78906 2.42576 2.67188C2.49607 2.53125 2.58982 2.42578 2.70701 2.35547C2.8242 2.28516 2.9531 2.25 3.09373 2.25H5.34373C5.53123 2.25 5.69529 2.32031 5.83591 2.46094C5.97654 2.57812 6.05857 2.71875 6.08201 2.88281C6.1992 3.65625 6.3867 4.41797 6.64451 5.16797C6.7617 5.47266 6.71482 5.73047 6.50388 5.94141L5.55466 6.89062C5.27341 7.17188 5.22654 7.47656 5.41404 7.80469C6.56248 9.84375 8.15623 11.4375 10.1953 12.5859C10.5234 12.7734 10.8281 12.7266 11.1094 12.4453L12.0586 11.4961C12.2695 11.2852 12.5273 11.2383 12.832 11.3555C13.582 11.6133 14.3437 11.8008 15.1172 11.918C15.5156 11.9648 15.7265 12.2227 15.75 12.6914V14.9414ZM13.3594 9.94922C12.914 9.78516 12.4804 9.76172 12.0586 9.87891C11.6367 9.97266 11.2851 10.1602 11.0039 10.4414L10.4414 11.0039C9.05857 10.0898 7.91013 8.94141 6.99607 7.55859L7.55857 6.99609C7.83982 6.71484 8.02732 6.36328 8.12107 5.94141C8.23826 5.51953 8.21482 5.08594 8.05076 4.64062C7.81638 4.00781 7.65232 3.35156 7.55857 2.67188C7.48826 2.13281 7.24216 1.67578 6.82029 1.30078C6.39841 0.925781 5.90623 0.738281 5.34373 0.738281H3.09373C2.43748 0.738281 1.87498 0.984375 1.40623 1.47656C0.960915 1.96875 0.773415 2.54297 0.843728 3.19922C1.10154 5.68359 1.91013 7.96875 3.26951 10.0547C4.48826 11.9766 6.05857 13.5469 7.98045 14.7656C10.0664 16.1016 12.3398 16.9102 14.8008 17.1914C15.2461 17.2383 15.6562 17.1562 16.0312 16.9453C16.4062 16.7578 16.6992 16.4766 16.9101 16.1016C17.1445 15.75 17.2617 15.3633 17.2617 14.9414V12.6562C17.2617 12.0938 17.0742 11.6016 16.6992 11.1797C16.3242 10.7578 15.8554 10.5 15.2929 10.4062C14.6367 10.3359 13.9922 10.1836 13.3594 9.94922Z"
        fill={color}
      />
    </svg>
  );
});
Phone.displayName = "Phone";
