import * as React from "react";
import { cn } from "tailwind-config";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Apply error styling to the input.
   */
  invalid?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, disabled, invalid, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex max-h-48 min-h-[80px] w-full rounded border-2 bg-white px-3 py-2 text-large outline-none placeholder:text-mediumGray focus:border-brand-purple disabled:cursor-not-allowed disabled:opacity-50",
          {
            "bg-lightBlue": disabled,
            /**
             * Order of the border color definitions below matters.
             * Focused should take priority over default, and error should take priority over both.
             */
            "border-mediumGray": !invalid || disabled,
            "border-error": invalid && !disabled,
          },
          className,
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
