import React from "react";

import type { IconProps } from "./types";

export const Clipboard = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M13.3203 2.5H15C15.6771 2.5 16.263 2.7474 16.7578 3.24219C17.2526 3.73698 17.5 4.32292 17.5 5V16.6797C17.5 17.3568 17.2526 17.9427 16.7578 18.4375C16.263 18.9323 15.6771 19.1797 15 19.1797H5C4.32292 19.1797 3.73698 18.9323 3.24219 18.4375C2.7474 17.9427 2.5 17.3568 2.5 16.6797V5C2.5 4.32292 2.7474 3.73698 3.24219 3.24219C3.73698 2.7474 4.32292 2.5 5 2.5H6.67969C6.88802 2.5 7.07031 2.59115 7.22656 2.77344C7.40885 2.92969 7.5 3.125 7.5 3.35938C7.5 3.56771 7.40885 3.76302 7.22656 3.94531C7.07031 4.10156 6.88802 4.17969 6.67969 4.17969H5C4.76562 4.17969 4.57031 4.25781 4.41406 4.41406C4.25781 4.57031 4.17969 4.76562 4.17969 5V16.6797C4.17969 16.888 4.25781 17.0833 4.41406 17.2656C4.57031 17.4219 4.76562 17.5 5 17.5H15C15.2344 17.5 15.4297 17.4219 15.5859 17.2656C15.7422 17.0833 15.8203 16.888 15.8203 16.6797V5C15.8203 4.76562 15.7422 4.57031 15.5859 4.41406C15.4297 4.25781 15.2344 4.17969 15 4.17969H13.3203C13.112 4.17969 12.9167 4.10156 12.7344 3.94531C12.5781 3.76302 12.5 3.56771 12.5 3.35938C12.5 3.125 12.5781 2.92969 12.7344 2.77344C12.9167 2.59115 13.112 2.5 13.3203 2.5ZM7.5 2.5V4.17969H12.5V2.5H7.5ZM7.5 0.820312H12.5C12.9688 0.820312 13.3594 0.989583 13.6719 1.32812C14.0104 1.64062 14.1797 2.03125 14.1797 2.5V4.17969C14.1797 4.6224 14.0104 5.01302 13.6719 5.35156C13.3594 5.66406 12.9688 5.82031 12.5 5.82031H7.5C7.1875 5.82031 6.90104 5.75521 6.64062 5.625C6.40625 5.46875 6.21094 5.26042 6.05469 5C5.89844 4.73958 5.82031 4.46615 5.82031 4.17969V2.5C5.82031 2.03125 5.97656 1.64062 6.28906 1.32812C6.6276 0.989583 7.03125 0.820312 7.5 0.820312Z"
        fill={color}
      />
    </svg>
  );
});
Clipboard.displayName = "Clipboard";
