import type { Filters } from "domains/schedule/components/Filters";

export const sortOptions = [
  { id: "start_time", name: "Starting Soonest" },
  { id: "-start_time", name: "Starting Latest" },
  { id: "-created_ts", name: "Last Posted" },
  { id: "-last_updated_ts", name: "Last Modified" },
] as const satisfies NonNullable<React.ComponentProps<typeof Filters>["sort"]>["options"];

export const gigStatusOptions = [
  {
    id: "unfilled",
    name: "Unfilled",
    controlClassName: "data-[state=checked]:border-gray data-[state=checked]:bg-gray",
  },
  {
    id: "ready",
    name: "Filled",
    controlClassName: "data-[state=checked]:border-info data-[state=checked]:bg-info",
  },
  {
    id: "completed",
    name: "Completed",
    controlClassName: "data-[state=checked]:border-success data-[state=checked]:bg-success",
  },
  {
    id: "paymentDue",
    name: "Payment Due",
    controlClassName: "data-[state=checked]:border-warning data-[state=checked]:bg-warning",
  },
  {
    id: "paymentError",
    name: "Payment Error",
    controlClassName: "data-[state=checked]:border-error data-[state=checked]:bg-error",
  },
  {
    id: "cancelled",
    name: "Cancelled",
    controlClassName: "data-[state=checked]:border-mediumGray data-[state=checked]:bg-mediumGray",
  },
] as const satisfies React.ComponentProps<typeof Filters>["gigStatus"]["options"];
export type GigStatusFilterOptions = (typeof gigStatusOptions)[number];

export const gigStatusOptionIds = gigStatusOptions.map(({ id }) => id);
