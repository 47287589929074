import { z } from "zod";

import { makeApiResource } from "./resource";

export const locationAddOnSchema = makeApiResource({
  end_date: z.string(),
  notes: z.string().nullable(),
  start_date: z.string(),
  type: z.string(),
});

export const mutatedLocationAddOnSchema = locationAddOnSchema.omit({ id: true });

export type LocationAddOn = z.infer<typeof locationAddOnSchema>;
export type MutatedLocationAddOn = z.infer<typeof mutatedLocationAddOnSchema>;
