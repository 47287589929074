import React from "react";

import type { IconProps } from "./types";

export const QR = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.25H6C6.41421 2.25 6.75 2.58579 6.75 3V6C6.75 6.41421 6.41421 6.75 6 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6V3C2.25 2.58579 2.58579 2.25 3 2.25ZM0.75 3C0.75 1.75736 1.75736 0.75 3 0.75H6C7.24264 0.75 8.25 1.75736 8.25 3V6C8.25 7.24264 7.24264 8.25 6 8.25H3C1.75736 8.25 0.75 7.24264 0.75 6V3ZM3 11.25H6C6.41421 11.25 6.75 11.5858 6.75 12V15C6.75 15.4142 6.41421 15.75 6 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V12C2.25 11.5858 2.58579 11.25 3 11.25ZM0.75 12C0.75 10.7574 1.75736 9.75 3 9.75H6C7.24264 9.75 8.25 10.7574 8.25 12V15C8.25 16.2426 7.24264 17.25 6 17.25H3C1.75736 17.25 0.75 16.2426 0.75 15V12ZM15 2.25H12C11.5858 2.25 11.25 2.58579 11.25 3V6C11.25 6.41421 11.5858 6.75 12 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6V3C15.75 2.58579 15.4142 2.25 15 2.25ZM12 0.75C10.7574 0.75 9.75 1.75736 9.75 3V6C9.75 7.24264 10.7574 8.25 12 8.25H15C16.2426 8.25 17.25 7.24264 17.25 6V3C17.25 1.75736 16.2426 0.75 15 0.75H12ZM9.75 16.5C9.75 16.9142 10.0858 17.25 10.5 17.25C10.9142 17.25 11.25 16.9142 11.25 16.5V12C11.25 11.5858 11.5858 11.25 12 11.25H16.5C16.9142 11.25 17.25 10.9142 17.25 10.5C17.25 10.0858 16.9142 9.75 16.5 9.75H12C10.7574 9.75 9.75 10.7574 9.75 12V16.5Z"
        fill={color}
      />
    </svg>
  );
});
QR.displayName = "QR";
