import React from "react";

import type { IconProps } from "./types";

export const Dashboard = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2592 5.68028L15.979 0.960534C16.1204 0.823915 16.3099 0.74832 16.5065 0.750028C16.7032 0.751737 16.8913 0.830614 17.0303 0.96967C17.1694 1.10873 17.2483 1.29684 17.25 1.49348C17.2517 1.69013 17.1761 1.87958 17.0395 2.02103L11.7895 7.27103C11.6488 7.41164 11.4581 7.49062 11.2592 7.49062C11.0603 7.49062 10.8696 7.41164 10.729 7.27103L6.75922 3.30128L2.03947 8.02103C1.97028 8.09267 1.88752 8.1498 1.79602 8.18911C1.70452 8.22842 1.6061 8.24911 1.50652 8.24997C1.40693 8.25084 1.30817 8.23186 1.216 8.19415C1.12383 8.15644 1.04009 8.10075 0.96967 8.03033C0.89925 7.95991 0.843561 7.87617 0.80585 7.784C0.768139 7.69183 0.749163 7.59307 0.750028 7.49348C0.750894 7.3939 0.771583 7.29548 0.81089 7.20398C0.850197 7.11248 0.907333 7.02972 0.978966 6.96053L6.22897 1.71053C6.36961 1.56993 6.56034 1.49094 6.75922 1.49094C6.95809 1.49094 7.14882 1.56993 7.28947 1.71053L11.2592 5.68028ZM7.88416 8.2408H5.63416C5.42705 8.2408 5.25916 8.40869 5.25916 8.6158V16.8658C5.25916 17.0729 5.42705 17.2408 5.63416 17.2408H7.88416C8.09126 17.2408 8.25916 17.0729 8.25916 16.8658V8.6158C8.25916 8.40869 8.09126 8.2408 7.88416 8.2408ZM3.38416 13.4908H1.13416C0.927048 13.4908 0.759155 13.6587 0.759155 13.8658V16.8658C0.759155 17.0729 0.927048 17.2408 1.13416 17.2408H3.38416C3.59126 17.2408 3.75916 17.0729 3.75916 16.8658V13.8658C3.75916 13.6587 3.59126 13.4908 3.38416 13.4908ZM10.1342 13.4908H12.3842C12.5913 13.4908 12.7592 13.6587 12.7592 13.8658V16.8658C12.7592 17.0729 12.5913 17.2408 12.3842 17.2408H10.1342C9.92705 17.2408 9.75916 17.0729 9.75916 16.8658V13.8658C9.75916 13.6587 9.92705 13.4908 10.1342 13.4908ZM16.8842 8.2408H14.6342C14.427 8.2408 14.2592 8.40869 14.2592 8.6158V16.8658C14.2592 17.0729 14.427 17.2408 14.6342 17.2408H16.8842C17.0913 17.2408 17.2592 17.0729 17.2592 16.8658V8.6158C17.2592 8.40869 17.0913 8.2408 16.8842 8.2408Z"
        fill={color}
      />
    </svg>
  );
});
Dashboard.displayName = "Dashboard";
