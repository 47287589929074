import React from "react";

import type { IconProps } from "./types";

export const ChevronUp = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52851 6.02851C7.78886 5.76816 8.21097 5.76816 8.47132 6.02851L12.4713 10.0285C12.7317 10.2889 12.7317 10.711 12.4713 10.9713C12.211 11.2317 11.7889 11.2317 11.5285 10.9713L7.99992 7.44273L4.47132 10.9713C4.21097 11.2317 3.78886 11.2317 3.52851 10.9713C3.26816 10.711 3.26816 10.2889 3.52851 10.0285L7.52851 6.02851Z"
        fill={color}
      />
    </svg>
  );
});
ChevronUp.displayName = "ChevronUp";
