import React from "react";

import type { IconProps } from "./types";

export const Bookmark = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M16.6797 4.17969V17.5C16.6797 17.8385 16.5234 18.0859 16.2109 18.2422C15.9245 18.3984 15.638 18.3724 15.3516 18.1641L10 14.375L4.64844 18.1641C4.36198 18.3724 4.0625 18.3984 3.75 18.2422C3.46354 18.0859 3.32031 17.8385 3.32031 17.5V4.17969C3.32031 3.47656 3.56771 2.89062 4.0625 2.42188C4.55729 1.92708 5.14323 1.67969 5.82031 1.67969H14.1797C14.8568 1.67969 15.4427 1.92708 15.9375 2.42188C16.4323 2.89062 16.6797 3.47656 16.6797 4.17969Z"
        fill={color}
      />
    </svg>
  );
});
Bookmark.displayName = "Bookmark";
