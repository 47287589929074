"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import * as React from "react";
import { cn } from "tailwind-config";
import { ChevronDown } from "../../Icons";
import { Label } from "../Label";

const SelectRoot = SelectPrimitive.Root;

const SelectValue = SelectPrimitive.Value;

export interface SelectLabelProps extends React.ComponentPropsWithoutRef<typeof Label> {}
const SelectLabel = React.forwardRef<React.ElementRef<typeof Label>, SelectLabelProps>(
  ({ className, children, ...props }, ref) => (
    <Label ref={ref} className={cn("text-normal font-semibold", className)} {...props}>
      {children}
    </Label>
  ),
);
SelectLabel.displayName = "SelectLabel";

const SelectBaseTrigger = SelectPrimitive.Trigger;

export interface SelectTriggerProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  invalid?: boolean;
}
const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
  ({ className, children, invalid, ...props }, ref) => (
    <SelectBaseTrigger
      ref={ref}
      className={cn(
        "group flex w-full items-center justify-between rounded border-2 border-mediumGray bg-white px-3 py-2 text-large leading-5 text-black transition-colors focus-within:border-brand-purple focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=open]:border-brand-purple data-[placeholder]:text-mediumGray data-[state=open]:text-white",
        invalid && "border-error",
        className,
      )}
      {...props}
    >
      <span className="truncate">{children}</span>
      <SelectPrimitive.Icon asChild>
        <ChevronDown className="size-4 flex-shrink-0 text-brand-purple transition-transform group-data-[state=open]:rotate-180" />
      </SelectPrimitive.Icon>
    </SelectBaseTrigger>
  ),
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

export interface SelectContentProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> {}
const SelectContent = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Content>, SelectContentProps>(
  ({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "relative z-50 min-w-[8rem] overflow-hidden rounded bg-white text-black shadow-back data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        position === "popper" &&
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          "max-h-60 space-y-1 p-1",
          position === "popper" &&
            "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  ),
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

export interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> {}
const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
  ({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        "relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-large outline-none focus:bg-lightBlue focus:text-black data-[disabled]:pointer-events-none data-[state=checked]:bg-lightBlue data-[state=checked]:font-semibold data-[disabled]:opacity-50",
        className,
      )}
      {...props}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  ),
);
SelectItem.displayName = SelectPrimitive.Item.displayName;

export interface SelectSeparatorProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> {}
const SelectSeparator = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Separator>, SelectSeparatorProps>(
  ({ className, ...props }, ref) => (
    <SelectPrimitive.Separator ref={ref} className={cn("-mx-1 my-1 h-[1px] bg-lightGray", className)} {...props} />
  ),
);
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export interface SelectProps
  extends SelectPrimitive.SelectProps,
    Omit<SelectPrimitive.SelectTriggerProps, "value" | "defaultValue" | "dir"> {
  className?: string;
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
}
const Select = React.forwardRef<React.ElementRef<typeof SelectRoot>, SelectProps>(
  (
    {
      className,
      children,
      label,
      placeholder,
      value,
      defaultValue,
      defaultOpen,
      onValueChange,
      open,
      onOpenChange,
      dir,
      name,
      autoComplete,
      disabled,
      required,
      ...triggerProps
    },
    ref,
  ) => {
    const rootProps: SelectPrimitive.SelectProps = {
      value,
      defaultValue,
      defaultOpen,
      onValueChange,
      open,
      onOpenChange,
      dir,
      name,
      autoComplete,
      disabled,
      required,
    };
    return (
      <SelectPrimitive.Root {...rootProps}>
        <div className={cn("space-y-1", className)}>
          {label && <SelectLabel>{label}</SelectLabel>}
          <SelectTrigger ref={ref} {...triggerProps}>
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
          <SelectContent className="-mt-1">{children}</SelectContent>
        </div>
      </SelectPrimitive.Root>
    );
  },
);
Select.displayName = SelectPrimitive.Root.displayName;

export {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectBaseTrigger,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
};
