import { route } from "react-router-typesafe-routes/dom";
import { zod } from "react-router-typesafe-routes/zod";
import { z } from "zod";

export const DASHBOARD_ROUTES = {
  ROOT: route(
    "dashboards",
    {},
    {
      DETAIL: route(":name", { params: { name: zod(z.string()) } }),
    },
  ),
};
