import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "components/ErrorBoundary";
import { LoaderLogo } from "components/Loader";
import { env } from "config/env";
import { AuthProvider } from "providers/Auth";
import { getFeatureFlag } from "providers/LaunchDarkly/client";
import React from "react";
import * as reactRouter from "react-router-dom";
import { makeLazyRoute } from "utils/routing";
import { ModeGuard } from "./ModeGuard";
import { ROUTES } from "./Routes";

const MessagingChatTokenProvider = React.lazy(() =>
  import("domains/messaging/providers/Auth").then(({ MessagingChatTokenProvider }) => ({
    default: MessagingChatTokenProvider,
  })),
);

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 10 * 1000 } },
});

const router = reactRouter.createBrowserRouter([
  {
    path: "*",
    lazy: async () => {
      const [isSuperTokensEnabled, { RootProvider }] = await Promise.all([
        getFeatureFlag("supertokens-integration", queryClient),
        import("providers/Root"),
      ]);
      if (!isSuperTokensEnabled) return { Component: RootProvider };

      const [stUI, pwlRecipe, stService] = await Promise.all([
        import("supertokens-auth-react/ui"),
        import("supertokens-auth-react/recipe/passwordless/prebuiltui"),
        import("services/auth/supertokens"),
      ]);
      stService.initializeAuth();
      return {
        element: (
          <>
            <reactRouter.Routes>
              {stUI.getSuperTokensRoutesForReactRouterDom(reactRouter, [pwlRecipe.PasswordlessPreBuiltUI])}
            </reactRouter.Routes>
          </>
        ),
      };
    },
    ErrorBoundary,
    children: [
      {
        path: `${ROUTES.SHIFT_MESSAGES.relativePath}/*`,
        element: (
          <MessagingChatTokenProvider>
            <reactRouter.Outlet />
          </MessagingChatTokenProvider>
        ),
        children: [{ path: "*", lazy: makeLazyRoute(() => import("domains/messaging/views/Home"), "Home") }],
      },
      ...(env.IS_LOCAL ?
        [{ path: "login", lazy: makeLazyRoute(() => import("containers/Login"), "LoginContainer") }]
      : []),
      {
        path: "*",
        Component: AuthProvider,
        children: [
          {
            path: ROUTES.SIGN_UP.relativePath,
            lazy: makeLazyRoute(() => import("domains/signUp/views/SignUpPage"), "SignUpPage"),
          },
          {
            path: ROUTES.ACCEPT_LOCATION_INVITATION.relativePath,
            lazy: makeLazyRoute(
              () => import("domains/locations/containers/AcceptManagerInvite"),
              "AcceptManagerInviteContainer",
            ),
          },
          {
            path: ROUTES.ACCEPT_ORG_INVITATION.relativePath,
            lazy: makeLazyRoute(
              () => import("domains/locations/containers/AcceptManagerInvite"),
              "AcceptManagerInviteContainer",
            ),
          },
          {
            path: ROUTES.ACCOUNT_LINKING.relativePath,
            lazy: makeLazyRoute(() => import("domains/signUp/containers/AccountLinking"), "AccountLinkingContainer"),
          },
          {
            path: `${ROUTES.CONNECT.relativePath}/*`,
            lazy: makeLazyRoute(() => import("domains/connect/views/Limited"), "ConnectLimited"),
          },
          {
            path: "find-a-pro/*",
            Component: function ConnectBackwardsCompat() {
              const profileId = window.location.pathname.split("find-a-pro/profile")[1];
              return (
                <reactRouter.Navigate
                  to={`${ROUTES.CONNECT.path}${profileId ?? ""}${window.location.search}`}
                  replace
                />
              );
            },
          },
          {
            path: "*",
            lazy: makeLazyRoute(() => import("./SignedUpRouter"), "SignedUpRouter"),
            children: [
              {
                path: `${ROUTES.ORGANIZATIONS.relativePath}/*`,
                children: [
                  {
                    path: `${ROUTES.ORGANIZATIONS.$.DETAILS.relativePath}/*`,
                    lazy: makeLazyRoute(() => import("domains/orgs/views/ManageOrganization"), "ManageOrganization"),
                  },
                  {
                    path: ROUTES.LOCATIONS.DETAILS.$.TEAM.relativePath,
                    lazy: makeLazyRoute(() => import("domains/orgs/views/ManageOrganization"), "ManageOrganization"),
                  },
                  {
                    path: ROUTES.LOCATIONS.DETAILS.$.BILLING.relativePath,
                    lazy: makeLazyRoute(() => import("domains/orgs/views/ManageOrganization"), "ManageOrganization"),
                  },
                ],
              },
              {
                path: `${ROUTES.LOCATIONS.relativePath}/*`,
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const hasOrganizations = await getFeatureFlag("temp-organizations", queryClient);
                      return hasOrganizations ?
                          makeLazyRoute(
                            () => import("domains/orgs/views/OrganizationOverview"),
                            "OrganizationOverviewView",
                          )()
                        : makeLazyRoute(() => import("domains/locations/views/ManageLocations"), "ManageLocations")();
                    },
                  },
                  {
                    path: ROUTES.LOCATIONS.$.ADD.relativePath,
                    lazy: async () => {
                      const hasOrganizations = await getFeatureFlag("temp-organizations", queryClient);
                      return hasOrganizations ?
                          makeLazyRoute(
                            () => import("domains/orgs/views/OrganizationOverview"),
                            "OrganizationOverviewView",
                          )()
                        : { element: <reactRouter.Navigate to={ROUTES.LOCATIONS.path} /> };
                    },
                  },
                  {
                    path: `${ROUTES.LOCATIONS.$.DETAILS.relativePath}/*`,
                    children: [
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.EDIT.relativePath,
                        lazy: makeLazyRoute(() => import("pages/EditLocation"), "EditLocation"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.STRIPE.relativePath,
                        lazy: makeLazyRoute(() => import("domains/locations/views/ManageStripe"), "ManageStripe"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.USERS.relativePath,
                        lazy: makeLazyRoute(() => import("domains/locations/views/ManageTeam"), "ManageTeamPage"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.TEAM.relativePath,
                        lazy: makeLazyRoute(() => import("domains/locations/views/ManageLocation"), "ManageLocation"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.INFO.relativePath,
                        lazy: makeLazyRoute(() => import("domains/locations/views/ManageLocation"), "ManageLocation"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.BILLING.relativePath,
                        lazy: makeLazyRoute(() => import("domains/locations/views/ManageLocation"), "ManageLocation"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.ADDONS.relativePath,
                        lazy: makeLazyRoute(() => import("pages/LocationAddOns"), "LocationAddOns"),
                      },
                      {
                        path: ROUTES.LOCATIONS.DETAILS.$.ORG_REMAP.relativePath,
                        lazy: makeLazyRoute(() => import("pages/LocationOrgRemap"), "LocationOrgRemap"),
                      },
                      {
                        path: "*",
                        Component: ModeGuard,
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTES.NOTIFICATION_SETTINGS.relativePath,
                lazy: makeLazyRoute(() => import("pages/SmsNotificationSettings"), "SmsNotificationSettings"),
              },
              {
                path: ROUTES.SETTINGS.relativePath,
                lazy: makeLazyRoute(() => import("domains/settings/views/Root"), "SettingsRootView"),
              },
              {
                path: `${ROUTES.ADMIN.relativePath}/*`,
                lazy: makeLazyRoute(() => import("domains/admin/views/Router"), "AdminRouter"),
              },
              {
                path: "*",
                Component: ModeGuard,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const Router = React.memo(function Router() {
  return (
    <React.Suspense fallback={<LoaderLogo />}>
      <QueryClientProvider client={queryClient}>
        <reactRouter.RouterProvider router={router} fallbackElement={<LoaderLogo />} />
      </QueryClientProvider>
    </React.Suspense>
  );
});
