import React from "react";

import type { IconProps } from "./types";

export const Ellipsis = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M8.82812 8.78906C8.48958 9.1276 8.32031 9.53125 8.32031 10C8.32031 10.4688 8.48958 10.8594 8.82812 11.1719C9.14062 11.5104 9.53125 11.6797 10 11.6797C10.4688 11.6797 10.8724 11.5104 11.2109 11.1719C11.5234 10.8594 11.6797 10.4688 11.6797 10C11.6797 9.53125 11.5234 9.1276 11.2109 8.78906C10.8724 8.47656 10.4688 8.32031 10 8.32031C9.53125 8.32031 9.14062 8.47656 8.82812 8.78906ZM8.82812 14.6484C8.48958 14.987 8.32031 15.3776 8.32031 15.8203C8.32031 16.2891 8.48958 16.6797 8.82812 16.9922C9.14062 17.3307 9.53125 17.5 10 17.5C10.4688 17.5 10.8724 17.3307 11.2109 16.9922C11.5234 16.6797 11.6797 16.2891 11.6797 15.8203C11.6797 15.3776 11.5234 14.987 11.2109 14.6484C10.8724 14.3359 10.4688 14.1797 10 14.1797C9.53125 14.1797 9.14062 14.3359 8.82812 14.6484ZM8.32031 4.17969C8.32031 4.46615 8.39844 4.73958 8.55469 5C8.71094 5.26042 8.91927 5.45573 9.17969 5.58594C9.41406 5.74219 9.6875 5.82031 10 5.82031C10.4688 5.82031 10.8724 5.65104 11.2109 5.3125C11.5234 5 11.6797 4.60938 11.6797 4.14062C11.6797 3.69792 11.5234 3.30729 11.2109 2.96875C10.8724 2.65625 10.4688 2.5 10 2.5C9.53125 2.5 9.14063 2.65625 8.82813 2.96875C8.48958 3.30729 8.32031 3.71094 8.32031 4.17969Z"
        fill={color}
      />
    </svg>
  );
});
Ellipsis.displayName = "Ellipsis";
