import React from "react";

import type { IconProps } from "./types";

export const Edit = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5958 4.26006C14.3191 4.26006 14.0537 4.36998 13.858 4.56564L5.20106 13.2226L4.70924 15.1899L6.67655 14.6981L15.3335 6.04112C15.5292 5.84546 15.6391 5.58009 15.6391 5.30338C15.6391 5.02668 15.5292 4.7613 15.3335 4.56564C15.1379 4.36998 14.8725 4.26006 14.5958 4.26006ZM12.5422 3.24979C13.0868 2.70515 13.8255 2.39917 14.5958 2.39917C15.366 2.39917 16.1047 2.70515 16.6494 3.24979C17.194 3.79444 17.5 4.53314 17.5 5.30338C17.5 6.07363 17.194 6.81233 16.6494 7.35697L7.81016 16.1962C7.69092 16.3154 7.54151 16.4 7.3779 16.4409L3.65613 17.3714C3.33906 17.4506 3.00364 17.3577 2.77254 17.1266C2.54144 16.8955 2.44853 16.5601 2.5278 16.243L3.45824 12.5213C3.49914 12.3577 3.58374 12.2083 3.70298 12.089L12.5422 3.24979Z"
        fill={color}
      />
    </svg>
  );
});
Edit.displayName = "Edit";
