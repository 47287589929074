import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import React from "react";
import { cn } from "tailwind-config";
import { Check } from "../../Icons/Check";

type CheckboxGroupContextValue = {
  value: Array<string>;
  onValueChange: (value: Array<string>) => void;
};
const CheckboxGroupContext = React.createContext<CheckboxGroupContextValue>({
  value: [],
  onValueChange: () => {},
});
interface CheckboxGroupProps extends React.HTMLAttributes<HTMLDivElement>, CheckboxGroupContextValue {}
const CheckboxGroup = React.forwardRef<HTMLDivElement, CheckboxGroupProps>(
  ({ className, value, onValueChange, ...props }, ref) => {
    return (
      <CheckboxGroupContext.Provider value={{ value, onValueChange }}>
        <div className={cn("grid gap-2", className)} {...props} ref={ref} />
      </CheckboxGroupContext.Provider>
    );
  },
);
CheckboxGroup.displayName = "CheckboxGroup";

export interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  value?: string;
}
const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, value, checked, onCheckedChange, ...props }, ref) => {
    const context = React.useContext(CheckboxGroupContext);
    return (
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          "size-[18px] shrink-0 rounded-sm border-2 border-gray bg-white text-white hover:border-brand-purple focus-visible:border-brand-purple focus-visible:outline-none disabled:cursor-not-allowed disabled:border-mediumGray disabled:bg-lightGray data-[state=checked]:border-brand-purple data-[state=checked]:bg-brand-purple data-[state=checked]:disabled:border-mediumGray data-[state=checked]:disabled:bg-mediumGray",
          className,
        )}
        checked={context && value != null ? context.value.includes(value) : checked}
        onCheckedChange={(checked) => {
          onCheckedChange?.(checked);
          if (!context || !value) return;
          if (checked) context.onValueChange([...context.value, value]);
          else context.onValueChange(context.value.filter((v) => v !== value));
        }}
        {...props}
      >
        <CheckboxPrimitive.Indicator className={cn("text-current flex size-full items-center justify-center")}>
          <Check className="size-full" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    );
  },
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox, CheckboxGroup };
