import React from "react";

import type { IconProps } from "./types";

export const Mail = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M15.2559 4C15.2559 3.625 15.1204 3.3125 14.8496 3.0625C14.5996 2.79167 14.2975 2.65625 13.9434 2.65625H3.25586C2.90169 2.65625 2.58919 2.79167 2.31836 3.0625C2.06836 3.3125 1.94336 3.625 1.94336 4V12C1.94336 12.375 2.06836 12.6979 2.31836 12.9688C2.58919 13.2188 2.90169 13.3438 3.25586 13.3438H13.9434C14.2975 13.3438 14.5996 13.2188 14.8496 12.9688C15.1204 12.6979 15.2559 12.375 15.2559 12V4ZM13.9434 4L8.59961 7.34375L3.25586 4H13.9434ZM13.9434 12H3.25586V5.34375L8.59961 8.65625L13.9434 5.34375V12Z"
        fill={color}
      />
    </svg>
  );
});
Mail.displayName = "Mail";
