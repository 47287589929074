import React from "react";

import type { IconProps } from "./types";

export const Logout = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7 1.8C2.4613 1.8 2.23239 1.89482 2.0636 2.0636C1.89482 2.23239 1.8 2.4613 1.8 2.7V15.3C1.8 15.5387 1.89482 15.7676 2.0636 15.9364C2.23239 16.1052 2.46131 16.2 2.7 16.2H6.3C6.79706 16.2 7.2 16.6029 7.2 17.1C7.2 17.5971 6.79706 18 6.3 18H2.7C1.98392 18 1.29716 17.7155 0.790812 17.2092C0.284463 16.7028 0 16.0161 0 15.3V2.7C0 1.98392 0.284463 1.29716 0.790812 0.790812C1.29716 0.284463 1.98392 0 2.7 0H6.3C6.79706 0 7.2 0.402944 7.2 0.9C7.2 1.39706 6.79706 1.8 6.3 1.8H2.7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2364 3.86358C12.885 3.51211 12.3151 3.51211 11.9636 3.86358C11.6122 4.21505 11.6122 4.7849 11.9636 5.13637L14.9272 8.09998H6.30002C5.80297 8.09998 5.40002 8.50292 5.40002 8.99998C5.40002 9.49703 5.80297 9.89998 6.30002 9.89998H14.9272L11.9636 12.8636C11.6122 13.2151 11.6122 13.7849 11.9636 14.1364C12.3151 14.4878 12.885 14.4878 13.2364 14.1364L17.7359 9.63694L17.7364 9.63637C17.7384 9.63441 17.7403 9.63244 17.7423 9.63047C17.9134 9.45619 17.9993 9.22954 18 9.00268M17.7423 8.36948C17.8257 8.45439 17.8888 8.55173 17.9317 8.65547C17.8872 8.54807 17.8225 8.45116 17.7423 8.36948ZM13.2364 3.86358L17.7359 8.36301Z"
        fill={color}
      />
    </svg>
  );
});
Logout.displayName = "Logout";
