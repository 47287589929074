import { z } from "zod";

import { certificationSchema, proContractorTypeSchema, proPreferencesSchema } from "./proPreferences";
import { ratingSummarySchema } from "./ratings";
import { makeApiResource } from "./resource";
import { competencySchema } from "./skills";

export const PRO_BADGES = ["VERIFICATION", "TOP_PRO"] as const;
export const TIRED_BADGES = ["VERIFICATION", "GOLD", "SILVER", "BRONZE"] as const;

const proBadges = [...PRO_BADGES, ...TIRED_BADGES] as const;
const propBadgesOptionsSchema = z.enum(proBadges);
export const proBadgeSchema = z.array(propBadgesOptionsSchema);
export const sortBadges = (badges: ProBadge) => badges.sort((a) => (a === "VERIFICATION" ? -1 : 1));

export type ProBadge = z.infer<typeof proBadgeSchema>;
export type ProBadgeType = z.infer<typeof propBadgesOptionsSchema>;

export const proBadgesTypesOptions = [
  {
    value: "TOP_PRO",
    label: "Top Pro",
    description: "Pros that has a high completion rating, is always on time and does a good job.",
  },
  {
    value: "GOLD",
    label: "Gold",
    description:
      "GOLD Pros! has VIP perks like early access to Gigs and boosted profile visibility on our matching platform!",
  },
  {
    value: "SILVER",
    label: "Silver",
    description:
      "Silver ranked Pros are rocking it! Not only do you get to flaunt your silver status to all, but you now get early access to Gigs as well!",
  },
  {
    value: "BRONZE",
    label: "Bronze",
    description:
      "Bronze Pros are Gigpro's rising stars! Your rank is now visible to all businesses, showcasing your excellence!",
  },
] as Array<{ value: ProBadgeType; label: string; description: string }>;

export const proPartialProfileSchema = z.object({
  uuid: z.string().nullish(),
  profile_id: z.number().nullish(),
  bio: z.string().nullable(),
  user_id: z.number().nullable(),
  headshot_public_id: z.string().nullable(),
  badges: proBadgeSchema.nullish(),
  display_name: z.string().nullish(),
  pro_preferences: proPreferencesSchema.extend({ id: z.number().nullish() }).nullable(),
  work_history: z.array(
    makeApiResource({
      business_name: z.string(),
      position: z.string().nullable(),
      pro: z.number().nullish(),
      start_date: z.string(),
      end_date: z.string().nullish(),
      position_skill_object: z
        .object({
          id: z.number(),
          name: z.string(),
          description: z.string().nullish(),
        })
        .nullable(),
      created_ts: z.string().nullish(),
      created_by: z.number().nullish(),
    }),
  ),
  market_id: z.number().optional(),
  market_not_listed: z.coerce.boolean().optional(),
  market_not_listed_name: z.string().optional(),
});
export type ProPartialProfile = z.infer<typeof proPartialProfileSchema>;

export const proFullProfileSchema = proPartialProfileSchema.omit({ work_history: true }).extend({
  id: z.number(),
  address_line_1: z.string().nullable(),
  address_line_2: z.string().nullable(),
  city: z.string().nullable(),
  has_stripe_id: z.boolean(),
  performance: z.object({
    rating: z.coerce.number(),
    gigs_worked: z.coerce.number(),
    completion_percentage: z.coerce.number(),
    callout_count: z.coerce.number(),
    noshow_count: z.coerce.number(),
    review_count: z.coerce.number(),
    ratings_summary: z.array(ratingSummarySchema),
  }),
  pro_preferences: proPreferencesSchema.nullable(),
  competencies: z.array(competencySchema),
  certifications: z.array(certificationSchema),
  // header
  display_name: z.string(),
  verified: z.boolean(),
  is_favorite: z.boolean(),
  is_blocked: z.boolean(),
  has_worked_together: z.boolean(),
  headshot_public_id: z.string().nullable(),
  user_id: z.number(),
  favorite_id: z.number().optional().nullable(),
});
export type PROfileFullData = z.infer<typeof proFullProfileSchema>;

export const proSchema = makeApiResource({
  ratings_summary: z.array(ratingSummarySchema),
  completion_percentage: z.number().nullish(),
  gigs_worked: z.number().nullish(),
  verified: z.boolean().nullish(),
  rating: z.number().nullish(),
  review_count: z.number().nullish(),
  skill_certification_count: z.number().nullish(),
  display_name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  gigster_profile: proPartialProfileSchema,
  phone_number: z.string().nullish(),
});
export type Pro = z.infer<typeof proSchema>;

export const shortProProfileSchema = z.object({
  uuid: z.string(),
  resume_public_id: z.string().nullable(),
  display_name: z.string().nullable(),
  user_id: z.number(),
  profile_id: z.number().nullish(),
  bio: z.string().nullable(),
  headshot_public_id: z.string().nullable(),
  badges: proBadgeSchema.nullish(),
  pro_preferences: z.object({ pro_contractor_type: z.array(proContractorTypeSchema.pick({ name: true })) }).nullable(),
});
export type ShortProProfile = z.infer<typeof shortProProfileSchema>;

export const proHireProSchema = makeApiResource({
  completion_percentage: z.number().catch(0),
  gigs_worked: z.number().catch(0),
  verified: z.boolean(),
  favorited: z.boolean(),
  favorite_id: z.number().catch(0),
  rating: z.number().catch(0),
  review_count: z.number().catch(0),
  first_name: z.string(),
  last_name: z.string(),
  display_name: z.string().optional(),
  gigster_profile: shortProProfileSchema,
}).transform((pro) => ({
  ...pro,
  ratings_summary: [
    {
      is_overall_type: true,
      rank: 0,
      label: "Overall",
      name: "overall",
      rating_count: pro.review_count,
      avg_rating: pro.rating,
    },
  ],
  display_name: pro.display_name ?? `${pro.first_name} ${pro.last_name[0]}.`,
}));
export type ProHirePro = z.infer<typeof proHireProSchema>;
