import { z } from "zod";

import type { SelectOption } from "types/utils";

import { makeApiResource, makePaginatedResult } from "./resource";
import { skillSchema } from "./skills";

const sharedAttributes = { name: z.string(), description: z.string().nullable() };
const commonPageResult = makePaginatedResult(makeApiResource(sharedAttributes));

export const transformToSelectOptions = (
  apiResults: z.infer<typeof commonPageResult>,
  preferName = false,
): SelectOption<number>[] =>
  apiResults.results.map(({ id, name, description }) => ({
    value: id,
    label: preferName ? name : (description ?? name),
  }));

export const timeBlockSchema = makeApiResource({ ...sharedAttributes, start_time: z.string(), end_time: z.string() });
export type TimeBlock = z.infer<typeof timeBlockSchema>;

export const proContractorTypeSchema = makeApiResource(sharedAttributes);
export type ProContractorType = z.infer<typeof proContractorTypeSchema>;

export const hoursPerWeekSchema = makeApiResource(sharedAttributes);
export type HoursPerWeek = z.infer<typeof hoursPerWeekSchema>;

export const certificationSchema = makeApiResource(sharedAttributes);
export type Certification = z.infer<typeof certificationSchema>;

export const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"] as const;
export const proPreferencesSchema = makeApiResource({
  pro_contractor_type: z
    .array(proContractorTypeSchema.extend({ id: z.number().nullish(), description: z.string().nullish() }))
    .nullish(),
  skills: z.array(skillSchema).nullish(),
  days_of_week: z.array(z.enum(daysOfWeek)).nullish(),
  time_blocks: z.array(timeBlockSchema).nullish(),
  hours_per_week: z.array(hoursPerWeekSchema).nullish(),
});

export type ProPreferences = z.infer<typeof proPreferencesSchema>;
export type DAYS_OF_WEEK = ProPreferences["days_of_week"];
