"use client";

import type * as React from "react";
import { type CustomComponents, DayPicker } from "react-day-picker";
import { cn } from "tailwind-config";

import { ChevronDown } from "../../Icons";
import { buttonVariants } from "../Button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const components: Partial<CustomComponents> = {
  Chevron: ({ orientation, className }) => (
    <ChevronDown className={cn("size-5", orientation === "left" ? "rotate-90" : "-rotate-90", className)} />
  ),
  Day: ({ className, day, modifiers, ...props }) => <td className={cn(className)} {...props} />,
};

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "rdp-months relative flex flex-col sm:flex-row gap-y-4 gap-x-6 justify-around",
        month: "rdp-month flex flex-col gap-4 w-full",
        month_caption: "rdp-month_caption flex justify-center pt-1 relative items-center",
        caption_label: "rdp-caption_label text-extraLarge font-semibold",
        nav: "rdp-nav gap-1 flex justify-between absolute top-0 left-0 right-0 z-10 p-1",
        button_previous: cn("rdp-button_previous", buttonVariants({ variant: "text" }), "size-6 bg-transparent p-0"),
        button_next: cn("rdp-button_next", buttonVariants({ variant: "text" }), "size-6 bg-transparent p-0"),
        month_grid: "rdp-month_grid w-full border-collapse space-y-1",

        weekdays: "rdp-weekdays grid grid-cols-7 w-full mb-2 place-items-center",
        weekday: "rdp-weekday text-black rounded-md w-9 font-semibold text-[0.8rem]",
        week: "rdp-week grid grid-cols-7 w-full justify-between place-items-center",

        day: cn(
          "rdp-day_button",
          buttonVariants({ variant: "text" }),
          "m-0.5 size-8 rounded-full bg-background p-0 hover:bg-tags-purple aria-selected:opacity-100",
        ),
        day_button: "rdp-day_button size-full",
        selected:
          "rdp-selected bg-brand-purple text-white hover:bg-brand-purpleHover focus:bg-primary focus:text-primary-foreground",
        today: "rdp-today ring-mediumGray/50 ring-1",
        outside:
          "rdp-outside bg-transparent text-mediumGray hover:bg-brand-purple/10 aria-selected:bg-brand-purple/20 aria-selected:text-mediumGray",
        disabled: "rdp-disabled text-mediumGray bg-transparent hover:bg-transparent",
        hidden: "rdp-hidden invisible",
        ...classNames,
      }}
      components={components}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
