import React from "react";

import type { IconProps } from "./types";

export const Coupon = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 3.75C2.25 3.33579 2.58579 3 3 3H6V3.66667C6 4.08088 6.33579 4.41667 6.75 4.41667C7.16421 4.41667 7.5 4.08088 7.5 3.66667V3H15C15.4142 3 15.75 3.33579 15.75 3.75V5.56248C14.4272 6.14051 13.5 7.46027 13.5 9C13.5 10.5397 14.4272 11.8595 15.75 12.4375V14.25C15.75 14.6642 15.4142 15 15 15H7.5V14.3333C7.5 13.9191 7.16421 13.5833 6.75 13.5833C6.33579 13.5833 6 13.9191 6 14.3333V15H3C2.58579 15 2.25 14.6642 2.25 14.25V12.4375C3.57282 11.8595 4.5 10.5397 4.5 9C4.5 7.46027 3.57282 6.14051 2.25 5.56248V3.75ZM0.75 3.75C0.75 2.50736 1.75736 1.5 3 1.5H15C16.2426 1.5 17.25 2.50736 17.25 3.75V6C17.25 6.41421 16.905 6.73776 16.5136 6.87328C15.6326 7.17829 15 8.01527 15 9C15 9.98473 15.6326 10.8217 16.5136 11.1267C16.905 11.2622 17.25 11.5858 17.25 12V14.25C17.25 15.4926 16.2426 16.5 15 16.5H3C1.75736 16.5 0.75 15.4926 0.75 14.25V12C0.75 11.5858 1.095 11.2622 1.48642 11.1267C2.3674 10.8217 3 9.98473 3 9C3 8.01527 2.3674 7.17829 1.48642 6.87328C1.095 6.73776 0.75 6.41421 0.75 6V3.75ZM7.5 6.33333C7.5 5.91912 7.16421 5.58333 6.75 5.58333C6.33579 5.58333 6 5.91912 6 6.33333V7.66667C6 8.08088 6.33579 8.41667 6.75 8.41667C7.16421 8.41667 7.5 8.08088 7.5 7.66667V6.33333ZM7.5 10.3333C7.5 9.91912 7.16421 9.58333 6.75 9.58333C6.33579 9.58333 6 9.91912 6 10.3333V11.6667C6 12.0809 6.33579 12.4167 6.75 12.4167C7.16421 12.4167 7.5 12.0809 7.5 11.6667V10.3333Z"
        fill={color}
      />
    </svg>
  );
});
Coupon.displayName = "Coupon";
