import React from "react";

import type { IconProps } from "./types";

export const ErrorSign = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M2.25 6.22266L6.22266 2.25H11.7773L15.75 6.22266V11.7773L11.7773 15.75H6.22266L2.25 11.7773V6.22266ZM0.738281 5.90625V12.0938C0.738281 12.3047 0.820312 12.4805 0.984375 12.6211L5.37891 17.0156C5.51953 17.1797 5.69531 17.2617 5.90625 17.2617H12.0938C12.3047 17.2617 12.4805 17.1797 12.6211 17.0156L17.0156 12.6211C17.1797 12.4805 17.2617 12.3047 17.2617 12.0938V5.90625C17.2617 5.69531 17.1797 5.51953 17.0156 5.37891L12.6211 0.984375C12.4805 0.820312 12.3047 0.738281 12.0938 0.738281H5.90625C5.69531 0.738281 5.51953 0.820312 5.37891 0.984375L0.984375 5.37891C0.820312 5.51953 0.738281 5.69531 0.738281 5.90625ZM9.73828 6.01172C9.73828 5.87109 9.70312 5.74219 9.63281 5.625C9.58594 5.50781 9.50391 5.41406 9.38672 5.34375C9.26953 5.27344 9.14062 5.23828 9 5.23828C8.78906 5.23828 8.61328 5.32031 8.47266 5.48438C8.33203 5.625 8.26172 5.80078 8.26172 6.01172V9C8.26172 9.21094 8.33203 9.38672 8.47266 9.52734C8.61328 9.66797 8.78906 9.73828 9 9.73828C9.21094 9.73828 9.38672 9.66797 9.52734 9.52734C9.66797 9.38672 9.73828 9.21094 9.73828 9V6.01172ZM8.47266 11.4609C8.33203 11.6016 8.26172 11.7773 8.26172 11.9883C8.26172 12.1992 8.33203 12.3867 8.47266 12.5508C8.61328 12.6914 8.78906 12.7617 9 12.7617C9.21094 12.7617 9.38672 12.6914 9.52734 12.5508C9.69141 12.3867 9.77344 12.1992 9.77344 11.9883C9.77344 11.8711 9.73828 11.7539 9.66797 11.6367C9.59766 11.5195 9.50391 11.4258 9.38672 11.3555C9.26953 11.2852 9.14062 11.25 9 11.25C8.78906 11.25 8.61328 11.3203 8.47266 11.4609Z"
        fill={color}
      />
    </svg>
  );
});
ErrorSign.displayName = "Error";
