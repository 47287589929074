import React from "react";

import type { IconProps } from "./types";

export const Heart = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M17.9688 10.7978C19.0104 9.75612 19.5312 8.4931 19.5312 7.00873C19.5312 5.49831 18.9974 4.22227 17.9297 3.1806C16.888 2.11289 15.612 1.57904 14.1016 1.57904C12.6172 1.57904 11.3542 2.09987 10.3125 3.14154L10 3.45404L9.6875 3.14154C8.64583 2.09987 7.36979 1.57904 5.85938 1.57904C4.375 1.57904 3.09896 2.11289 2.03125 3.1806C0.989583 4.22227 0.46875 5.49831 0.46875 7.00873C0.46875 8.4931 0.989583 9.75612 2.03125 10.7978C2.99479 11.7613 4.375 13.1415 6.17188 14.9384C7.96875 16.7353 9.04948 17.816 9.41406 18.1806C9.57031 18.3369 9.76563 18.415 10 18.415C10.2344 18.415 10.4297 18.3369 10.5859 18.1806C10.9766 17.79 12.0573 16.7092 13.8281 14.9384C15.625 13.1415 17.0052 11.7613 17.9688 10.7978Z"
        fill={color}
      />
    </svg>
  );
});
Heart.displayName = "Heart";
