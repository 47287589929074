import Axios from "axios";

import type { PageResult } from "api/django/PageResult";
import { RestClient } from "api/django/RestClient";
import type { BillingInfo, Location } from "api/resources/Location";
import { env } from "config/env";
import { makeIdToCloudinaryUrl } from "utils/cloudinary";

const postProcessLocation = ({ id, image_public_id, ...location }: Location) => ({
  ...location,
  id: Number(id),
  image_public_id,
  imageUrl: makeIdToCloudinaryUrl(image_public_id),
});

const postProcessLocations = (locationsPage: PageResult<Location>): PageResult<Location> => ({
  ...locationsPage,
  results: locationsPage.results.map(postProcessLocation),
});
class LocationClient extends RestClient<Location> {
  _getBaseUrl() {
    return "api/locations";
  }

  async retrieve(id: string | number): Promise<Location> {
    const locations = await super.retrieve(id);
    return postProcessLocation(locations);
  }

  async list(params?: URLSearchParams | Record<string, any> | undefined): Promise<PageResult<Location>> {
    const locations = await super.list(params);
    return postProcessLocations(locations);
  }

  async listAll(_?: URLSearchParams | Record<string, any> | undefined): Promise<Location[]> {
    throw new Error("This is slow, don't do it!");
  }

  async getSetupIntentSecret(id: number): Promise<string> {
    const token = await this.getTokenSilently();
    const response = await Axios.get(`${env.REACT_APP_SG_API_URL}${this._getBaseUrl()}/${id}/setup-intent/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return (response.data as { client_secret: string }).client_secret;
  }

  async getBillingInfo(locationId: number): Promise<BillingInfo> {
    const token = await this.getTokenSilently();
    const response = await Axios.get(
      `${env.REACT_APP_SG_API_URL}${this._getBaseUrl()}/${locationId}/payment-method/?include_details=true`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return response.data;
  }

  async updatePaymentMethod(locationId: number, paymentMethodId: string): Promise<void> {
    const token = await this.getTokenSilently();
    await Axios.put(
      `${env.REACT_APP_SG_API_URL}${this._getBaseUrl()}/${locationId}/payment-method/`,
      { payment_method_id: paymentMethodId },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  }

  async createCustomerPortalLink(locationId: number): Promise<string> {
    const response = await Axios.post(
      this.getUrl(locationId, "stipe-customer-portal-session"),
      {},
      await this.getRequestOpts(),
    );
    return response.data;
  }
}

export const locationClient = new LocationClient();
