import React from "react";

import type { IconProps } from "./types";

export const HireLogo = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <svg width="36" height="36" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <rect width="32" height="32" rx="4" fill="url(#paint0_linear_1779_13125)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7916 11.6042C12.7916 12.4901 12.0734 13.2083 11.1875 13.2083C10.3015 13.2083 9.58329 12.4901 9.58329 11.6042C9.58329 10.7182 10.3015 10 11.1875 10C12.0734 10 12.7916 10.7182 12.7916 11.6042ZM22.4166 11.6042C22.4166 12.4901 21.6984 13.2083 20.8125 13.2083C19.9265 13.2083 19.2083 12.4901 19.2083 11.6042C19.2083 10.7182 19.9265 10 20.8125 10C21.6984 10 22.4166 10.7182 22.4166 11.6042ZM21.0416 14.125C20.407 14.1841 19.793 14.3815 19.2429 14.7033C18.6927 15.0251 18.2197 15.4635 17.8571 15.9877C17.613 16.2766 17.3434 16.5429 17.0514 16.7833C17.2172 16.9771 17.3318 17.2094 17.3848 17.4588C17.4378 17.7083 17.4275 17.9671 17.3548 18.2115C17.8896 17.9168 18.3632 17.5226 18.75 17.0501V21.4584C18.75 21.7015 18.8465 21.9346 19.0184 22.1065C19.1903 22.2784 19.4235 22.375 19.6666 22.375H22.4166C22.6597 22.375 22.8929 22.2784 23.0648 22.1065C23.2367 21.9346 23.3333 21.7015 23.3333 21.4584V16.4167C23.3333 15.8089 23.0918 15.226 22.6621 14.7962C22.2323 14.3665 21.6494 14.125 21.0416 14.125ZM14.1456 15.9877C14.768 16.6798 15.1814 17.1042 15.8166 17.1042C15.999 17.1042 16.1738 17.1766 16.3028 17.3055C16.4317 17.4345 16.5041 17.6093 16.5041 17.7917C16.5041 17.974 16.4317 18.1489 16.3028 18.2778C16.1738 18.4067 15.999 18.4792 15.8166 18.4792C15.3093 18.4475 14.8155 18.3027 14.3714 18.0555C13.9273 17.8082 13.5441 17.4647 13.25 17.0501V21.4584C13.25 21.7015 13.1534 21.9346 12.9815 22.1065C12.8096 22.2784 12.5764 22.375 12.3333 22.375H9.58329C9.34018 22.375 9.10702 22.2784 8.93511 22.1065C8.7632 21.9346 8.66663 21.7015 8.66663 21.4584V16.4167C8.66663 15.8089 8.90807 15.226 9.33784 14.7962C9.76761 14.3665 10.3505 14.125 10.9583 14.125C11.5934 14.1837 12.2079 14.3809 12.7586 14.7027C13.3092 15.0246 13.7827 15.4632 14.1456 15.9877Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_1779_13125" x1="32" y1="32" x2="0" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0AD8EB" />
          <stop offset="1" stopColor="#00838F" />
        </linearGradient>
      </defs>
    </svg>
  );
});
HireLogo.displayName = "HireLogo";
