"use client";

import type { FieldPath, FieldPathValue, FieldValues, UseControllerProps } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "..";
import { MaskedInput, type MaskedInputProps } from "../../MaskedInput";
import type { FormFieldProps, Transform } from "../types";

export const MaskedTextfield = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  optional,
  placeholder,
  className,
  name,
  control,
  shouldUnregister,
  disabled,
  transform,
  ...inputProps
}: FormFieldProps<TFieldValues, TName, Transform<string, FieldPathValue<TFieldValues, TName>>> & MaskedInputProps) => {
  const formFieldProps: UseControllerProps<TFieldValues, TName> = { name, control, shouldUnregister, disabled };
  return (
    <FormField
      {...formFieldProps}
      render={({ field: { ref, onChange, ...field }, fieldState }) => (
        <FormItem className={className}>
          <FormLabel optional={optional}>{label}</FormLabel>
          <FormControl>
            <MaskedInput
              className="w-full"
              placeholder={placeholder}
              {...inputProps}
              {...field}
              inputRef={ref}
              value={transform && field.value != null ? transform.input(field.value) : field.value}
              onAccept={transform ? (value) => onChange(transform.output(value)) : onChange}
              invalid={!!fieldState.error}
            />
          </FormControl>
          {!!description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

MaskedTextfield.displayName = "MaskedTextfield";
