import { z } from "zod";

const applicationMetadataSchema = z.object({
  gig_starttime: z.coerce.date(),
  gig_endtime: z.coerce.date(),
  gig_position: z.string(),
  gig_wage_amount: z.coerce.number(),
  pro_fullname: z.string(),
  application_encrypted_id: z.string(),
});
export type ApplicationMetadata = z.infer<typeof applicationMetadataSchema>;

export const chatTokenAuthSchema = z.object({
  is_active: z.boolean(),
  applications: z.array(applicationMetadataSchema).nullish(),
  session_token: z.string(),
  shift_encrypted_id: z.string(),
  business_encrypted_id: z.string(),
});
export type ChatTokenAuth = z.infer<typeof chatTokenAuthSchema>;
export const directMessagesAuthSchema = z.object({
  location_encrypted_id: z.string(),
  session_token: z.string(),
});
export type DirectMessagesAuth = z.infer<typeof directMessagesAuthSchema>;

export const directMessagesChatSchema = z.object({
  pro_id: z.union([z.coerce.number(), z.string()]),
  location_id: z.coerce.number(),
  channel_url: z.string(),
});
export type DirectMessagesChat = z.infer<typeof directMessagesChatSchema>;

export const supportMessageSchema = z.object({
  id: z.number(),
  channel_url: z.string(),
  recipient_id: z.number(),
  recipient_type: z.enum(["LOCATION", "GIGSTER"]),
});
export type SupportMessage = z.infer<typeof supportMessageSchema>;

export const unreadMessageCountSchema = z.object({
  unread_message_count: z.number(),
});
export type UnreadMessageCount = z.infer<typeof unreadMessageCountSchema>;
