import React from "react";

import type { IconProps } from "../types";

export const StageInterview = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0C8.20435 0 7.44129 0.31607 6.87868 0.878679C6.31607 1.44129 6 2.20435 6 3V3.99999H3C1.34315 3.99999 0 5.34314 0 6.99999V17C0 18.6568 1.34315 20 3 20H6.99658C6.99772 20 6.99886 20 7 20C7.00114 20 7.00228 20 7.00342 20H14.9966C14.9977 20 14.9989 20 15 20C15.0011 20 15.0023 20 15.0034 20H19C20.6569 20 22 18.6568 22 17V6.99999C22 5.34314 20.6569 3.99999 19 3.99999H16V3C16 2.20435 15.6839 1.44129 15.1213 0.878679C14.5587 0.31607 13.7956 0 13 0H9ZM14 3.99999V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H9C8.73478 2 8.48043 2.10536 8.29289 2.29289C8.10536 2.48043 8 2.73478 8 3V3.99999H14ZM8 5.99999H14V18H8V5.99999ZM6 5.99999H3C2.44772 5.99999 2 6.44771 2 6.99999V17C2 17.5523 2.44772 18 3 18H6V5.99999ZM16 18V5.99999H19C19.5523 5.99999 20 6.44771 20 6.99999V17C20 17.5523 19.5523 18 19 18H16Z"
          fill={color}
        />
      </svg>
    );
  },
);

StageInterview.displayName = "StageInterview";
