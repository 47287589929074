import React from "react";

import type { IconProps } from "./types";

export const Invite = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58333 5.83341C4.58333 4.4527 5.70262 3.33341 7.08333 3.33341C8.46405 3.33341 9.58333 4.4527 9.58333 5.83341C9.58333 7.21413 8.46405 8.33341 7.08333 8.33341C5.70262 8.33341 4.58333 7.21413 4.58333 5.83341ZM7.08333 1.66675C4.78215 1.66675 2.91667 3.53223 2.91667 5.83341C2.91667 8.1346 4.78215 10.0001 7.08333 10.0001C9.38452 10.0001 11.25 8.1346 11.25 5.83341C11.25 3.53223 9.38452 1.66675 7.08333 1.66675ZM4.16667 11.6667C3.0616 11.6667 2.00179 12.1057 1.22039 12.8871C0.438987 13.6685 0 14.7283 0 15.8334V17.5001C0 17.9603 0.373096 18.3334 0.833333 18.3334C1.29357 18.3334 1.66667 17.9603 1.66667 17.5001V15.8334C1.66667 15.1704 1.93006 14.5345 2.3989 14.0656C2.86774 13.5968 3.50363 13.3334 4.16667 13.3334H10C10.663 13.3334 11.2989 13.5968 11.7678 14.0656C12.2366 14.5345 12.5 15.1704 12.5 15.8334V17.5001C12.5 17.9603 12.8731 18.3334 13.3333 18.3334C13.7936 18.3334 14.1667 17.9603 14.1667 17.5001V15.8334C14.1667 14.7283 13.7277 13.6685 12.9463 12.8871C12.1649 12.1057 11.1051 11.6667 10 11.6667H4.16667ZM16.6667 5.83342C17.1269 5.83342 17.5 6.20651 17.5 6.66675V8.33341H19.1667C19.6269 8.33341 20 8.70651 20 9.16675C20 9.62699 19.6269 10.0001 19.1667 10.0001H17.5V11.6667C17.5 12.127 17.1269 12.5001 16.6667 12.5001C16.2064 12.5001 15.8333 12.127 15.8333 11.6667V10.0001H14.1667C13.7064 10.0001 13.3333 9.62699 13.3333 9.16675C13.3333 8.70651 13.7064 8.33341 14.1667 8.33341H15.8333V6.66675C15.8333 6.20651 16.2064 5.83342 16.6667 5.83342Z"
        fill={color}
      />
    </svg>
  );
});
Invite.displayName = "Invite";
