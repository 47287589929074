import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import { errorResponse } from "api/resources/resource";

const directOfferSchema = z.object({
  gig_id: z.number(),
  /**
   * Determines if the offer should be recurring
   * Only valid for recurring gigs
   */
  is_recurring: z.boolean(),
  group_ids: z.array(z.number()).optional(),
  /**
   * User ids of pros this gig should be sent to
   */
  pro_ids: z.array(z.number()).optional(),
  /**
   * Bonus amount to be added to the offer
   */
  bonus: z.number().optional(),
});
export type DirectOffer = z.infer<typeof directOfferSchema>;

const directOfferApi = makeApi([
  {
    method: "post",
    path: "staffer/direct-offers/",
    response: z.union([z.array(z.any()), directOfferSchema, errorResponse]),
    parameters: [
      {
        name: "Direct offer",
        type: "Body",
        schema: directOfferSchema,
      },
      {
        name: "is_for_recommended_pros",
        type: "Query",
        schema: z.boolean().optional(),
      },
    ],
    alias: "createDirectOffer",
  },
]);

export const { client, hooks } = makeClientWithHooks("directOffers", directOfferApi);
