import { env } from "config/env";

export const initNewRelic = () => {
  if (!NREUM) return;
  // extracted from snippet
  NREUM.loader_config = {
    accountID: env.REACT_APP_NR_ACCOUNT_ID,
    trustKey: env.REACT_APP_NR_ACCOUNT_ID,
    agentID: env.REACT_APP_NR_APPLICATION_ID,
    licenseKey: env.REACT_APP_NR_LICENSE_KEY,
    applicationID: env.REACT_APP_NR_APPLICATION_ID,
  };
  NREUM.info = {
    beacon: env.REACT_APP_NR_BEACON,
    errorBeacon: env.REACT_APP_NR_BEACON,
    licenseKey: env.REACT_APP_NR_LICENSE_KEY,
    applicationID: env.REACT_APP_NR_APPLICATION_ID,
    sa: 1,
  };
};

export function captureException(error: Error) {
  if (!newrelic) return;
  newrelic.noticeError(error);
}

export function captureBreadcrumb(category: string, message: string, data: Record<string | number, any> = {}) {
  if (!newrelic) return;
  newrelic.addPageAction(category, { message, ...data });
}
