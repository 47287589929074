import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  certificationSchema,
  hoursPerWeekSchema,
  proContractorTypeSchema,
  timeBlockSchema,
  transformToSelectOptions,
} from "api/resources/proPreferences";
import {
  proGigHistorySummarySchema,
  proProfileExperienceSectionSchema,
  proProfileReviewsSectionSchema,
} from "api/resources/proProfile";
import { proFullProfileSchema } from "api/resources/pros";
import { makePaginatedResult, paginationParams } from "api/resources/resource";

const sharedOptions = { method: "get", parameters: paginationParams } as const;

export const findAProApi = makeApi([
  {
    method: "get",
    path: "/gigster-profiles/:id/work-history-records/",
    response: proProfileExperienceSectionSchema,
    alias: "proProfileExperienceSection",
  },
  {
    method: "get",
    path: "/gigster-profiles/:id/",
    response: proFullProfileSchema,
    alias: "proProfile",
  },
  {
    method: "get",
    path: "/gigster-profiles/:id/review-stats/",
    response: proProfileReviewsSectionSchema,
    alias: "proProfileReviewSection",
    parameters: [{ name: "skill_id", type: "Query", schema: z.number().optional() }],
  },
  {
    method: "get",
    path: "/gigster-profiles/:id/gig-stats/skills/",
    response: proGigHistorySummarySchema.transform((data) =>
      data.reduce(
        (acc, { skill_id, skill_name, rating, gigs_worked, avg_wage_amount }) => {
          acc.entries[skill_name] = {
            skillId: skill_id,
            count: gigs_worked,
            avgRating: rating,
            avgWage: avg_wage_amount,
          };
          return { count: acc.count + gigs_worked, entries: acc.entries };
        },
        {
          count: 0,
          entries: {} as Record<string, { skillId: number; count: number; avgRating: number; avgWage: number }>,
        },
      ),
    ),
    alias: "proGigHistorySummary",
  },
  {
    ...sharedOptions,
    path: "/time-blocks/",
    response: makePaginatedResult(timeBlockSchema).transform((data) => transformToSelectOptions(data, true)),
    alias: "timeBlockOptions",
  },
  {
    ...sharedOptions,
    path: "/pro-contractor-type/",
    response: makePaginatedResult(proContractorTypeSchema).transform((data) => transformToSelectOptions(data, true)),
    alias: "proContractorTypeOptions",
  },
  {
    ...sharedOptions,
    path: "/hours-per-week/",
    response: makePaginatedResult(hoursPerWeekSchema).transform((data) => transformToSelectOptions(data)),
    alias: "hoursPerWeekOptions",
  },
  {
    ...sharedOptions,
    path: "/certifications/",
    response: makePaginatedResult(certificationSchema).transform((data) => transformToSelectOptions(data)),
    alias: "certificationsOptions",
  },
  {
    method: "get",
    path: "/pro/:uuid/",
    response: proFullProfileSchema,
    alias: "proProfileByUuid",
  },
]);
