import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import { makePaginatedResult } from "api/resources/resource";

export const GeoLog = z.object({
  id: z.string(),
  action: z.enum(["GEO_FENCE_ENTERED", "GEO_FENCE_EXITED", "UNKNOWN"]),
  is_flagged_for_staffer_review: z.boolean(),
  created_ts: z.coerce.date(),
  last_updated_ts: z.coerce.date(),
  precision: z.enum(["FINE", "COARSE"]),
  metadata: z.object({
    gig_id: z.number(),
    user_id: z.number(),
    market_id: z.number(),
    location_id: z.number(),
    gig_application_id: z.number(),
  }),
  coordinates: z.object({
    type: z.enum(["Point"]),
    coordinates: z.array(z.number()),
  }),
});

export type GeoLog = z.infer<typeof GeoLog>;

const geoLogs = makeApi([
  {
    method: "get",
    path: "/audit/geo-logs/",
    response: makePaginatedResult(GeoLog),
    alias: "geoLogs",
    parameters: [
      {
        name: "search",
        type: "Query",
        schema: z.number(),
      },
      {
        name: "is_flagged_for_staffer_review",
        type: "Query",
        schema: z.boolean(),
      },
    ],
  },
]);

export const { client, hooks } = makeClientWithHooks("geoLogs", geoLogs);
