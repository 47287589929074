import { browserTimezone, convertToTimezone, isPast, nowInTimezone } from "@gigpro/utils/datetime";
import { areIntervalsOverlapping, differenceInHours } from "date-fns";

import type { GigCancellationReason } from "api/clients/gigs";
import type { ConfirmedPro, StafferGig, StafferGigV2 } from "api/resources/StafferGig";
import type { GigApplication } from "api/resources/gigApplications";
import { sortBadges } from "api/resources/pros";

import { identify, track } from "./analytics";

export interface GroupApplication {
  pending: Array<GigApplication>;
  cancelled: Array<GigApplication>;
  confirmed: Array<GigApplication>;
}

export function groupApplications(applications: Array<GigApplication> = []): GroupApplication {
  return applications.reduce(
    (acc: GroupApplication, application: GigApplication) => {
      const isConfirmed = application.is_selected && application.is_confirmed && !application.is_cancelled;
      const isPending = !application.is_confirmed && application.is_selected && !application.is_cancelled;
      const isCancelled = application.is_cancelled;

      if (isPending) {
        acc.pending.push(application);
        return acc;
      }
      if (isCancelled) {
        acc.cancelled.push(application);
        return acc;
      }
      if (isConfirmed) {
        acc.confirmed.push(application);
        return acc;
      }
      return acc;
    },
    { pending: [], cancelled: [], confirmed: [] },
  );
}

export const postProcessServerApplication = (application: GigApplication, timezone: string): GigApplication => ({
  ...application,
  working_hours: {
    start_time:
      application.working_hours?.start_time ?
        convertToTimezone(new Date(application.working_hours.start_time), timezone)
      : undefined,
    end_time:
      application.working_hours?.end_time ?
        convertToTimezone(new Date(application.working_hours.end_time), timezone)
      : undefined,
    original_start_time:
      application.working_hours?.original_start_time ?
        convertToTimezone(new Date(application.working_hours.original_start_time), timezone)
      : undefined,
    original_end_time:
      application.working_hours?.original_end_time ?
        convertToTimezone(new Date(application.working_hours.original_end_time), timezone)
      : undefined,
  },
  gigster: {
    ...application.gigster,
    gigs_worked: application.gigster.gigs_worked ?? 0,
    gigster_profile: {
      ...application.gigster.gigster_profile,
      badges: sortBadges(application.gigster.gigster_profile.badges ?? []),
    },
  },
});

export function formatWageAmount(wageAmount: string | number) {
  return Number.parseFloat(String(wageAmount))?.toFixed(2);
}

export function getConfirmedPro({ gigster, ...application }: GigApplication): ConfirmedPro {
  return {
    id: gigster.id,
    applicationId: application.id,
    applicationReview: application.review,
    headshot_public_id: gigster.gigster_profile?.headshot_public_id,
    fullName: `${gigster.first_name} ${gigster.last_name}`,
    first_name: gigster.first_name,
    last_name: gigster.last_name,
    reviewCount: gigster.review_count,
    rating: gigster.rating,
    is_blocked: !!application.is_blocked,
    is_favorite: !!application.is_favorite,
    working_hours: application.working_hours,
    payment: application.payment,
    review: application.review,
  };
}

const RATING_CUTOFF_HOURS = 24;
export function getDefaultCancellationRating(gig: StafferGig, reason: GigCancellationReason) {
  if (reason === "NO_SHOW") {
    return {
      defaultRating: 1,
      defaultRatingMessage:
        "Gigpro suggests a rating of 1 star when a Pro does not show up for a gig. You are welcome to change this rating if you would like.",
    };
  }
  if (reason === "CALLED_OUT") {
    const hoursBeforeGigStart = differenceInHours(gig.start_time, nowInTimezone(gig.location.timezone));
    if (hoursBeforeGigStart < RATING_CUTOFF_HOURS) {
      return {
        defaultRating: 2,
        defaultRatingMessage:
          "Gigpro suggests a rating of 2 stars when a Pro calls out less than 24 hours before the gig starts. You are welcome to change this rating if you would like.",
      };
    }
  }
  return {};
}

export function isGigFilled(gig: StafferGig): boolean {
  return gig.number_of_confirmed_applications === gig.allowed_confirmed_pros;
}

export function isGigStarted(gig: StafferGig): boolean {
  return isPast(gig.start_time, gig.location.timezone);
}

export function isGigInProgress(gig: StafferGig): boolean {
  return isGigStarted(gig) && !isGigEnded(gig);
}

export function isGigEnded(gig: StafferGig): boolean {
  return isPast(gig.end_time, gig.location.timezone);
}

type GigTimes = { start_time: Date; end_time: Date };
export const areGigsOverlapping = (gig: GigTimes, compareGig: GigTimes): boolean =>
  areIntervalsOverlapping(
    { start: gig.start_time, end: gig.end_time },
    { start: compareGig.start_time, end: compareGig.end_time },
  );

export function postProcessServerGigV2({ headshots, stats, ...gig }: StafferGigV2): StafferGig {
  return {
    ...gig,
    start_time: convertToTimezone(gig.start_time, gig.location.timezone ?? browserTimezone),
    end_time: convertToTimezone(gig.end_time, gig.location.timezone ?? browserTimezone),
    wage_amount: Number(gig.wage_amount),
    number_of_applications: stats?.total_applications_count ?? 0,
    number_of_open_applications:
      stats ?
        stats.total_applications_count -
        stats.confirmed_pros_count -
        stats.selected_applications_count -
        stats.cancelled_applications_count
      : 0,
    number_of_auto_matched_pros: stats?.confirmed_auto_matched_pros_count ?? 0,
    number_of_selected_applications: stats?.selected_applications_count ?? 0,
    number_of_confirmed_applications: stats?.confirmed_pros_count ?? 0,
    number_of_cancellations: stats?.cancelled_applications_count ?? 0,
    number_of_payments: stats?.total_payments_count ?? 0,
    number_of_failed_payments: stats?.failed_payments_count ?? 0,
    number_of_error_payments: stats?.error_payments_count ?? 0,
    number_of_submitted_payments: stats?.submitted_payments_count ?? 0,
    number_of_paid_pros: stats?.paid_pros_count ?? 0,
    public_headshots: headshots,
  };
}

export const trackGigPostV3Selection = (userId: number, status: boolean) => {
  track("Post Gig V3 Opt-in", { status: status, manager_id: userId });
  identify(userId, { post_gigs_v3_enabled: status });
};

export const isGigPastHours = (gig: StafferGig, hours: number) => {
  const end = gig.end_time;
  const now = nowInTimezone(gig.location.timezone);
  const diff = differenceInHours(now, end);
  return diff > hours;
};
