import React from "react";

import type { IconProps } from "./types";

export const Home = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46045 0.907986C9.18962 0.697338 8.81038 0.697338 8.53955 0.907986L1.78954 6.15799C1.60685 6.30008 1.5 6.51856 1.5 6.75V15C1.5 15.5967 1.73705 16.169 2.15901 16.591C2.58097 17.0129 3.15326 17.25 3.75 17.25H6.75H11.25H14.25C14.8467 17.25 15.419 17.0129 15.841 16.591C16.2629 16.169 16.5 15.5967 16.5 15V6.75C16.5 6.51856 16.3931 6.30008 16.2105 6.15799L9.46045 0.907986ZM12 15.75H14.25C14.4489 15.75 14.6397 15.671 14.7803 15.5303C14.921 15.3897 15 15.1989 15 15V7.11681L9 2.45015L3 7.11681V15C3 15.1989 3.07902 15.3897 3.21967 15.5303C3.36032 15.671 3.55109 15.75 3.75 15.75H6V9C6 8.58579 6.33579 8.25 6.75 8.25H11.25C11.6642 8.25 12 8.58579 12 9V15.75ZM7.5 15.75V9.75H10.5V15.75H7.5Z"
        fill={color}
      />
    </svg>
  );
});
Home.displayName = "Home";
