import { resultifyAsync } from "@gigpro/utils";
import { type AxiosError, isAxiosError } from "axios";
import type { FieldValues, Path, UseFormSetError } from "react-hook-form";

export type ErrorCode = "requires_payment_information";
export interface ApiErrorData {
  code: string;
  message: string;
  target?: string | null;
  target_value?: string | null;
  details?: Array<ApiErrorData>;
}

/**
 * A basic container for managing a standardized error returned from our APIs.
 * It can handle all known error formats from Axios and Fetch responses.
 *
 * @example
 * With Axios
 * ```
 * import { ApiError } from "@gigpro/api";
 *
 * try {
 *     const response = await axios.get("/api/v1/users");
 * } catch (e) {
 *     const error = await ApiError.fromThrown(response);
 *     console.error(error.message);
 * }
 * ```
 * @example
 * With Fetch
 * ```
 * import { ApiError } from "@gigpro/api";
 *
 * const response = await fetch("/api/v1/users");
 * if (!response.ok) {
 *     const error = await ApiError.fromThrown(response);
 *     console.error(error.message);
 * }
 * ```
 */
export class ApiError<
  T extends Record<string, any> = Record<string, any>,
  Raw extends Response | AxiosError = Response,
> extends Error {
  constructor(
    public error: ApiErrorData,
    public rawResponse: Raw | null,
  ) {
    super(`Request failed with code ${error.code}: ${error.message}`);
  }

  getFieldErrorMessage(fieldName: keyof T): string | undefined {
    if (!this.hasFieldError(fieldName)) return;
    if (fieldName === this.error.target) return this.error.message;
    return this.error.details?.find((detail) => fieldName === detail.target)?.message;
  }

  getMainErrorMessage(): string {
    return this.error.message;
  }

  getMainErrorCode(): string {
    return this.error.code;
  }

  getDetails(): Array<ApiErrorData> | undefined {
    return this.error.details;
  }

  hasFieldError(fieldName: keyof T) {
    return fieldName === this.error.target || !!this.error.details?.some((detail) => fieldName === detail.target);
  }

  hasErrorCode(code: ErrorCode) {
    return this.error.code === code || !!this.error.details?.some((detail) => detail.code === code);
  }

  setFormErrors<FormValues extends FieldValues = T>(
    setError: UseFormSetError<FormValues>,
    mapping: Partial<{ [key in keyof T]: Path<FormValues> }> = {},
  ) {
    const errorMessages = (this.error.details ?? []).reduce(
      (acc, detail) => {
        if (!detail.target) return acc;
        acc[detail.target as keyof T] = detail.message;
        return acc;
      },
      (this.error.target ? { [this.error.target]: this.error.message } : {}) as { [key in keyof T]: string },
    );

    for (const field of Object.keys(errorMessages)) {
      const key = (mapping[field] ?? field) as Path<FormValues>;
      setError(key, { type: "api", message: this.getFieldErrorMessage(field) });
    }
  }

  static unknown<Raw extends Response | AxiosError>(raw?: any) {
    return new ApiError<Record<string, never>, Raw>(
      { code: `${raw?.status ?? (raw as AxiosError).response?.status ?? "unknown"}`, message: "Unknown error" },
      raw ?? null,
    );
  }

  static readonly fromThrown = async <T extends Record<string, any>, Raw extends Response | AxiosError = AxiosError>(
    error: unknown,
  ): Promise<ApiError<T, Raw>> => {
    if (error instanceof ApiError) return error;
    if (error instanceof Response) return (await ApiError.fromResponse(error)) as ApiError<T, Raw>;
    if (isAxiosError(error)) return ApiError.fromAxiosError(error) as ApiError<T, Raw>;
    return ApiError.unknown(error);
  };

  private static readonly fromAxiosError = <T extends Record<string, any>>(
    axiosError: AxiosError<{ error: ApiErrorData }>,
  ): ApiError<T, AxiosError<{ error: ApiErrorData }>> => {
    const data = axiosError.response?.data.error;
    if (data === undefined || !data.code || !data.message) {
      return convertLegacyError(axiosError.response?.data, axiosError);
    }
    return new ApiError(data, axiosError);
  };

  private static readonly fromResponse = async <T extends Record<string, any>>(
    response: Response,
  ): Promise<ApiError<T>> => {
    const error = await resultifyAsync(() => response.json().then((data) => data.error ?? data))();
    if (error.ok && error.value.message && error.value.code) {
      return new ApiError<T>(error.value, response);
    }
    if (error.ok) return convertLegacyError(error.value, response);
    return ApiError.unknown(response);
  };
}

function convertLegacyError<T extends Record<string, any>, Raw extends Response | AxiosError>(
  error: Record<string, any> | Array<string> | undefined,
  raw: Raw | null = null,
): ApiError<T, Raw> {
  try {
    const status = raw instanceof Response ? raw.status : raw?.response?.status;
    if (!error || (status ?? 0) >= 500) return ApiError.unknown(raw);
    const code = "invalid";

    if (Array.isArray(error)) {
      return new ApiError({ code, message: error[0] }, raw);
    }

    const message: string | Array<string> | undefined = error.message ?? error.detail ?? error.reason ?? error;
    if (typeof message === "string" || Array.isArray(message)) {
      return new ApiError({ code, message: Array.isArray(message) ? message[0] : message }, raw);
    }

    const keys = Object.keys(error);
    if (keys.length > 0) {
      const firstError = error[keys[0]];
      const errorData: Partial<ApiErrorData> = {
        code,
        message: Array.isArray(firstError) ? firstError[0] : firstError,
        target: keys[0],
      };
      for (const key of keys.slice(1)) {
        errorData.details ??= [];
        errorData.details.push({ code, message: Array.isArray(error[key]) ? error[key][0] : error[key], target: key });
      }
      return new ApiError(errorData as ApiErrorData, raw);
    }
  } catch (e) {
    console.error("Failed to convert legacy error", e);
  }
  return ApiError.unknown(raw);
}
