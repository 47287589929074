import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import type { PublicGigster } from "api/resources/StafferGigApplication";
import type { ProHirePro } from "api/resources/pros";

import { ApiClient } from "./baseClient";

class StafferProClient extends ApiClient<PublicGigster> {
  endpoint = () => "/staffer/gigster-profiles";
}

class StafferProSearchClient extends ApiClient<ProHirePro> {
  endpoint = () => "/staffer/gigster-profiles/search";
}

export const stafferProClient = new StafferProClient();
export const stafferProSearchClient = new StafferProSearchClient();

export const BlockProData = z.object({
  gigster_id: z.number(),
  location_ids: z.array(z.number()),
  reason: z.enum([
    "CANCELLED_CONFIRMED_PRO",
    "CANCELLED_CALLED_OUT",
    "COMPLETED_GIG_AFTER_PAYMENT",
    "BLOCKED_BY_GIGPRO_ADMIN",
  ]),
  feedback: z.string().nullish(),
});

export type BlockProData = z.infer<typeof BlockProData>;

const prosApi = makeApi([
  {
    method: "post",
    path: "/staffer/pro-block/",
    parameters: [
      {
        name: "Block pro data",
        type: "Body",
        schema: BlockProData,
      },
    ],
    response: BlockProData.omit({ location_ids: true }).extend({ id: z.number() }),
    alias: "blockPro",
  },
]);

export const { client, hooks } = makeClientWithHooks("pros", prosApi);
