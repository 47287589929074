import React from "react";

import type { IconProps } from "./types";

export const CheckCircle = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M15.125 6.9375C15 6.79167 14.8438 6.71875 14.6563 6.71875C14.4896 6.71875 14.3333 6.79167 14.1875 6.9375C14.0625 7.0625 14 7.20833 14 7.375V8C14 9.64583 13.4063 11.0625 12.2188 12.25C11.0521 13.4167 9.64583 14 8 14C6.35417 14 4.9375 13.4167 3.75 12.25C2.58333 11.0625 2 9.64583 2 8C2 6.33333 2.58333 4.91667 3.75 3.75C4.9375 2.58333 6.35417 2 8 2C8.85417 2 9.66667 2.17708 10.4375 2.53125C10.6667 2.61458 10.875 2.59375 11.0625 2.46875C11.2708 2.32292 11.375 2.13542 11.375 1.90625C11.375 1.61458 11.25 1.41667 11 1.3125C10.0417 0.875 9.04167 0.65625 8 0.65625C5.97917 0.65625 4.25 1.375 2.8125 2.8125C1.375 4.25 0.65625 5.97917 0.65625 8C0.65625 10.0208 1.375 11.75 2.8125 13.1875C4.25 14.625 5.97917 15.3438 8 15.3438C9.33333 15.3438 10.5625 15.0104 11.6875 14.3438C12.8125 13.6979 13.6979 12.8125 14.3438 11.6875C15.0104 10.5625 15.3438 9.33333 15.3438 8V7.375C15.3438 7.20833 15.2708 7.0625 15.125 6.9375ZM15.3438 2.65625C15.3438 2.55208 15.3125 2.44792 15.25 2.34375C15.1875 2.23958 15.1042 2.15625 15 2.09375C14.8958 2.03125 14.7813 2 14.6563 2C14.4688 2 14.3125 2.0625 14.1875 2.1875L8 8.40625L6.46875 6.875C6.34375 6.75 6.1875 6.6875 6 6.6875C5.8125 6.6875 5.65625 6.75 5.53125 6.875C5.40625 7 5.34375 7.15625 5.34375 7.34375C5.34375 7.53125 5.40625 7.6875 5.53125 7.8125L7.53125 9.8125C7.65625 9.9375 7.8125 10 8 10C8.1875 10 8.34375 9.9375 8.46875 9.8125L15.125 3.125C15.2708 3 15.3438 2.84375 15.3438 2.65625Z"
        fill={color}
      />
    </svg>
  );
});
CheckCircle.displayName = "CheckCircle";
