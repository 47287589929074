import React from "react";

import type { IconProps } from "../types";

export const LineCook = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33213 5.40099C8.95014 3.98585 10.3611 3 12 3C13.6389 3 15.0499 3.98585 15.6679 5.40099C15.8243 5.7592 16.1751 5.99343 16.5659 6.00061C18.4683 6.03555 20 7.58901 20 9.5C20 11.0224 19.0275 12.3201 17.6668 12.8011C17.2672 12.9423 17 13.3201 17 13.7439V18H7V13.7439C7 13.3201 6.73284 12.9423 6.33325 12.8011C4.97251 12.3201 4 11.0224 4 9.5C4 7.58901 5.53171 6.03555 7.43407 6.00061C7.82489 5.99343 8.17569 5.7592 8.33213 5.40099ZM12 1C9.75832 1 7.80559 2.22921 6.77588 4.04728C4.08038 4.40188 2 6.7079 2 9.5C2 11.6386 3.22011 13.4901 5 14.4V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V14.4C20.7799 13.4901 22 11.6386 22 9.5C22 6.70789 19.9196 4.40188 17.2241 4.04728C16.1944 2.22921 14.2417 1 12 1ZM6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23H18C18.5523 23 19 22.5523 19 22C19 21.4477 18.5523 21 18 21H6Z"
        fill={color}
      />
    </svg>
  );
});

LineCook.displayName = "LineCook";
