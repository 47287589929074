import type { Card } from "@braze/web-sdk";
import { atom, getDefaultStore } from "jotai";

import { env } from "config/env";

export const contentCardsAtom = atom<Array<Card>>([]);
export const hasContentCardsAtom = atom((get) => get(contentCardsAtom).length > 0);

const getBraze = async () => {
  const braze = await import("@braze/web-sdk");
  if (braze.isInitialized()) return braze;
  braze.initialize(env.REACT_APP_BRAZE_KEY, {
    baseUrl: env.REACT_APP_BRAZE_ENDPOINT,
    serviceWorkerLocation: "/braze-service-worker.js",
  });
  return braze;
};

const initBraze = async () => {
  const braze = await getBraze();
  braze.requestPushPermission();
  braze.automaticallyShowInAppMessages();
  braze.openSession();
  braze.subscribeToContentCardsUpdates((contentCards) => getDefaultStore().set(contentCardsAtom, contentCards.cards));
  braze.requestContentCardsRefresh();
};

export { initBraze, getBraze };
