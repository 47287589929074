import React from "react";

import type { IconComponent } from "./types";

export const Plus: IconComponent = React.forwardRef(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M10.8203 4.17969C10.8203 4.02344 10.7812 3.88021 10.7031 3.75C10.651 3.61979 10.5599 3.51562 10.4297 3.4375C10.2995 3.35938 10.1562 3.32031 10 3.32031C9.76562 3.32031 9.57031 3.41146 9.41406 3.59375C9.25781 3.75 9.17969 3.94531 9.17969 4.17969V15.8203C9.17969 16.0547 9.25781 16.263 9.41406 16.4453C9.57031 16.6016 9.76562 16.6797 10 16.6797C10.0521 16.6797 10.1042 16.6797 10.1562 16.6797C10.2344 16.6536 10.2995 16.6276 10.3516 16.6016C10.4036 16.5495 10.4557 16.5104 10.5078 16.4844C10.5599 16.4583 10.599 16.4193 10.625 16.3672C10.6771 16.3151 10.7161 16.263 10.7422 16.2109C10.7682 16.1589 10.7812 16.1068 10.7812 16.0547C10.8073 15.9766 10.8203 15.8984 10.8203 15.8203V4.17969ZM4.17969 9.17969C3.94531 9.17969 3.73698 9.25781 3.55469 9.41406C3.39844 9.57031 3.32031 9.76562 3.32031 10C3.32031 10.2344 3.39844 10.4297 3.55469 10.5859C3.73698 10.7422 3.94531 10.8203 4.17969 10.8203H15.8203C16.0547 10.8203 16.25 10.7422 16.4062 10.5859C16.5885 10.4297 16.6797 10.2344 16.6797 10C16.6797 9.76562 16.5885 9.57031 16.4062 9.41406C16.25 9.25781 16.0547 9.17969 15.8203 9.17969H4.17969Z"
        fill={color}
      />
    </svg>
  );
});
Plus.displayName = "Plus";
Plus.alias = ["add"];
