import type { GetNextPageParamFunction } from "@tanstack/react-query";

import type { PageResult } from "api/django/PageResult";
import type { ApiResource } from "api/resources/resource";

export const getParams = (payload: Record<string, any> = {}, removeKey?: string): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(payload).forEach(([key, value]) => {
    if (value == null || removeKey === key) return;
    if (value instanceof Date) {
      params.append(key, value.toISOString());
      return;
    }
    if (Array.isArray(value)) {
      params.append(key, value.join(","));
      return;
    }
    params.append(key, value.toString());
  });
  return params;
};

export const getNextPageParam: GetNextPageParamFunction<PageResult<ApiResource>> = <T extends ApiResource>(
  lastPage: PageResult<T>,
) => {
  if (!lastPage?.next) return;
  const params = new URLSearchParams(new URL(lastPage.next).search);

  return Object.fromEntries(params);
};
