import React from "react";

import type { IconProps } from "./types";

export const ChevronDown = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.02851 6.02876C4.28886 5.76841 4.71097 5.76841 4.97132 6.02876L8.49992 9.55735L12.0285 6.02876C12.2889 5.76841 12.711 5.76841 12.9713 6.02876C13.2317 6.28911 13.2317 6.71122 12.9713 6.97157L8.97132 10.9716C8.71097 11.2319 8.28886 11.2319 8.02851 10.9716L4.02851 6.97157C3.76816 6.71122 3.76816 6.28911 4.02851 6.02876Z"
        fill={color}
      />
    </svg>
  );
});
ChevronDown.displayName = "ChevronDown";
