import React from "react";

import type { IconProps } from "./types";

export const GigsLogo = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return (
    <svg width="36" height="36" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <rect width="32" height="32" rx="4" fill="url(#paint0_linear_1779_13129)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 16C9.99996 12.6863 12.6863 9.99996 16 9.99996C19.3137 9.99996 22 12.6863 22 16C22 19.3137 19.3137 22 16 22C12.6863 22 9.99996 19.3137 9.99996 16ZM16 8.66663C11.9499 8.66663 8.66663 11.9499 8.66663 16C8.66663 20.05 11.9499 23.3333 16 23.3333C20.05 23.3333 23.3333 20.05 23.3333 16C23.3333 11.9499 20.05 8.66663 16 8.66663ZM16.6666 12C16.6666 11.6318 16.3681 11.3333 16 11.3333C15.6318 11.3333 15.3333 11.6318 15.3333 12V16C15.3333 16.2525 15.476 16.4833 15.7018 16.5962L18.3685 17.9296C18.6978 18.0942 19.0983 17.9608 19.2629 17.6314C19.4276 17.3021 19.2941 16.9017 18.9648 16.737L16.6666 15.5879V12Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1779_13129"
          x1="-14.6171"
          y1="16.461"
          x2="16.461"
          y2="47.539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6964DE" />
          <stop offset="1" stopColor="#FCA6E9" />
        </linearGradient>
      </defs>
    </svg>
  );
});
GigsLogo.displayName = "GigsLogo";
