import React from "react";

import type { IconProps } from "./types";

export const Award = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1645 5.77779C4.1645 3.6607 5.88074 1.94445 7.99783 1.94445C10.1149 1.94445 11.8312 3.6607 11.8312 5.77779C11.8312 7.10747 11.1542 8.27903 10.126 8.96657C10.0893 8.98554 10.0548 9.00791 10.0228 9.03323C9.43522 9.39952 8.74124 9.61113 7.99783 9.61113C5.88074 9.61113 4.1645 7.89489 4.1645 5.77779ZM9.90558 10.521C9.31612 10.7583 8.67223 10.8889 7.99783 10.8889C7.32366 10.8889 6.67998 10.7584 6.09068 10.5212L5.61118 14.1313L7.66908 12.8966C7.87141 12.7752 8.12417 12.7752 8.3265 12.8966L10.3846 14.1315L9.90558 10.521ZM4.89247 9.83771C3.67307 8.9036 2.88672 7.43253 2.88672 5.77779C2.88672 2.955 5.17504 0.666672 7.99783 0.666672C10.8206 0.666672 13.1089 2.955 13.1089 5.77779C13.1089 7.43224 12.3229 8.90308 11.1038 9.83722L11.8256 15.2771C11.8578 15.5203 11.748 15.7605 11.543 15.8951C11.3379 16.0298 11.0739 16.0352 10.8635 15.909L7.99779 14.1895L5.13205 15.909C4.92168 16.0352 4.65762 16.0298 4.45257 15.8951C4.24752 15.7604 4.13772 15.5202 4.17002 15.277L4.89247 9.83771Z"
        fill={color}
      />
    </svg>
  );
});
Award.displayName = "Award";
