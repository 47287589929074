import React from "react";

import type { IconProps } from "./types";

export const ArrowLeft = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0892 15.5892C13.4147 15.2638 13.4147 14.7362 13.0892 14.4107L8.67847 9.99998L13.0892 5.58924C13.4147 5.2638 13.4147 4.73616 13.0892 4.41072C12.7638 4.08529 12.2361 4.08529 11.9107 4.41072L6.9107 9.41072C6.58527 9.73616 6.58527 10.2638 6.9107 10.5892L11.9107 15.5892C12.2361 15.9147 12.7638 15.9147 13.0892 15.5892Z"
        fill={color}
      />
    </svg>
  );
});
ArrowLeft.displayName = "ArrowLeft";
