import React from "react";

import type { IconProps } from "./types";

export const Interview = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79176 3.60417C4.79176 4.49013 4.07354 5.20834 3.18759 5.20834C2.30163 5.20834 1.58342 4.49013 1.58342 3.60417C1.58342 2.71821 2.30163 2 3.18759 2C4.07354 2 4.79176 2.71821 4.79176 3.60417ZM14.4168 3.60417C14.4168 4.49013 13.6986 5.20834 12.8126 5.20834C11.9266 5.20834 11.2084 4.49013 11.2084 3.60417C11.2084 2.71821 11.9266 2 12.8126 2C13.6986 2 14.4168 2.71821 14.4168 3.60417ZM13.0417 6.12501C12.4071 6.18411 11.7932 6.3815 11.243 6.7033C10.6928 7.0251 10.2198 7.4635 9.85724 7.98768C9.61316 8.27662 9.34348 8.54292 9.05149 8.78334C9.21729 8.97713 9.33192 9.20935 9.38492 9.45882C9.43792 9.70829 9.4276 9.96706 9.3549 10.2115C9.88972 9.91681 10.3633 9.5226 10.7501 9.0501V13.4584C10.7501 13.7015 10.8467 13.9346 11.0186 14.1065C11.1905 14.2784 11.4236 14.375 11.6667 14.375H14.4167C14.6599 14.375 14.893 14.2784 15.0649 14.1065C15.2368 13.9346 15.3334 13.7015 15.3334 13.4584V8.41668C15.3334 7.80889 15.092 7.22599 14.6622 6.79622C14.2324 6.36645 13.6495 6.12501 13.0417 6.12501ZM6.14567 7.98768C6.76809 8.67976 7.18151 9.10418 7.81676 9.10418C7.9991 9.10418 8.17397 9.17661 8.3029 9.30554C8.43183 9.43447 8.50426 9.60934 8.50426 9.79168C8.50426 9.97402 8.43183 10.1489 8.3029 10.2778C8.17397 10.4067 7.9991 10.4792 7.81676 10.4792C7.30943 10.4475 6.81561 10.3027 6.3715 10.0555C5.92738 9.80819 5.54422 9.46467 5.25009 9.0501V13.4584C5.25009 13.7015 5.15351 13.9346 4.9816 14.1065C4.80969 14.2784 4.57654 14.375 4.33342 14.375H1.58342C1.3403 14.375 1.10714 14.2784 0.935234 14.1065C0.763325 13.9346 0.666748 13.7015 0.666748 13.4584V8.41668C0.666748 7.80889 0.908191 7.22599 1.33796 6.79622C1.76773 6.36645 2.35063 6.12501 2.95842 6.12501C3.59352 6.18372 4.20803 6.38093 4.75869 6.70275C5.30936 7.02456 5.78279 7.46316 6.14567 7.98768Z"
        fill={color}
      />
    </svg>
  );
});
Interview.displayName = "Interview";
