"use client";

import type { FieldPath, FieldPathValue, FieldValues, UseControllerProps } from "react-hook-form";
import { cn } from "tailwind-config";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "..";
import { Input, type InputProps } from "../../Input";
import type { FormFieldProps, Transform } from "../types";

export const Textfield = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  optional,
  placeholder,
  className,
  name,
  control,
  shouldUnregister,
  disabled,
  Tooltip,
  transform,
  ...inputProps
}: FormFieldProps<TFieldValues, TName, Transform<string, FieldPathValue<TFieldValues, TName>>, never, React.ReactNode> &
  InputProps) => {
  const formFieldProps: UseControllerProps<TFieldValues> = { name, control, shouldUnregister, disabled };
  return (
    <FormField
      {...formFieldProps}
      render={({ field, fieldState }) => (
        <FormItem className={cn("flex-1 space-y-1", className)}>
          <FormLabel className="flex flex-row gap-1" optional={optional}>
            {label}
            {Tooltip}
          </FormLabel>
          <FormControl>
            <Input
              className="w-full"
              placeholder={placeholder}
              {...inputProps}
              {...field}
              value={(transform && field.value != null ? transform.input(field.value) : field.value) ?? ""}
              onChange={transform ? (event) => field.onChange(transform.output(event.target.value)) : field.onChange}
              invalid={!!fieldState.error}
            />
          </FormControl>
          {!!description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

Textfield.displayName = "Textfield";
