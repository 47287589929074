import { requiredStringSchema } from "@gigpro/ui/Form";
import { browserTimezone } from "@gigpro/utils/datetime";
import { z } from "zod";

export const locationAddressSchema = z.object({
  id: z.number(),
  type: z.enum(["PRIMARY", "SECONDARY"]),
  street_address: requiredStringSchema,
  city: requiredStringSchema,
  state_code: requiredStringSchema,
  postal_code: requiredStringSchema,
  timezone: z.string().catch(() => browserTimezone),
  coordinates: z
    .object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    })
    .nullish(),
});
export type LocationAddress = z.infer<typeof locationAddressSchema>;
