import React from "react";

import type { IconProps } from "./types";

export const Calendar = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3333 1.33341C11.3333 0.965225 11.0348 0.666748 10.6666 0.666748C10.2984 0.666748 9.99992 0.965225 9.99992 1.33341V2.00008H5.99992V1.33341C5.99992 0.965225 5.70144 0.666748 5.33325 0.666748C4.96506 0.666748 4.66658 0.965225 4.66658 1.33341V2.00008H3.33325C2.22868 2.00008 1.33325 2.89551 1.33325 4.00008V6.66675V13.3334C1.33325 14.438 2.22868 15.3334 3.33325 15.3334H12.6666C13.7712 15.3334 14.6666 14.438 14.6666 13.3334V6.66675V4.00008C14.6666 2.89551 13.7712 2.00008 12.6666 2.00008H11.3333V1.33341ZM13.3333 6.00008V4.00008C13.3333 3.63189 13.0348 3.33341 12.6666 3.33341H11.3333V4.00008C11.3333 4.36827 11.0348 4.66675 10.6666 4.66675C10.2984 4.66675 9.99992 4.36827 9.99992 4.00008V3.33341H5.99992V4.00008C5.99992 4.36827 5.70144 4.66675 5.33325 4.66675C4.96506 4.66675 4.66658 4.36827 4.66658 4.00008V3.33341H3.33325C2.96506 3.33341 2.66659 3.63189 2.66659 4.00008V6.00008H13.3333ZM2.66659 7.33342H13.3333V13.3334C13.3333 13.7016 13.0348 14.0001 12.6666 14.0001H3.33325C2.96506 14.0001 2.66659 13.7016 2.66659 13.3334V7.33342Z"
        fill={color}
      />
    </svg>
  );
});
Calendar.displayName = "Calendar";
