import type { AnalyticsSnippet } from "@segment/analytics-next";
import React, { useContext } from "react";
import { UNSAFE_RouteContext } from "react-router-dom";

import type { ManagerProfile } from "api/clients/users";
import type { User } from "api/resources/User";
import { env } from "config/env";
import { getActiveLocationId } from "services/globalState";

import { initBraze } from "./braze";
import { removeEmptyFields } from "./forms";

export type AnalyticsUserTraits = {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string | undefined | null;
  email: string | undefined | null;
  isGigproAdmin: boolean;
  business: string | undefined | null;
  appMode: string | undefined | null;
  role: "PRO" | "MANAGER";
  marketId: number | undefined | null;
  market: string | undefined | null;
  locationId: number | undefined | null;
};

export const getAnalyticsUserTraits = (
  user: User,
  managerProfile: Partial<ManagerProfile> | undefined,
): AnalyticsUserTraits => ({
  id: user.id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email ?? managerProfile?.email,
  phoneNumber: user.phone_number,
  business: managerProfile?.business_name,
  marketId: managerProfile?.market_id,
  locationId: getActiveLocationId(),
  market: managerProfile?.market_name,
  isGigproAdmin: !!user.is_gigpro_admin,
  role: "MANAGER",
  appMode: user.app_mode,
});

if (env.REACT_APP_SEGMENT_WRITE_KEY) {
  window.analytics._writeKey = env.REACT_APP_SEGMENT_WRITE_KEY;
  window.analytics.load(env.REACT_APP_SEGMENT_WRITE_KEY);
}

const segmentAnalytics: AnalyticsSnippet = window.analytics;

export const identify = async (id: number, traits: Record<string, string | number | boolean | undefined | null>) => {
  const attributes: Record<string, string> = removeEmptyFields({ ...traits, environment: env.REACT_APP_ENV });
  await segmentAnalytics?.identify(`${id}`, attributes);
  initBraze();
};

export const group = (groupId: string, traits: Record<string, string | number | boolean>) => {
  segmentAnalytics?.group(groupId, traits);
};

export const track = (event: string, properties: Record<string, string | number | boolean | null | undefined>) => {
  segmentAnalytics?.track(event, properties);
  if (newrelic) {
    newrelic.addPageAction(
      event,
      Object.fromEntries(Object.entries(properties).map(([key, value]) => [key, String(value)])),
    );
  }
};

export const page = (page: string) => {
  segmentAnalytics?.page({ name: page, url: page });
  if (newrelic) newrelic.setCurrentRouteName(page);
};

export const useRouteTracker = () => {
  const routeContext = useContext(UNSAFE_RouteContext);
  const pattern = routeContext.matches
    .map(({ route: { path } }) => path)
    .filter((p) => p !== undefined)
    .join("/")
    .replaceAll("*", "")
    .replaceAll(/\/+/g, "/");

  React.useEffect(() => {
    page(pattern);
  }, [pattern]);
};
