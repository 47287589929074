import * as LabelPrimitive from "@radix-ui/react-label";
import * as React from "react";
import { cn } from "tailwind-config";

interface LabelProps extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  optional?: boolean;
}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, children, optional, ...props }, ref) => (
    <LabelPrimitive.Root
      ref={ref}
      className={cn("truncate text-large peer-disabled:cursor-not-allowed peer-disabled:opacity-70", className)}
      {...props}
    >
      {children}
      {optional && <span className="ml-1 font-regular text-mediumGray">(optional)</span>}
    </LabelPrimitive.Root>
  ),
);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
