import React from "react";

import type { IconProps } from "./types";

export const Clock = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00008 7.99984C2.00008 4.68613 4.68637 1.99984 8.00008 1.99984C11.3138 1.99984 14.0001 4.68613 14.0001 7.99984C14.0001 11.3135 11.3138 13.9998 8.00008 13.9998C4.68637 13.9998 2.00008 11.3135 2.00008 7.99984ZM8.00008 0.666504C3.94999 0.666504 0.666748 3.94975 0.666748 7.99984C0.666748 12.0499 3.94999 15.3332 8.00008 15.3332C12.0502 15.3332 15.3334 12.0499 15.3334 7.99984C15.3334 3.94975 12.0502 0.666504 8.00008 0.666504ZM8.66675 3.99984C8.66675 3.63165 8.36827 3.33317 8.00008 3.33317C7.63189 3.33317 7.33342 3.63165 7.33342 3.99984V7.99984C7.33342 8.25235 7.47608 8.48319 7.70194 8.59612L10.3686 9.92946C10.6979 10.0941 11.0984 9.96063 11.263 9.63131C11.4277 9.30199 11.2942 8.90155 10.9649 8.73689L8.66675 7.58781V3.99984Z"
        fill={color}
      />
    </svg>
  );
});
Clock.displayName = "Clock";
