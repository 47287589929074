import React from "react";

import type { IconProps } from "./types";

export const Pay = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.72727 7.66667C1.72727 4.20233 4.53566 1.39394 8 1.39394C11.4643 1.39394 14.2727 4.20233 14.2727 7.66667C14.2727 11.131 11.4643 13.9394 8 13.9394C4.53566 13.9394 1.72727 11.131 1.72727 7.66667ZM8 0C3.76581 0 0.333328 3.43248 0.333328 7.66667C0.333328 11.9008 3.76581 15.3333 8 15.3333C12.2342 15.3333 15.6667 11.9008 15.6667 7.66667C15.6667 3.43248 12.2342 0 8 0ZM7.60171 3.36039C7.60171 3.0442 7.85803 2.78788 8.17422 2.78788C8.49041 2.78788 8.74673 3.0442 8.74673 3.36039V4.38094H10.1656C10.4818 4.38094 10.7381 4.63726 10.7381 4.95344C10.7381 5.26963 10.4818 5.52595 10.1656 5.52595H8.74673V7.1688H9.16991C9.69144 7.1688 10.1916 7.37598 10.5604 7.74476C10.9292 8.11354 11.1364 8.61371 11.1364 9.13525C11.1364 9.65678 10.9292 10.157 10.5604 10.5257C10.1916 10.8945 9.69144 11.1017 9.16991 11.1017H8.74673V12.1223C8.74673 12.4384 8.49041 12.6948 8.17422 12.6948C7.85803 12.6948 7.60171 12.4384 7.60171 12.1223V11.1017H5.78463C5.46844 11.1017 5.21212 10.8454 5.21212 10.5292C5.21212 10.213 5.46844 9.95667 5.78463 9.95667H7.60171V8.31382H7.17857C6.65703 8.31382 6.15686 8.10664 5.78808 7.73786C5.4193 7.36908 5.21212 6.86891 5.21212 6.34738C5.21212 5.82585 5.4193 5.32567 5.78808 4.95689C6.15686 4.58811 6.65703 4.38094 7.17857 4.38094H7.60171V3.36039ZM7.17857 5.52595C6.96071 5.52595 6.75178 5.6125 6.59773 5.76654C6.44368 5.92059 6.35714 6.12952 6.35714 6.34738C6.35714 6.56523 6.44368 6.77417 6.59773 6.92821C6.75178 7.08226 6.96071 7.1688 7.17857 7.1688H7.60171V5.52595H7.17857ZM8.74673 8.31382V9.95667H9.16991C9.38777 9.95667 9.5967 9.87013 9.75075 9.71608C9.90479 9.56204 9.99134 9.3531 9.99134 9.13525C9.99134 8.91739 9.90479 8.70846 9.75075 8.55441C9.5967 8.40036 9.38776 8.31382 9.16991 8.31382H8.74673Z"
        fill={color}
      />
    </svg>
  );
});
Pay.displayName = "Pay";
