import { requiredStringSchema } from "@gigpro/ui/Form";
import {
  Recurrence,
  browserTimezone,
  convertToTimezone,
  convertToUtc,
  convertWeekdayToUTC,
  formatDate,
  weekdayNames,
} from "@gigpro/utils/datetime";
import { makeApi, makeParameters } from "@zodios/core";
import { makeClientWithHooks } from "api/helpers";
import { GigStatus } from "api/resources/StafferGig";
import { type GigPostPayload, gigPostPayloadSchema, gigSchema, offerableGigSchema } from "api/resources/gigs";
import { proHistoryGigSchema } from "api/resources/proProfile";
import { makePaginatedResult, paginationParams } from "api/resources/resource";
import { skillSchema } from "api/resources/skills";
import { z } from "zod";
import { BlockProData } from "./pros";
import { transformRecurrenceToApi } from "./recurrence";

const queryOptions = z.enum(["incomplete", "direct_offer", "history_by_skill"]);
const commonParams = makeParameters([
  ...paginationParams,
  { name: "ordering", type: "Query", schema: z.enum(["-created_ts"]).optional() },
]);

export type RecurrenceScope = "single" | "recurring";

const gigPatchSchema = z
  .intersection(
    z.discriminatedUnion("type", [
      gigSchema.pick({ wage_amount: true }).extend({ type: z.literal("wage_amount") }),
      gigSchema.pick({ contact_name: true, contact_phone: true }).extend({ type: z.literal("manager_on_duty") }),
      gigSchema.pick({ allowed_confirmed_pros: true }).extend({ type: z.literal("confirmed_pro") }),
      gigSchema.pick({ boosted_gig_bonus: true }).extend({ type: z.literal("boosted_amount") }),
      gigSchema.pick({ start_time: true, end_time: true }).extend({
        timezone: z.string(),
        recurrence: Recurrence.pick({ weekdays: true, occurrence: true }).optional(),
        type: z.literal("shift_times"),
      }),
      z.object({
        type: z.literal("recurrence_until"),
        timezone: z.string(),
        until: z.date(),
      }),
      gigSchema.pick({ special_inst: true }).extend({ type: z.literal("private_notes") }),
    ]),
    z.object({ include_recurring_gigs: z.boolean().optional() }),
  )
  .transform((data) => {
    const { type, ...payload } = data;
    if (data.type === "shift_times") {
      const recurrence = data.recurrence;
      return {
        ...payload,
        start_time: convertToUtc(data.start_time, data.timezone),
        end_time: convertToUtc(data.end_time, data.timezone),
        recurring_pattern:
          recurrence ?
            {
              byweekdays: recurrence.weekdays.map((weekday) => ({
                weekday: weekdayNames[convertWeekdayToUTC(weekday, data.start_time, data.timezone)],
                occurrence: recurrence.occurrence,
              })),
            }
          : undefined,
        recurrence: undefined,
      };
    }
    if (data.type === "recurrence_until") {
      return {
        ...payload,
        recurring_pattern: { until: convertToUtc(data.until, data.timezone) },
        recurrence: undefined,
      };
    }
    return payload;
  });

export const gigCancellationPostPayloadSchema = z.object({
  gig_id: z.number(),
  reason: z.enum(["CALLED_OUT", "CORRECTION", "NOT_NEEDED", "NO_SHOW", "OTHER"]),
  explanation: requiredStringSchema,
  block_pro_data: BlockProData.optional(),
  rating: z.number().optional(),
  include_recurring_gigs: z.boolean().optional(),
});
export type GigCancellationPostPayload = z.infer<typeof gigCancellationPostPayloadSchema>;
export type GigCancellationReason = GigCancellationPostPayload["reason"];

const transformGigPostPayload = ({ start_time, end_time, recurrence, timezone, ...gig }: Partial<GigPostPayload>) =>
  timezone ?
    {
      ...gig,
      start_time: start_time ? convertToUtc(start_time, timezone) : start_time,
      end_time: end_time ? convertToUtc(end_time, timezone) : end_time,
      recurring_pattern:
        recurrence?.repeat === "repeat" && start_time ?
          transformRecurrenceToApi(recurrence, start_time, timezone)
        : undefined,
    }
  : {
      ...gig,
      start_time,
      end_time,
      recurring_pattern:
        recurrence?.repeat === "repeat" && start_time ?
          transformRecurrenceToApi(recurrence, start_time, browserTimezone)
        : undefined,
    };

const calendarGig = gigSchema
  .pick({
    id: true,
    start_time: true,
    end_time: true,
    skill: true,
    wage_amount: true,
    allowed_confirmed_pros: true,
    is_recurring: true,
    status: true,
  })
  .extend({ timezone: z.string().catch(browserTimezone) });

const stafferGigsPath = "/staffer/gigs/";
const stafferGigPath = `${stafferGigsPath}:gigId/`;
const stafferGigsApi = makeApi([
  {
    method: "post",
    path: stafferGigsPath,
    response: gigSchema
      .omit({
        skill: true,
        location: true,
        status: true,
      })
      .extend({ skill: skillSchema })
      .transform((gig) => ({ ...gig, skill: gig.skill.name })),
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: gigPostPayloadSchema.transform(transformGigPostPayload),
      },
    ],
    alias: "postGig",
  },
  {
    method: "post",
    path: `${stafferGigsPath}?save=false`,
    response: gigSchema.partial(),
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: gigPostPayloadSchema.partial().transform(transformGigPostPayload),
      },
    ],
    alias: "validateGig",
  },
  {
    method: "patch",
    path: stafferGigPath,
    response: gigSchema.partial(),
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: gigPatchSchema,
      },
    ],
    alias: "editGig",
  },
  {
    method: "post",
    path: "gig-cancellations/",
    response: gigCancellationPostPayloadSchema.extend({ id: z.number() }),
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: gigCancellationPostPayloadSchema,
      },
    ],
    alias: "cancelGig",
  },
  {
    method: "get",
    path: `${stafferGigsPath}?query=${queryOptions.Enum.history_by_skill}`,
    response: makePaginatedResult(proHistoryGigSchema),
    parameters: [
      ...commonParams,
      { name: "pro_id", type: "Query", schema: z.number() },
      { name: "skill_id", type: "Query", schema: z.number() },
    ],
  },
  {
    method: "get",
    path: `${stafferGigsPath}?query=${queryOptions.Enum.direct_offer}`,
    response: makePaginatedResult(offerableGigSchema).transform((data) => ({
      ...data,
      results: data.results.map((gig) => ({
        ...gig,
        start_time: convertToTimezone(gig.start_time, gig.location.timezone ?? browserTimezone),
        end_time: convertToTimezone(gig.end_time, gig.location.timezone ?? browserTimezone),
      })),
    })),
    parameters: [
      ...commonParams,
      { name: "pro_id", type: "Query", schema: z.number() },
      { name: "location_name", type: "Query", schema: z.string().optional() },
    ],
    alias: "directOfferGigs",
  },
  {
    method: "get",
    path: `${stafferGigsPath}v2/calendar/`,
    parameters: [
      {
        type: "Query",
        name: "location_id",
        schema: z.number(),
      },
      {
        type: "Query",
        name: "start_time",
        schema: z.date().transform((date) => formatDate(date, "MM/dd/yyyy")),
      },
      {
        type: "Query",
        name: "end_time",
        schema: z.date().transform((date) => formatDate(date, "MM/dd/yyyy")),
      },
      {
        type: "Query",
        name: "skill_ids",
        schema: z
          .array(z.number())
          .transform((ids) => ids.join(","))
          .optional(),
      },
      {
        type: "Query",
        name: "statuses",
        schema: z
          .array(z.nativeEnum(GigStatus))
          .transform((statuses) => statuses.join(","))
          .optional(),
      },
    ],
    response: z.array(calendarGig),
    alias: "calendar",
  },
]);

export const { client, hooks } = makeClientWithHooks("stafferGigs", stafferGigsApi);
