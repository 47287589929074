import React from "react";

import type { IconProps } from "./types";

export const Support = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg ref={ref} width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.7829 3.2256C16.6249 1.06854 14.0372 0 11.01 0C7.98274 0 5.3851 1.07853 3.22707 3.2256C1.06903 5.37267 0 7.96913 0 11.005C0 14.0409 1.07902 16.6173 3.22707 18.7744C5.3851 20.9315 7.97275 22 11 22C14.0272 22 16.6149 20.9215 18.7729 18.7744C20.931 16.6173 22 14.0309 22 11.005C22 7.97912 20.921 5.39265 18.7729 3.23559L18.7829 3.2256ZM18.0136 16.5974L15.1762 13.7612C15.7357 12.9424 16.0154 12.0236 16.0154 10.995C16.0154 9.96641 15.7357 9.04766 15.1662 8.23877L18.0036 5.40263C19.3324 7.00045 20.0018 8.85792 20.0018 10.985C20.0018 13.1121 19.3424 14.9696 18.0236 16.5874L18.0136 16.5974ZM8.89192 13.1021C8.30245 12.5129 8.00272 11.8039 8.00272 10.995C8.00272 10.1861 8.30245 9.47708 8.89192 8.88788C9.48138 8.29868 10.1907 7.99909 11 7.99909C11.8093 7.99909 12.5186 8.29868 13.1081 8.88788C13.6975 9.47708 13.9973 10.1861 13.9973 10.995C13.9973 11.8039 13.6975 12.5129 13.1081 13.1021C12.5186 13.6913 11.8093 13.9909 11 13.9909C10.1907 13.9909 9.48138 13.6913 8.89192 13.1021ZM16.5849 4.00454L13.7475 6.84067C12.9482 6.27145 12.0291 5.99183 10.99 5.99183C9.95095 5.99183 9.04178 6.27145 8.22252 6.83069L5.3851 3.99455C7.00363 2.67635 8.86194 2.01725 10.99 2.01725C13.1181 2.01725 14.9864 2.68634 16.5749 4.01453L16.5849 4.00454ZM3.97638 5.39265L6.81381 8.22878C6.25431 9.03768 5.97457 9.95642 5.97457 10.995C5.97457 12.0336 6.25431 12.9523 6.79382 13.7812L3.9564 16.6173C2.64759 14.9995 1.99818 13.1221 1.99818 10.995C1.99818 8.86791 2.65758 6.99047 3.97638 5.39265ZM5.36512 18.0254L8.20254 15.1893C9.0218 15.7385 9.95095 16.0082 10.99 16.0082C12.0291 16.0082 12.9582 15.7286 13.7575 15.1693L16.5949 18.0054C14.9964 19.3237 13.1281 19.9828 10.99 19.9828C8.85195 19.9828 6.98365 19.3236 5.36512 18.0254Z"
        fill={color}
      />
    </svg>
  );
});
Support.displayName = "Support";
