import {
  Recurrence,
  type RecurrenceWithRepeat,
  browserTimezone,
  convertToTimezone,
  convertToUtc,
  convertWeekdayToTimezone,
  convertWeekdayToUTC,
  frequencyOptions,
  weekdayNames,
} from "@gigpro/utils/datetime";
import { makeApi } from "@zodios/core";
import { isBefore } from "date-fns";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import { gigSchema } from "api/resources/gigs";

const stafferGigsPath = "/staffer/gigs/";
const stafferGigPath = `${stafferGigsPath}:gigId/`;

const recurrenceHelperPath = `${stafferGigsPath}v2/recurring-pattern/`;

const recurringPatternSchema = z.object({
  freq: z.enum(frequencyOptions),
  interval: z.number(),
  byweekdays: z.array(z.object({ weekday: z.enum(weekdayNames), occurrence: z.number() })),
  until: z.coerce.date().nullish(),
});

export const transformRecurrenceToApi = (
  recurrence: Recurrence,
  baseDate: Date,
  timezone: string,
): z.infer<typeof recurringPatternSchema> => ({
  freq: recurrence.frequency,
  interval: recurrence.interval,
  until: recurrence.until ? convertToUtc(recurrence.until, timezone) : undefined,
  byweekdays: recurrence.weekdays.map((day) => ({
    weekday: weekdayNames[convertWeekdayToUTC(day, baseDate, timezone)],
    occurrence: recurrence.occurrence,
  })),
});

export const transformRecurrenceFromApi = (
  recurrence: z.infer<typeof recurringPatternSchema>,
  baseDate: Date,
  timezone: string,
): Recurrence => ({
  frequency: recurrence.freq,
  interval: recurrence.interval,
  until: recurrence.until ? convertToTimezone(recurrence.until, timezone) : undefined,
  weekdays: recurrence.byweekdays.map((day) =>
    convertWeekdayToTimezone(weekdayNames.indexOf(day.weekday), baseDate, timezone),
  ),
  occurrence: recurrence.byweekdays[0].occurrence,
});

const PhantomGig = gigSchema
  .pick({
    start_time: true,
    end_time: true,
    skill: true,
    wage_amount: true,
    allowed_confirmed_pros: true,
  })
  .extend({ timezone: z.string().default(browserTimezone) });
export type PhantomGig = z.infer<typeof PhantomGig>;

const recurrenceApis = makeApi([
  {
    method: "get",
    path: `${stafferGigPath}recurring-pattern/`,
    response: recurringPatternSchema.transform((recurringPattern): RecurrenceWithRepeat => {
      const { freq: frequency, interval, byweekdays } = recurringPattern;
      const until = recurringPattern.until ?? undefined;
      const weekdays = byweekdays.map(({ weekday }) => weekdayNames.indexOf(weekday));
      const occurrence = byweekdays[0].occurrence;

      return { weekdays, frequency, interval, occurrence, until, repeat: "repeat" };
    }),
    alias: "recurringPatternUTC",
  },
  {
    method: "post",
    immutable: true,
    path: `${recurrenceHelperPath}dates/`,
    parameters: [
      {
        name: "Recurrence",
        type: "Body",
        schema: Recurrence.extend({ start: z.date(), end: z.date() }).transform(({ start, end, ...data }) => {
          const apiRecPattern = transformRecurrenceToApi(data, start, "UTC");
          return {
            ...apiRecPattern,
            dtstart: start,
            until: isBefore(end, data.until ?? end) ? end : (data.until ?? end),
          };
        }),
      },
    ],
    response: z.object({ dates: z.array(z.coerce.date()) }),
    alias: "recurrencePreview",
  },
  {
    method: "get",
    path: `${recurrenceHelperPath}gigs/`,
    parameters: [
      {
        name: "location_id",
        type: "Query",
        schema: z.number(),
      },
      {
        name: "start_time",
        type: "Query",
        schema: z.date(),
      },
      {
        name: "end_time",
        type: "Query",
        schema: z.date(),
      },
      {
        type: "Query",
        name: "skill_ids",
        schema: z
          .array(z.number())
          .transform((ids) => ids.join(","))
          .optional(),
      },
    ],
    response: z.array(
      PhantomGig.transform((data) => ({
        ...data,
        start_time: convertToTimezone(data.start_time, data.timezone),
        end_time: convertToTimezone(data.end_time, data.timezone),
      })),
    ),
    alias: "phantomGigs",
  },
]);

export const { client, hooks } = makeClientWithHooks("recurrence", recurrenceApis);
