import type Stripe from "stripe";

import type { Location as SchemaLocation } from "api/clients/locationsAndOrganizations";
import type { ReferralCodeSignup } from "./ReferralCodeSignup";
import type { User } from "./User";

export const IMAGE_WIDTH = 60;
export const IMAGE_WIDTH_PX = `${IMAGE_WIDTH}px`;

export type LocationStatus = SchemaLocation["approval_status"];
export interface Location extends SchemaLocation {
  creator?: User;
  created_ts?: string;
  referral_signup?: ReferralCodeSignup;
  background_checks_fee_percentage?: number;
  stripe_payment_method_type?: "card" | "us_bank_account";
  gigpro_fee_percentage?: number;
}

export type PaymentMethod = Stripe.PaymentMethod;
export type BillingInfo = {
  details: PaymentMethod | null | undefined;
  id: string | undefined | null;
  type: "card" | "us_bank_account" | undefined | null;
  invoicing_enabled: boolean;
  status: "REQUIRES_ACTION" | "ACTIVE" | "FAILED" | "NOT_CONFIGURED";
};
