import React from "react";

import type { IconProps } from "../types";

export const BakerPastry = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C5.34315 2 4 3.34315 4 5L4 7L4 9V10.1707C2.83481 10.5825 2 11.6938 2 13V20C1.44772 20 1 20.4477 1 21C1 21.5523 1.44772 22 2 22H3H21H22C22.5523 22 23 21.5523 23 21C23 20.4477 22.5523 20 22 20V13C22 11.6938 21.1652 10.5825 20 10.1707V9V7V5C20 3.34315 18.6569 2 17 2H7ZM18 5.65512V5C18 4.44772 17.5523 4 17 4H7C6.44772 4 6 4.44772 6 5V5.65512C6.47229 5.30312 7.10388 5 8 5C9.41529 5 10.1708 5.7561 10.6809 6.26671L10.7071 6.29289C11.1966 6.78239 11.4397 7 12 7C12.5603 7 12.8034 6.78239 13.2929 6.29289L13.3191 6.26671C13.8292 5.7561 14.5847 5 16 5C16.8961 5 17.5277 5.30312 18 5.65512ZM6 8.34488V10H18V8.34488C17.7264 8.14094 17.5062 7.9206 17.3191 7.73329L17.2929 7.70711C16.8034 7.21761 16.5603 7 16 7C15.4397 7 15.1966 7.21761 14.7071 7.70711L14.6809 7.73329C14.1708 8.2439 13.4153 9 12 9C10.5847 9 9.82925 8.2439 9.31906 7.73329L9.29289 7.70711C8.8034 7.21761 8.56032 7 8 7C7.43968 7 7.1966 7.21761 6.70711 7.70711L6.68094 7.73329C6.49379 7.9206 6.27363 8.14094 6 8.34488ZM4 13C4 12.4477 4.44772 12 5 12H19C19.5523 12 20 12.4477 20 13V14C18.5847 14 17.8292 14.7561 17.3191 15.2667L17.2929 15.2929C16.8034 15.7824 16.5603 16 16 16C15.4397 16 15.1966 15.7824 14.7071 15.2929L14.6809 15.2667C14.1708 14.7561 13.4153 14 12 14C10.5847 14 9.82925 14.7561 9.31906 15.2667L9.29289 15.2929C8.8034 15.7824 8.56032 16 8 16C7.43968 16 7.1966 15.7824 6.70711 15.2929L6.68094 15.2667C6.17075 14.7561 5.41529 14 4 14V13ZM18.7071 16.7071C19.1966 16.2176 19.4397 16 20 16V20H4V16C4.56031 16 4.8034 16.2176 5.29289 16.7071L5.31906 16.7333C5.82925 17.2439 6.58471 18 8 18C9.41529 18 10.1708 17.2439 10.6809 16.7333L10.7071 16.7071C11.1966 16.2176 11.4397 16 12 16C12.5603 16 12.8034 16.2176 13.2929 16.7071L13.3191 16.7333C13.8292 17.2439 14.5847 18 16 18C17.4153 18 18.1708 17.2439 18.6809 16.7333L18.7071 16.7071Z"
        fill={color}
      />
    </svg>
  );
});

BakerPastry.displayName = "BakerPastry";
