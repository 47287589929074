import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import { makePaginatedResult } from "api/resources/resource";

const gigproCertificateSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  brand_id: z.string().nullish(),
  skill_id: z.number().nullish(),
});
export type GigproCertificate = z.infer<typeof gigproCertificateSchema>;

const gigproCertificationStatus = z.enum(["CERTIFIED", "DISQUALIFIED"]);
export type GigproCertificationStatus = z.infer<typeof gigproCertificationStatus>;

const gigproCertificationSchema = z.object({
  id: z.string(),
  certificate: gigproCertificateSchema,
  status: gigproCertificationStatus,
  pro_id: z.number(),
});
export type GigproCertification = z.infer<typeof gigproCertificationSchema>;

const gigproCertificationCreateSchema = z.object({
  id: z.string(),
  pro_id: z.number(),
  certificate_id: z.string(),
  status: gigproCertificationStatus,
});
export type GigproCertificationCreate = z.infer<typeof gigproCertificationCreateSchema>;

const certificatesApi = makeApi([
  {
    method: "get",
    path: "/certificates/",
    response: makePaginatedResult(gigproCertificateSchema),
    alias: "certificates",
  },
  {
    method: "post",
    path: "/pro-certifications/",
    response: gigproCertificationCreateSchema,
    parameters: [
      {
        name: "Certification",
        type: "Body",
        schema: gigproCertificationCreateSchema.omit({ id: true }),
      },
    ],
    alias: "createCertification",
  },
  {
    method: "patch",
    path: "/pro-certifications/:certificationId/",
    response: gigproCertificationCreateSchema.pick({ id: true, status: true }),
    parameters: [{ name: "Status", type: "Body", schema: gigproCertificationCreateSchema.pick({ status: true }) }],
    alias: "updateCertification",
  },
]);

export const { client, hooks } = makeClientWithHooks("certificates", certificatesApi);
