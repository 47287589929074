import React from "react";

import type { IconProps } from "./types";

export const Help = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9.19368 6.01821C8.84454 5.95832 8.48547 6.02393 8.18007 6.20342C7.87466 6.38291 7.64264 6.66469 7.52509 6.99885C7.38763 7.3896 6.95945 7.59493 6.5687 7.45747C6.17796 7.32002 5.97263 6.89183 6.11009 6.50109C6.34519 5.83276 6.80924 5.2692 7.42004 4.91022C8.03084 4.55125 8.74898 4.42003 9.44726 4.5398C10.1455 4.65957 10.7789 5.02261 11.2352 5.56462C11.6913 6.10651 11.941 6.79233 11.9401 7.50065C11.9397 8.64842 11.0888 9.40639 10.4811 9.81151C10.1544 10.0293 9.833 10.1895 9.59625 10.2947C9.47681 10.3478 9.37624 10.3881 9.30376 10.4157C9.26746 10.4295 9.23804 10.4402 9.21661 10.4478L9.19057 10.457L9.18227 10.4598L9.17935 10.4608L9.1782 10.4612C9.17797 10.4612 9.17726 10.4615 8.94009 9.74997L9.17726 10.4615C8.7843 10.5925 8.35956 10.3801 8.22857 9.98714C8.09768 9.59445 8.30968 9.17001 8.70213 9.03872L8.7141 9.03452C8.72564 9.03042 8.74455 9.02356 8.76977 9.01395C8.82034 8.99468 8.89555 8.96465 8.98705 8.92399C9.17217 8.84171 9.41329 8.72062 9.64906 8.56343C10.1663 8.21863 10.4401 7.85182 10.4401 7.49997V7.49885C10.4406 7.14461 10.3158 6.80162 10.0876 6.53062C9.8595 6.25962 9.54282 6.0781 9.19368 6.01821ZM9 13.5C9.41421 13.5 9.75 13.1642 9.75 12.75C9.75 12.3358 9.41421 12 9 12C8.58579 12 8.25 12.3358 8.25 12.75C8.25 13.1642 8.58579 13.5 9 13.5Z"
        fill={color}
      />
    </svg>
  );
});
Help.displayName = "Help";
