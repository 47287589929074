import { phoneNumberSchema } from "@gigpro/ui/Form";
import * as z from "zod";

import { managerOnDutySchema } from "./managerOnDuty";
import { proContractorTypeSchema } from "./proPreferences";

export const jobSchema = z.object({
  id: z.string(),
  name: z.string(),
  wage: z.coerce.number(),
  external_application_link: z.string().nullish(),
  status: z.enum(["open", "filled", "archived"]),
  location: z.number(),
  position: z.number(),
  candidate_count: z.number().default(0),
  job_type: proContractorTypeSchema,
  job_type_id: z.number(),
});
export type Job = z.infer<typeof jobSchema>;

export const jobPostSchema = jobSchema.omit({ id: true, candidate_count: true, job_type: true });
export type JobPost = z.infer<typeof jobPostSchema>;

export const candidateSchema = z.object({
  id: z.string(),
  first_name: z.string().nonempty("First name is required"),
  last_name: z.string().nonempty("Last name is required"),
  phone_number: phoneNumberSchema,
  email: z.string().email("Invalid email address"),
});
export type Candidate = z.infer<typeof candidateSchema>;

const contactSchema = managerOnDutySchema.omit({ location_id: true }).extend({ phone_number: phoneNumberSchema });

export const stafferInterviewSchema = z.object({
  id: z.string(),
  location: z.object({
    id: z.number(),
    name: z.string(),
    street_address: z.string(),
    city: z.string(),
    state_code: z.string(),
    image_public_id: z.string().nullish(),
  }),
  wage: z.coerce.number(),
  status: z.enum([
    "INVITED",
    "CONFIRMED",
    "DECLINED",
    "PENDING_PAYMENT",
    "PAYMENT_SUBMITTED",
    "PAYMENT_FAILED",
    "PAYMENT_ERROR",
    "PAID",
    "REVIEWED",
  ]),
  channel_url: z.string().nullish(),
  instructions: z.string().nullish(),
  job_candidate: z.string(),
  job_posting: z.string(),
  job_posting_name: z.string(),
  start_time: z.coerce.date(),
  end_time: z.coerce.date(),
  gig_id: z.number().nullable(),
  gig_application_id: z.number().nullable(),
  candidate: candidateSchema,
});
export type StafferInterview = z.infer<typeof stafferInterviewSchema>;

export const stafferInterviewPostSchema = stafferInterviewSchema
  .pick({
    wage: true,
    instructions: true,
    job_posting: true,
    start_time: true,
    end_time: true,
  })
  .extend({
    candidate: candidateSchema.omit({ id: true }),
    interview_contact: contactSchema.omit({ id: true }),
  });
export type StafferInterviewPost = z.infer<typeof stafferInterviewPostSchema>;

export const stafferReviewSchema = z.object({
  feedback: z.enum(["LIKELY_HIRE", "HAS_POTENTIAL", "PASS"]),
});
export type StafferReview = z.infer<typeof stafferReviewSchema>;
export type StafferInterviewFeedback = StafferReview["feedback"];
