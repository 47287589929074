import { type SanitizedPath, boolean, number, route, string, union } from "react-router-typesafe-routes/dom";

export const CONNECT_ROUTES = <const T extends string>(basePath: SanitizedPath<T>) =>
  route(
    basePath,
    {
      searchParams: {
        ordering: union(["completion_rate", "rating", "gigs_worked"] as const).default("gigs_worked"),

        market_id: number(),
        pro_uuid: string(),

        is_favorite: boolean(),
        is_verified: boolean(),
        has_worked_together: boolean(),
        has_background_check: boolean(),

        rating_gte: number(),
        time_blocks: number().defined().array(),
        certifications: number().defined().array(),
        pro_certifications: string().defined().array(),
        days_of_week: string().defined().array(),
        hours_per_week: number().defined().array(),
        contractor_types: number().defined().array(),
        previous_positions: number().defined().array(),
        badge_types: string().defined().array(),

        pro_fullname: string(),

        skill_group: string(),

        signup: boolean().default(true),
      },
    },
    {
      PROFILE: route(":proId", {
        params: { proId: number().defined() },
        searchParams: { offerType: union(["select", "existing"] as const) },
      }),
    },
  );
