import { makeApi, makeEndpoint } from "@zodios/core";

import { makeClientWithHooks } from "api/helpers";
import {
  chatTokenAuthSchema,
  directMessagesAuthSchema,
  directMessagesChatSchema,
  supportMessageSchema,
  unreadMessageCountSchema,
} from "api/resources/messaging";

import { makePaginatedResult, paginationParams } from "../resources/resource";

const messagingBasePath = "/messaging/chat";

const chatTokenEndpoint = makeEndpoint({
  method: "get",
  path: `${messagingBasePath}/:chatToken/`,
  response: chatTokenAuthSchema,
  alias: "chatTokenAuth",
});

const messagingApi = makeApi([
  chatTokenEndpoint,
  {
    method: "get",
    path: `${messagingBasePath}/location/:locationId/auth/`,
    response: directMessagesAuthSchema,
    alias: "locationChatAuth",
  },
  {
    method: "get",
    path: `${messagingBasePath}/location/:locationId/unread-count/`,
    response: unreadMessageCountSchema,
    alias: "unreadMessageCount",
  },
  {
    method: "get",
    path: `${messagingBasePath}/staffer/:userId/auth/`,
    response: directMessagesAuthSchema,
    alias: "managerChatAuth",
  },
  {
    method: "post",
    path: "/messaging/direct-messages/chat/",
    response: directMessagesChatSchema,
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: directMessagesChatSchema.omit({ channel_url: true }),
      },
    ],
    alias: "proLocationChat",
  },
  {
    method: "get",
    path: "/messaging/support-channels/",
    response: makePaginatedResult(supportMessageSchema),
    parameters: paginationParams,
    alias: "getSupportChannels",
  },
  {
    method: "post",
    path: "/messaging/support-channels/",
    response: supportMessageSchema,
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: supportMessageSchema.pick({ recipient_id: true, recipient_type: true }),
      },
    ],
    alias: "createSupportChannel",
  },
]);

export const { client, hooks } = makeClientWithHooks("messaging", messagingApi);
export const { client: chatTokenClient, hooks: chatTokenHooks } = makeClientWithHooks(
  "chatToken",
  [chatTokenEndpoint] as const,
  { skipAuth: true },
);
