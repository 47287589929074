import { optionalPhoneNumberSchema, transformPhoneOutput } from "@gigpro/ui/Form";
import { makeApi } from "@zodios/core";
import { isPast } from "date-fns";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";

export const InviteStatus = z.enum(["SUBMITTED", "ACCEPTED", "ERROR", "EXPIRED"]);
export type InviteStatus = z.infer<typeof InviteStatus>;

const locationManagerRoleSchema = z.enum(["ADMIN", "TEAM_MEMBER"]);
export type LocationManagerRole = z.infer<typeof locationManagerRoleSchema>;

const locationManagerSchema = z.object({
  id: z.number(),
  location: z.number(),
  role: locationManagerRoleSchema,
  user: z.object({
    id: z.number(),
    email: z.string().nullish(),
    first_name: z.string(),
    last_name: z.string(),
    phone_number: z.string().nullish(),
  }),
});
export type LocationManager = z.infer<typeof locationManagerSchema>;
const locationInviteSchema = z.object({
  id: z.number(),
  status: InviteStatus.exclude(["ACCEPTED"]),
  expiration_date: z.coerce.date().optional(),
  link: z.string().nullish(),
  email: z.string().email(),
  location_id: z.number(),
  role: locationManagerRoleSchema,
  phone_number: z.string().nullish(),
  manager_name: z.string().nullish(),
});
export type LocationInvite = z.infer<typeof locationInviteSchema>;
export const locationInvitePayload = locationInviteSchema
  .omit({
    id: true,
    status: true,
    expiration_date: true,
    link: true,
    phone_number: true,
  })
  .extend({
    phone_number: optionalPhoneNumberSchema.optional(),
  });

export const orgManagerRoleSchema = z.enum(["ADMIN"]);
export type OrgManagerRole = z.infer<typeof orgManagerRoleSchema>;
export const orgInviteSchema = locationInviteSchema
  .omit({
    location_id: true,
    role: true,
  })
  .extend({
    id: z.string(),
    status: InviteStatus,
    role: orgManagerRoleSchema,
  });
export type OrgInvite = Omit<z.infer<typeof orgInviteSchema>, "status"> & {
  status: Exclude<InviteStatus, "ACCEPTED">;
};
export const orgManagerSchema = z.object({
  id: z.string(),
  organization_id: z.string(),
  role: orgManagerRoleSchema,
  user: locationManagerSchema.shape.user,
});
export type OrgManager = z.infer<typeof orgManagerSchema>;
export type OrgLocationManager = Omit<LocationManager, "location"> & { locations: Array<number> };

const invitationsPath = "/invitations";
const locationManagersPath = "/location-managers";

const orgPath = "/staffer/organizations/:orgId";
const orgManagersPath = "/staffer/organization-managers";
const orgInvitesPath = `${orgPath}/invitations`;

const managerApi = makeApi([
  {
    method: "get",
    path: `${locationManagersPath}/`,
    parameters: [{ name: "location", type: "Query", schema: z.number() }],
    response: z.array(locationManagerSchema),
    alias: "locationManagers",
  },
  {
    method: "patch",
    path: `${locationManagersPath}/:managerId/`,
    parameters: [
      {
        name: "LocationManager",
        type: "Body",
        schema: locationManagerSchema.partial(),
      },
    ],
    response: locationManagerSchema,
    alias: "updateLocationManager",
  },
  {
    method: "delete",
    path: `${locationManagersPath}/:managerId/`,
    response: locationManagerSchema,
    alias: "deleteLocationManager",
  },
  {
    method: "get",
    path: `${invitationsPath}/`,
    parameters: [{ name: "location", type: "Query", schema: z.number() }],
    response: z.array(locationInviteSchema).transform((invitations) =>
      invitations.map((invitation) => ({
        ...invitation,
        status: invitation.expiration_date && isPast(invitation.expiration_date) ? "EXPIRED" : invitation.status,
      })),
    ),
    alias: "locationInvites",
  },
  {
    method: "post",
    path: `${invitationsPath}/`,
    parameters: [
      {
        name: "Invitation",
        type: "Body",
        schema: locationInvitePayload.transform(({ phone_number, ...rest }) => ({
          ...rest,
          ...((phone_number?.length ?? -1) > 0 ? { phone_number: transformPhoneOutput(phone_number) } : {}),
        })),
      },
    ],
    response: locationInviteSchema,
    alias: "inviteLocationManager",
  },
  {
    method: "delete",
    path: `${invitationsPath}/:invitationId/`,
    response: locationInviteSchema,
    alias: "deleteLocationInvite",
  },
  {
    method: "get",
    path: `${invitationsPath}/:inviteToken/`,
    response: locationInviteSchema,
    alias: "getLocationInvite",
  },
  {
    method: "post",
    path: `${invitationsPath}/:invitationId/resend/`,
    response: locationInviteSchema,
    alias: "resendLocationInvite",
  },
  {
    method: "post",
    path: `${invitationsPath}/accept/:inviteToken/`,
    response: locationInviteSchema.extend({ status: InviteStatus }),
    parameters: [
      {
        type: "Body",
        name: "locationInviteAcceptance",
        schema: locationInvitePayload.pick({ phone_number: true }),
      },
    ],
    alias: "acceptLocationInvite",
  },
  {
    method: "get",
    path: `${orgPath}/organization-managers/`,
    response: z.array(orgManagerSchema),
    alias: "orgManagers",
  },
  {
    method: "get",
    path: `${orgPath}${locationManagersPath}/`,
    response: z.array(locationManagerSchema).transform((managers) => {
      const managerLocationMapping: Record<number, OrgLocationManager> = {};
      for (const manager of managers) {
        const entry = managerLocationMapping[manager.user.id];
        if (entry) entry.locations.push(manager.location);
        else managerLocationMapping[manager.user.id] = { ...manager, locations: [manager.location] };
      }
      return Object.values(managerLocationMapping);
    }),
    alias: "orgLocationManagers",
  },
  {
    method: "get",
    path: `${orgInvitesPath}/`,
    response: z.array(orgInviteSchema).transform(
      (invites) =>
        invites
          .filter((invite) => invite.status !== "ACCEPTED") // TODO: Remove this filter when the backend is fixed.
          .map((invite) => ({
            ...invite,
            status: invite.expiration_date && isPast(invite.expiration_date) ? "EXPIRED" : invite.status,
          })) as Array<OrgInvite>,
    ),
    alias: "orgInvites",
  },
  {
    method: "post",
    path: `${orgInvitesPath}/`,
    response: orgInviteSchema,
    parameters: [
      {
        type: "Body",
        name: "orgInvite",
        schema: locationInvitePayload.omit({ location_id: true }).transform(({ phone_number, ...rest }) => ({
          ...rest,
          ...((phone_number?.length ?? -1) > 0 ? { phone_number: transformPhoneOutput(phone_number) } : {}),
        })),
      },
    ],
    alias: "inviteOrgManager",
  },
  {
    method: "post",
    path: `${orgInvitesPath}/:invitationId/resend/`,
    response: orgInviteSchema,
    alias: "resendOrgInvite",
  },
  {
    method: "delete",
    path: `${orgInvitesPath}/:invitationId/`,
    response: undefined,
    alias: "deleteOrgInvite",
  },
  {
    method: "get",
    path: "/staffer/organizations/invitations/:inviteToken/",
    response: orgInviteSchema,
    alias: "getOrgInvite",
  },
  {
    method: "post",
    path: "/staffer/organizations/invitations/:inviteToken/acceptance/",
    response: locationInviteSchema
      .extend({
        id: z.string(),
        status: z.literal("ACCEPTED"),
      })
      .partial(),
    parameters: [
      {
        type: "Body",
        name: "orgInviteAcceptance",
        schema: locationInvitePayload.pick({ phone_number: true }),
      },
    ],
    alias: "acceptOrgInvite",
  },
  {
    method: "delete",
    path: `${orgManagersPath}/:managerId`,
    response: undefined,
    alias: "revokeOrgManager",
  },
]);

export const { client: managerClient, hooks: managerHooks } = makeClientWithHooks("manager", managerApi);
