import React from "react";

import type { IconProps } from "../types";

export const BartenderFull = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8526 1.97191C16.2949 1.86098 15.7168 1.91791 15.1915 2.13551C14.6661 2.35312 14.2171 2.72161 13.9012 3.1944C13.7676 3.3943 13.6604 3.60905 13.5811 3.83333H16.2917C16.6601 3.83333 16.9958 4.04449 17.1554 4.37652C17.315 4.70855 17.2701 5.10266 17.04 5.39033L9.58366 14.7108V21.0833H13.4167C13.9459 21.0833 14.375 21.5124 14.375 22.0417C14.375 22.5709 13.9459 23 13.4167 23H3.83333C3.30406 23 2.875 22.5709 2.875 22.0417C2.875 21.5124 3.30406 21.0833 3.83333 21.0833H7.66699V14.7116L3.28996 9.24027C3.28053 9.22901 3.27135 9.21754 3.26243 9.20586L0.21001 5.39033C-0.0201206 5.10266 -0.0649882 4.70855 0.0945934 4.37652C0.254175 4.04449 0.589954 3.83333 0.958342 3.83333H11.5968C11.7204 3.22766 11.9607 2.64858 12.3075 2.12956C12.8341 1.34158 13.5824 0.727416 14.458 0.364746C15.3335 0.00207618 16.297 -0.0928149 17.2265 0.0920727C18.156 0.27696 19.0098 0.733322 19.6799 1.40345C20.35 2.07357 20.8064 2.92737 20.9913 3.85686C21.1762 4.78635 21.0813 5.7498 20.7186 6.62536C20.3559 7.50092 19.7418 8.24928 18.9538 8.77579C18.1658 9.30231 17.2394 9.58333 16.2917 9.58333C15.7624 9.58333 15.3333 9.15427 15.3333 8.625C15.3333 8.09573 15.7624 7.66667 16.2917 7.66667C16.8603 7.66667 17.4161 7.49805 17.8889 7.18214C18.3617 6.86623 18.7302 6.41722 18.9478 5.89188C19.1654 5.36655 19.2224 4.78848 19.1114 4.23078C19.0005 3.67309 18.7267 3.16081 18.3246 2.75874C17.9225 2.35666 17.4102 2.08284 16.8526 1.97191ZM12.4545 5.74999H2.95227L4.48562 7.66667H12.7644L14.2977 5.74999H12.4622L12.4583 5.75L12.4545 5.74999ZM6.01895 9.58334L8.62501 12.8409L11.2311 9.58334H6.01895Z"
        fill={color}
      />
    </svg>
  );
});

BartenderFull.displayName = "BartenderFull";
