import { getCookie } from "./cookies";

interface GASessionInfo {
  ga_session_id: string;
  ga_session_number: string;
}

export const getGAClientId = (): string => {
  return getCookie("_ga")?.substring(6) ?? "";
};

export const getGASessionInfo = (measurementId: string | undefined): GASessionInfo => {
  if (!measurementId) {
    return { ga_session_id: "", ga_session_number: "" };
  }
  const mid = measurementId.split("-").pop(); // Remove the leading "G-" if it is included
  const match = document.cookie.match(new RegExp(`_ga_${mid}=GS\\d\.\\d\.(.+?)(?:;|$)`));
  const parts = match?.[1].split(".");
  return {
    ga_session_id: parts?.shift() ?? "",
    ga_session_number: parts?.shift() ?? "",
  };
};
