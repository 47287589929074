import React from "react";

import type { IconProps } from "../types";

/**
 * Also used for Barback position
 */
export const BartenderFixed = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6875 0.75C3.16973 0.75 2.75 1.16973 2.75 1.6875V22.3125C2.75 22.8303 3.16973 23.25 3.6875 23.25H18.8125C19.3303 23.25 19.75 22.8303 19.75 22.3125V1.6875C19.75 1.16973 19.3303 0.75 18.8125 0.75H3.6875ZM4.625 21.375V2.625H17.875V21.375H4.625ZM22.5 4.4375C22.5 3.91973 22.0803 3.5 21.5625 3.5C21.0447 3.5 20.625 3.91973 20.625 4.4375V19.5625C20.625 20.0803 21.0447 20.5 21.5625 20.5C22.0803 20.5 22.5 20.0803 22.5 19.5625V4.4375ZM7.8125 6.25C7.29473 6.25 6.875 6.66973 6.875 7.1875V9.9375C6.875 11.0978 7.33594 12.2106 8.15641 13.0311C8.75221 13.6269 9.50215 14.0331 10.3125 14.2109V16.5625H8.5C7.98223 16.5625 7.5625 16.9822 7.5625 17.5C7.5625 18.0178 7.98223 18.4375 8.5 18.4375H14C14.5178 18.4375 14.9375 18.0178 14.9375 17.5C14.9375 16.9822 14.5178 16.5625 14 16.5625H12.1875V14.2109C12.9978 14.0331 13.7478 13.6269 14.3436 13.0311C15.1641 12.2106 15.625 11.0978 15.625 9.9375V7.1875C15.625 6.66973 15.2053 6.25 14.6875 6.25H7.8125ZM13.0178 11.7053C12.5489 12.1741 11.913 12.4375 11.25 12.4375C10.587 12.4375 9.95107 12.1741 9.48223 11.7053C9.01339 11.2364 8.75 10.6005 8.75 9.9375V8.125H13.75V9.9375C13.75 10.6005 13.4866 11.2364 13.0178 11.7053Z"
          fill={color}
        />
      </svg>
    );
  },
);

BartenderFixed.displayName = "BartenderFixed";
