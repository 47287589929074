import React from "react";

import type { IconProps } from "./types";

export const Globe = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10492 3.24144C5.14084 4.01323 2.89017 6.55262 2.54594 9.66659H5.87524C6.06208 7.36393 6.83275 5.1536 8.10492 3.24144ZM10.0002 1.33325C4.93755 1.33325 0.833496 5.43731 0.833496 10.4999C0.833496 15.5625 4.93755 19.6666 10.0002 19.6666C15.0628 19.6666 19.1668 15.5625 19.1668 10.4999C19.1668 5.43731 15.0628 1.33325 10.0002 1.33325ZM11.8954 3.24144C13.1676 5.1536 13.9382 7.36393 14.1251 9.66659H17.4544C17.1102 6.55262 14.8595 4.01323 11.8954 3.24144ZM12.4522 9.66659C12.2428 7.40561 11.3916 5.25153 10.0002 3.45892C8.60876 5.25153 7.75749 7.40561 7.54816 9.66659H12.4522ZM7.54816 11.3333H12.4522C12.2428 13.5942 11.3916 15.7483 10.0002 17.5409C8.60876 15.7483 7.75749 13.5942 7.54816 11.3333ZM5.87524 11.3333H2.54594C2.89017 14.4472 5.14084 16.9866 8.10492 17.7584C6.83275 15.8462 6.06208 13.6359 5.87524 11.3333ZM11.8954 17.7584C13.1676 15.8462 13.9382 13.6359 14.1251 11.3333H17.4544C17.1102 14.4472 14.8595 16.9866 11.8954 17.7584Z"
        fill={color}
      />
    </svg>
  );
});
Globe.displayName = "Globe";
