import Axios, { type AxiosRequestConfig } from "axios";

import { getParams } from "api/queries/utils";
import type { CreateResource } from "api/resources/resource";
import { env } from "config/env";
import { getTokenSilently } from "services/auth";

import type { PageResult } from "./PageResult";

/**
 * @deprecated in favor of Zodios
 * @see src/api/client.ts
 */
export abstract class RestClient<T extends Record<string, any>, Paginated extends boolean = true> {
  static apiUrl = env.REACT_APP_SG_API_URL;
  noAuth = false;

  constructor(options: { noAuth?: boolean } = {}) {
    this.noAuth = options.noAuth ?? false;
  }
  abstract _getBaseUrl(): string;
  async getTokenSilently() {
    try {
      return await getTokenSilently();
    } catch (error) {
      return null;
    }
  }

  getUrl = (id?: string | number, suffix?: string) =>
    `${RestClient.apiUrl}${this._getBaseUrl()}/${id ? `${id}/` : ""}${suffix ? `${suffix}/` : ""}`;

  getRequestOpts = async (rawParams?: URLSearchParams | Record<string, any>) => {
    const params = rawParams instanceof URLSearchParams ? rawParams : getParams(rawParams);
    if (this.noAuth) {
      return { params };
    }
    const token = await this.getTokenSilently();
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    };
    return config;
  };

  async create(model: CreateResource<T>): Promise<T> {
    const response = await Axios.post(this.getUrl(), model, await this.getRequestOpts());

    return response.data;
  }

  async destroy(id: string | number): Promise<T> {
    const response = await Axios.delete(this.getUrl(id), await this.getRequestOpts());

    return response.data;
  }

  async list(params?: URLSearchParams | Record<string, any>): Promise<Paginated extends true ? PageResult<T> : T[]> {
    const response = await Axios.get(this.getUrl(), await this.getRequestOpts(params));

    return response.data;
  }
  /**
   * @deprecated
   * Lists all resources for this client assuming that the API does not support pagination.
   *
   * All APIs should be updated to support pagination, which is why this method is marked as depreacted.
   *
   * @param params
   */
  async listAll(params?: URLSearchParams | Record<string, any>): Promise<T[]> {
    const response = await Axios.get(this.getUrl(), await this.getRequestOpts(params));

    return response.data;
  }

  async partialUpdate(model: Partial<T>): Promise<any> {
    const { id, ...payload } = model;
    const response = await Axios.patch(this.getUrl(id), payload, await this.getRequestOpts());

    return response.data;
  }

  async retrieve(id: string | number): Promise<T> {
    const response = await Axios.get(this.getUrl(id), await this.getRequestOpts());

    return response.data;
  }

  async update(model: T): Promise<T> {
    const response = await Axios.put(this.getUrl(model.id), model, await this.getRequestOpts());

    return response.data;
  }
}
