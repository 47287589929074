import React from "react";

interface UseIsFocusedArguments<E extends HTMLElement> {
  onBlur?: (e: React.FocusEvent<E>) => void;
  onFocus?: (e: React.FocusEvent<E>) => void;
}

export function useIsFocused<E extends HTMLElement>({ onFocus, onBlur }: UseIsFocusedArguments<E>) {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = (event: React.FocusEvent<E>) => {
    onFocus?.(event);
    setIsFocused(true);
  };
  const handleBlur = (event: React.FocusEvent<E>) => {
    onBlur?.(event);
    setIsFocused(false);
  };

  return {
    onFocus: handleFocus,
    onBlur: handleBlur,
    isFocused,
  };
}
