import { IMaskMixin, type ReactMaskOpts, type ReactMaskProps } from "react-imask";

import { Input, type InputProps } from "../Input";
import { Textarea, type TextareaProps } from "../Textarea";

export type MaskedInputProps = React.ComponentProps<typeof MaskedInput>;
export type MaskedTextareaProps = React.ComponentProps<typeof MaskedTextarea>;

export const MaskedInput = IMaskMixin<HTMLInputElement, ReactMaskOpts & ReactMaskProps<HTMLInputElement> & InputProps>(
  ({ inputRef, ...props }) => <Input {...props} ref={inputRef} />,
);

export const MaskedTextarea = IMaskMixin<
  HTMLTextAreaElement,
  ReactMaskOpts & ReactMaskProps<HTMLTextAreaElement> & TextareaProps
>(({ inputRef, ...props }) => <Textarea {...props} ref={inputRef} />);
