import { type Theme, alpha, css, styled } from "@mui/material";

import { colorSelector } from "providers/Theme/selectors";

import { loaderInterval } from "./constants";

const animationTimingFunction = "cubic-bezier(.58,-0.23,.3,1.3)";
const animationStretch = 2;
const animationDistance = "60px";
const getBg = (opacity: number) => (theme: { theme: Theme }) => alpha(colorSelector("text.gray")(theme), opacity);

const animateIn = css`
  animation-name: blurIn;
  animation-duration: ${loaderInterval}ms;
  animation-timing-function: ${animationTimingFunction};
  animation-fill-mode: both;
  animation-iteration-count: infinite;
`;

export const Emoji = styled("div")`
  ${animateIn}
  font-size: 3.5em;
  margin-bottom: 0.2em;
  animation-delay: 0.1s;
`;
export const Text = styled("div")`
  ${animateIn}
  color: ${colorSelector("text.gray")};
  font-size: 20px;
  margin-right: -14px; // Optically center for ellipsis
  &:after {
    content: "";
    display: inline-block;
    background: transparent;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-left: 18px;
    box-shadow:
      -3px 0 0 ${getBg(0.6)},
      -9px 0 0 ${getBg(0.6)},
      -15px 0 0 ${getBg(0.6)};
    animation: pulseEllipsis ${loaderInterval / 2}ms infinite both ease-in-out;
  }
`;

export const Loader = styled("div")`
  @keyframes blurIn {
    0% {
      transform: translate3d(0, -${animationDistance}, 0) scale(0.9, ${animationStretch});
      filter: blur(3px);
      opacity: 0;
    }
    7% {
      transform: translate3d(0, -${animationDistance}, 0) scale(0.9, ${animationStretch});
      filter: blur(3px);
      opacity: 0;
    }
    25% {
      transform: translate3d(0, 0, 0) scale(1);
      filter: blur(0);
      opacity: 1;
    }
    75% {
      transform: translate3d(0, 0, 0) scale(1);
      filter: blur(0);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, ${animationDistance}, 0) scale(0.9, ${animationStretch});
      filter: blur(3px);
      opacity: 0;
    }
  }
  @keyframes pulseEllipsis {
    0% {
      box-shadow:
        -3px 0 0 ${getBg(0.6)},
        -9px 0 0 ${getBg(0.6)},
        -15px 0 0 ${getBg(0.6)};
    }
    20% {
      box-shadow:
        -3px 0 ${getBg(0.6)},
        -9px 0 0 ${getBg(0.6)},
        -15px 0 0 ${getBg(1)};
    }
    40% {
      box-shadow:
        -3px 0 0 ${getBg(0.6)},
        -9px 0 0 ${getBg(1)},
        -15px 0 0 ${getBg(0.6)};
    }
    60% {
      box-shadow:
        -3px 0 0 ${getBg(1)},
        -9px 0 0 ${getBg(0.6)},
        -15px 0 0 ${getBg(0.6)};
    }
  }
`;
