import { useQueryClient } from "@tanstack/react-query";
import { atom, getDefaultStore, useAtom, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import isEqual from "lodash/isEqual";
import React from "react";

import { usersHooks } from "api/client";
import { LOCATIONS_LIST } from "api/queries/locations";

import { locationOrgHooks, useContextLocations } from "api/clients/locationsAndOrganizations";
import { type LicenseName, hasLicense } from "api/resources/User";
import { useAuth } from "providers/Auth";
import { identify } from "providers/LaunchDarkly/client";

const defaultStore = getDefaultStore();

export const drawerAtom = atom(false);

export const LOCATION_ID_STORE_KEY = "currentLocation";
export const activeLocationIdAtom = atomWithStorage<number | undefined>(
  LOCATION_ID_STORE_KEY,
  undefined,
  {
    getItem: (key) => {
      if (location.pathname.startsWith("/locations/")) {
        const locationId = Number(location.pathname.split("/")[2]);
        if (!Number.isNaN(locationId)) {
          localStorage.setItem(key, JSON.stringify(locationId));
          return locationId;
        }
      }
      return localStorage.getItem(key) ? Number(localStorage.getItem(key)) : undefined;
    },
    setItem: (key, newValue) => localStorage.setItem(key, JSON.stringify(newValue)),
    removeItem: (key) => localStorage.removeItem(key),
  },
  { getOnInit: true },
);
export const getActiveLocationId = () => {
  const storageVal = defaultStore.get(activeLocationIdAtom);
  if (Number.isNaN(storageVal)) return undefined;
  return storageVal;
};

export const activeLicenseAtom = atom<LicenseName>("marketplace");
export const getActiveLicense = () => defaultStore.get(activeLicenseAtom);
export const marketplaceLicenseAtom = atom((get) => get(activeLicenseAtom) === "marketplace");
export const hireLicenseAtom = atom((get) => get(activeLicenseAtom) === "hire");

export const useAuthenticatedStateSetup = () => {
  const setLicense = useSetAtom(activeLicenseAtom);

  const queryClient = useQueryClient();
  const [activeLocationId, setActiveLocationId] = useAtom(activeLocationIdAtom);

  const { data: locations, isLoading: locationsLoading } = useContextLocations({ includeAll: true });
  const activeLocation = locationOrgHooks.useLocation(
    { params: { locationId: activeLocationId ?? -1 } },
    { enabled: !!activeLocationId },
  ).data;

  const { user, analyticsUser } = useAuth();

  React.useEffect(() => {
    if (!activeLocationId) return;
    // reset possibly location-dependent queries
    queryClient.resetQueries({
      predicate: ({ queryKey }) => {
        const queryKeyFirst = queryKey[0];
        const isManagerQuery = isEqual(queryKeyFirst, usersHooks.getKeyByAlias("authManager")[0]);
        const isAuthUserQuery = isEqual(queryKeyFirst, usersHooks.getKeyByAlias("authUser")[0]);
        const isLocationQuery =
          queryKeyFirst === LOCATIONS_LIST ||
          isEqual(queryKeyFirst, locationOrgHooks.getKeyByAlias("location")[0]) ||
          isEqual(queryKeyFirst, locationOrgHooks.getKeyByAlias("activeLocations")[0]) ||
          isEqual(queryKeyFirst, locationOrgHooks.getKeyByAlias("approvedLocations")[0]);
        const isOrgsQuery = isEqual(queryKeyFirst, locationOrgHooks.getKeyByAlias("organizations")[0]);
        return !isManagerQuery && !isAuthUserQuery && !isLocationQuery && !isOrgsQuery;
      },
    });
  }, [activeLocationId, queryClient]);

  React.useEffect(() => {
    if (!locations || !user) return;
    if (locations.length === 0) {
      setActiveLocationId(undefined);
      return;
    }
    const newActiveLocationId =
      activeLocationId && locations.some((location) => location.id === activeLocationId) ?
        activeLocationId
      : locations[0].id;
    setActiveLocationId(newActiveLocationId);
    if (activeLocation && analyticsUser) {
      identify({ user: analyticsUser, location: activeLocation });
    }

    const locationNotHasAnyLicense = !user.licenses.some((license) => license.location_id === newActiveLocationId);
    setLicense(
      hasLicense(user, newActiveLocationId, "marketplace") || locationNotHasAnyLicense ? "marketplace" : "hire",
    );
  }, [activeLocation, activeLocationId, locations, setActiveLocationId, setLicense, user, analyticsUser]);

  return locationsLoading || (!activeLocation && locations?.length !== 0);
};
