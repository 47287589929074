import { addHours, differenceInCalendarDays, differenceInHours, isBefore, subMinutes } from "date-fns";
import { fromZonedTime, getTimezoneOffset, toZonedTime } from "date-fns-tz";

import { formatRelative } from "./formatRelative";

const aDate = new Date();

/**
 * The timezone of the browser.
 * This only works in the browser, not server-side.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
 * @example "Europe/Berlin"
 */
export const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ensureTimezone = (timezone: string | undefined | null): string => timezone || browserTimezone;
/**
 * Returns the timezone name in the short format, e.g. "CET".
 * @param timezone IANA timezone name, e.g. "Europe/Berlin"
 * @returns the timezone name in the short format, e.g. "CET"
 */
export const shortenTimezone = (timezone: string | undefined | null): string => {
  const tz = ensureTimezone(timezone);
  return (
    new Intl.DateTimeFormat(undefined, { timeZone: tz, timeZoneName: "short" })
      .formatToParts(aDate)
      .find((part) => part.type === "timeZoneName")?.value ?? tz
  );
};

/**
 * Converts a date to UTC, assuming the date is in the given timezone.
 * @param date in any timezone
 * @param timezone the date is supposed to be in
 * @returns the date in UTC
 */
export const convertToUtc = (date: Date | string, timezone: string): Date =>
  fromZonedTime(date, ensureTimezone(timezone));

/**
 * Applies the UTC offset so the date displays as if it's UTC but with the current timezone intact.
 * @param date in any timezone
 * @param timezone the date is supposed to be in
 * @returns the date as if it were UTC
 */
export const jumpToUtc = (date: Date | string, timezone: string): Date => {
  const utcOffset = getTimezoneOffset(ensureTimezone(timezone)) / (60 * 60 * 1000);
  // We need to apply the opposite of the offset to get the date as if it were UTC, hence the * -1.
  return addHours(new Date(date), utcOffset * -1);
};

/**
 * Converts a date in UTC to the given timezone.
 * @param date in UTC
 * @param timezone the date should be converted to
 * @returns the date in the given timezone
 */
export const convertToTimezone = (date: Date | string, timezone: string): Date =>
  toZonedTime(date, ensureTimezone(timezone));

/**
 * Converts a date from one timezone to another.
 * @param date a date in {@linkcode fromTimezone}
 * @param fromTimezone the timezone of the date
 * @param toTimezone the timezone the date should be converted to
 * @returns the date in the {@linkcode toTimezone}
 */
export const convertBetweenTimezones = (date: Date | string, fromTimezone: string, toTimezone: string): Date =>
  convertToTimezone(convertToUtc(date, fromTimezone), toTimezone);

/**
 * Returns the current date in the given timezone.
 * @param timezone the timezone the date should be in. Defaults to browser timezone.
 * @returns the current date in the given timezone
 * @example nowInTimezone("Europe/Berlin")
 */
export const nowInTimezone = (timezone: string | null | undefined): Date =>
  convertBetweenTimezones(new Date(), browserTimezone, timezone ?? browserTimezone);

/**
 * Is the given date in the past?
 * @param date the date to check
 * @param timezone the timezone the date is in. Defaults to browser timezone.
 * @returns true if the date is in the past
 */
export const isPast = (date: Date | number, timezone: string | null | undefined): boolean =>
  isBefore(date, nowInTimezone(timezone));

/**
 * Formats the distance from the given date to now.
 * @param date the date to format the distance to
 * @param timezone the timezone the date is in. Defaults to browser timezone.
 * @param options
 * @returns the formatted distance assuming the date is in the given timezone
 */
export const formatRelativeToNow = (date: Date | number, timezone: string | null | undefined): string => {
  return formatRelative(date, nowInTimezone(timezone), { numeric: "always" });
};

export const isWithinHours = (date: Date | number, hours: number, timezone: string | null | undefined) =>
  differenceInHours(date, nowInTimezone(timezone)) <= hours;

export const isWithinDays = (date: Date | number, days: number, timezone: string | null | undefined) =>
  differenceInCalendarDays(date, nowInTimezone(timezone)) <= days;

export const isWithin15Minutes = (date: Date, timezone: string | null) => isPast(subMinutes(date, 15), timezone);

export const getDateTimezoneOffsetInDays = (date: Date, timezone: string) =>
  differenceInCalendarDays(jumpToUtc(date, timezone), date);
