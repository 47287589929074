import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "tailwind-config";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center rounded text-normal font-semibold text-brand-purple transition-all duration-300 focus-visible:outline-none focus-visible:outline-dashed focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:pointer-events-none disabled:text-gray",
  {
    variants: {
      variant: {
        text: "hover:bg-lightBlue",
        raised: "bg-white shadow-down transition-shadow hover:shadow-transparent",
        outlined:
          "ring-2 ring-inset ring-brand-purple hover:bg-lightBlue disabled:bg-lightBlue disabled:ring-transparent",
        contained: "bg-brand-purple text-white hover:bg-brand-purpleHover disabled:bg-lightBlue",
        destructive: "bg-error text-white hover:bg-errorHover",
        disabled: "bg-lightBlue text-gray",
      },
      size: {
        small: "px-2 py-1",
        large: "px-4 py-2",
        mainAction: "px-6 py-3 text-extraLarge",
      },
      fullWidth: {
        false: "",
        true: "w-full",
      },
      icon: {
        false: "",
        true: "p-1",
      },
      loading: {
        true: "cursor-wait bg-lightBlue text-transparent before:absolute before:bottom-[calc(50%-12.5px)] before:w-5 before:text-mediumGray before:content-[''] before:loading-spinner disabled:text-transparent",
        false: "",
      },
    },
    compoundVariants: [
      {
        size: "small",
        loading: true,
        className: "before:bottom-[calc(50%-10px)] before:w-4",
      },
    ],
    defaultVariants: {
      variant: "text",
      size: "large",
      fullWidth: false,
      icon: false,
      loading: false,
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  /**
   * Sets the button a width of 100%
   * @deprecated use `className="w-full"` instead
   */
  fullWidth?: boolean;
  asChild?: boolean;
  "data-id"?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, fullWidth = false, loading = false, icon, asChild = false, disabled = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, fullWidth, icon, loading, className }))}
        ref={ref}
        disabled={disabled || !!loading}
        type="button"
        {...props}
      >
        {props.children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
