import cx, { type ClassValue } from "clsx";
import { createTailwindMerge, getDefaultConfig } from "tailwind-merge";

const twMerge = createTailwindMerge(() => {
  const defaultConfig = getDefaultConfig();
  return {
    ...defaultConfig,
    theme: {
      ...defaultConfig.theme,
      colors: [
        "[inherit]",
        "brand-purple",
        "brand-orange",
        "brand-purpleHover",
        "black",
        "lightGray",
        "mediumGray",
        "gray",
        "warning",
        "error",
        "info",
        "success",
        "white",
        "lightBlue",
        "transparent",
        "tags-blue",
        "tags-red",
        "tags-green",
        "tags-yellow",
        "tags-purple",
        "accent-lightBlue",
        "accent-lightRed",
        "accent-lightGreen",
        "accent-lightYellow",
        "accent-lightPurple",
      ],
    },
    classGroups: {
      ...defaultConfig.classGroups,
      fontSize: [
        { text: ["extraSmall", "small", "normal", "base", "large", "extraLarge", "huge", "enormous", "screenTitle"] },
      ],
      fontWeight: [{ font: ["regular", "semibold"] }],
      shadow: [{ shadow: ["up", "down", "back", "top"] }],
    },
  };
});

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(cx(inputs));
}

/**
 * Breakpoints in pixels
 */
export const breakpoints = {
  sm: 600,
  md: 1024,
  lg: 1100,
  xl: 1536,
};
