import { Button } from "@gigpro/ui/Button";
import { usersHooks } from "api/client";
import { Link } from "react-router-dom";
import { ROUTES, buildRoute } from "routing/Routes";
import { startAccountLinking } from "services/auth";

const connectionLabelLookup: Record<string, string> = {
  sms: "SMS",
  email: "email",
  "google-oauth2": "Google",
  apple: "Apple",
  facebook: "Facebook",
};

export function AccountLinkingContainer() {
  const linkableAccounts = usersHooks.useLinkableAccounts();
  const previousLogin =
    linkableAccounts.data?.[0].connection ? connectionLabelLookup[linkableAccounts.data?.[0].connection] : "SMS";

  return (
    <div className="flex items-center justify-center text-center">
      <div className="flex w-3/4 flex-col items-center md:w-1/2">
        <h1 className="mb-8 text-enormous font-semibold">Looks like you previously logged in with {previousLogin}</h1>
        <Button variant="contained" className="mb-4 w-full" onClick={startAccountLinking}>
          Log in with {previousLogin} to link your accounts
        </Button>
        <Button variant="outlined" className="mb-1 w-full" asChild>
          <Link to={buildRoute(ROUTES.SIGN_UP, {}, { skipAccountLinking: true })}>Don't link accounts</Link>
        </Button>
        <p className="text-large">By not linking accounts you'll be creating a new account</p>
      </div>
    </div>
  );
}
