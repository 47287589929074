import React from "react";

import type { IconProps } from "./types";

export const InfoOutline = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00008 7.99996C2.00008 4.68625 4.68637 1.99996 8.00008 1.99996C11.3138 1.99996 14.0001 4.68625 14.0001 7.99996C14.0001 11.3137 11.3138 14 8.00008 14C4.68637 14 2.00008 11.3137 2.00008 7.99996ZM8.00008 0.666626C3.94999 0.666626 0.666748 3.94987 0.666748 7.99996C0.666748 12.05 3.94999 15.3333 8.00008 15.3333C12.0502 15.3333 15.3334 12.05 15.3334 7.99996C15.3334 3.94987 12.0502 0.666626 8.00008 0.666626ZM7.33341 5.33329C7.33341 4.9651 7.63189 4.66663 8.00008 4.66663H8.00675C8.37494 4.66663 8.67341 4.9651 8.67341 5.33329C8.67341 5.70148 8.37494 5.99996 8.00675 5.99996H8.00008C7.63189 5.99996 7.33341 5.70148 7.33341 5.33329ZM8.00008 7.33329C8.36827 7.33329 8.66675 7.63177 8.66675 7.99996V10.6666C8.66675 11.0348 8.36827 11.3333 8.00008 11.3333C7.63189 11.3333 7.33341 11.0348 7.33341 10.6666V7.99996C7.33341 7.63177 7.63189 7.33329 8.00008 7.33329Z"
        fill={color}
      />
    </svg>
  );
});
InfoOutline.displayName = "InfoOutline";
