import { requiredStringSchema } from "@gigpro/ui/Form";
import { z } from "zod";

import { makeApiResource } from "./resource";
import { requirementSchema } from "./skills";

export const gigTemplateSchema = makeApiResource({
  name: z.string(),
  skill_id: z.number(),
  skill_name: z.string().optional(),
  location_id: z.number(),
  no_experience_required: z.boolean(),
  hourly_rate: z.coerce.number(),
  position_description: requiredStringSchema,
  private_notes: z.string().nullish(),
  requirements: z.array(requirementSchema.omit({ skill: true })).optional(),
  auto_select_enabled: z.boolean().nullish(),
});
export type GigTemplate = z.infer<typeof gigTemplateSchema>;

export const gigTemplatePayloadSchema = gigTemplateSchema
  .omit({ skill_name: true, hourly_rate: true })
  .extend({ requirement_ids: z.array(z.number()).optional(), hourly_rate: z.coerce.number().min(15).max(50) });

export type GigTemplatePayload = z.infer<typeof gigTemplatePayloadSchema>;
