import React from "react";

import type { IconProps } from "./types";

export const Profile = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M12.6562 14C12.6562 14.1875 12.7188 14.3438 12.8438 14.4688C12.9896 14.5938 13.1458 14.6562 13.3125 14.6562C13.5 14.6562 13.6562 14.5938 13.7812 14.4688C13.9271 14.3438 14 14.1875 14 14V12.6562C14 12.0521 13.8438 11.5 13.5312 11C13.2396 10.4792 12.8333 10.0729 12.3125 9.78125C11.8125 9.48958 11.2604 9.34375 10.6562 9.34375H5.34375C4.42708 9.34375 3.63542 9.66667 2.96875 10.3125C2.32292 10.9583 2 11.7396 2 12.6562V14C2 14.125 2.03125 14.2396 2.09375 14.3438C2.15625 14.4479 2.23958 14.5208 2.34375 14.5625C2.44792 14.625 2.55208 14.6562 2.65625 14.6562C2.84375 14.6562 3 14.5938 3.125 14.4688C3.27083 14.3438 3.34375 14.1875 3.34375 14V12.6562C3.34375 12.1146 3.53125 11.6458 3.90625 11.25C4.30208 10.8542 4.78125 10.6562 5.34375 10.6562H10.6562C11.0312 10.6562 11.3646 10.75 11.6562 10.9375C11.9688 11.1042 12.2083 11.3438 12.375 11.6562C12.5625 11.9688 12.6562 12.3021 12.6562 12.6562V14ZM10.3438 2.3125C11.0104 2.95833 11.3438 3.73958 11.3438 4.65625C11.3438 5.57292 11.0104 6.36458 10.3438 7.03125C9.69792 7.67708 8.91667 8 8 8C7.08333 8 6.29167 7.67708 5.625 7.03125C4.97917 6.36458 4.65625 5.57292 4.65625 4.65625C4.65625 3.73958 4.97917 2.95833 5.625 2.3125C6.29167 1.66667 7.08333 1.34375 8 1.34375C8.91667 1.34375 9.69792 1.66667 10.3438 2.3125ZM9.40625 6.09375C9.80208 5.69792 10 5.22917 10 4.6875C10 4.125 9.80208 3.64583 9.40625 3.25C9.01042 2.85417 8.54167 2.65625 8 2.65625C7.45833 2.65625 6.98958 2.85417 6.59375 3.25C6.19792 3.64583 6 4.11458 6 4.65625C6 5.03125 6.08333 5.375 6.25 5.6875C6.4375 5.97917 6.6875 6.21875 7 6.40625C7.3125 6.57292 7.64583 6.65625 8 6.65625C8.54167 6.65625 9.01042 6.46875 9.40625 6.09375Z"
        fill={color}
      />
    </svg>
  );
});
Profile.displayName = "Profile";
