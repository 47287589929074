import React from "react";

import type { IconProps } from "./types";

export const HeartOutline = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M13.7812 5.29688C14.6562 4.42188 15.7031 3.98438 16.9219 3.98438C18.1719 3.98438 19.2344 4.4375 20.1094 5.34375C21.0156 6.21875 21.4688 7.28125 21.4688 8.53125C21.4688 9.75 21.0312 10.7969 20.1562 11.6719C19.0938 12.7344 17.5625 14.2656 15.5625 16.2656C13.5938 18.2344 12.4062 19.4219 12 19.8281C11.5938 19.4219 10.3906 18.2344 8.39062 16.2656C6.42188 14.2656 4.92188 12.7344 3.89062 11.6719C2.98438 10.7969 2.53125 9.75 2.53125 8.53125C2.53125 7.28125 2.96875 6.21875 3.84375 5.34375C4.75 4.4375 5.8125 3.98438 7.03125 3.98438C8.28125 3.98438 9.34375 4.42188 10.2188 5.29688L11.2969 6.375C11.4844 6.5625 11.7188 6.65625 12 6.65625C12.2812 6.65625 12.5156 6.5625 12.7031 6.375L13.7812 5.29688ZM21.5625 13.0781C22.8125 11.8281 23.4375 10.3125 23.4375 8.53125C23.4375 6.71875 22.7969 5.1875 21.5156 3.9375C20.2656 2.65625 18.7344 2.01562 16.9219 2.01562C15.1406 2.01562 13.625 2.64062 12.375 3.89062L12 4.26562L11.625 3.89062C10.375 2.64062 8.84375 2.01562 7.03125 2.01562C5.25 2.01562 3.71875 2.65625 2.4375 3.9375C1.1875 5.1875 0.5625 6.71875 0.5625 8.53125C0.5625 10.3125 1.1875 11.8281 2.4375 13.0781C3.59375 14.2344 5.25 15.8906 7.40625 18.0469C9.5625 20.2031 10.8594 21.5 11.2969 21.9375C11.4844 22.125 11.7188 22.2188 12 22.2188C12.2812 22.2188 12.5156 22.125 12.7031 21.9375C13.1719 21.4688 14.4688 20.1719 16.5938 18.0469C18.75 15.8906 20.4062 14.2344 21.5625 13.0781Z"
        fill={color}
      />
    </svg>
  );
});
HeartOutline.displayName = "HeartOutline";
