import React from "react";

import type { IconProps } from "./types";

export const AssignmentTurnedIn = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M6.32031 14.1797L3 10.8203L4.17188 9.64844L6.32031 11.7969L11.8281 6.32812L13 7.5L6.32031 14.1797ZM8 2.5C8.23438 2.5 8.42969 2.57812 8.58594 2.73438C8.74219 2.89062 8.82031 3.08594 8.82031 3.32031C8.82031 3.55469 8.74219 3.76302 8.58594 3.94531C8.42969 4.10156 8.23438 4.17969 8 4.17969C7.76562 4.17969 7.57031 4.10156 7.41406 3.94531C7.25781 3.76302 7.17969 3.55469 7.17969 3.32031C7.17969 3.08594 7.25781 2.89062 7.41406 2.73438C7.57031 2.57812 7.76562 2.5 8 2.5ZM13.8203 2.5H10.3438C10.1615 2.00521 9.84896 1.60156 9.40625 1.28906C8.98958 0.976562 8.52083 0.820312 8 0.820312C7.47917 0.820312 6.9974 0.976562 6.55469 1.28906C6.13802 1.60156 5.83854 2.00521 5.65625 2.5H2.17969C1.71094 2.5 1.30729 2.66927 0.96875 3.00781C0.65625 3.32031 0.5 3.71094 0.5 4.17969V15.8203C0.5 16.2891 0.65625 16.6927 0.96875 17.0312C1.30729 17.3438 1.71094 17.5 2.17969 17.5H13.8203C14.2891 17.5 14.6797 17.3438 14.9922 17.0312C15.3307 16.6927 15.5 16.2891 15.5 15.8203V4.17969C15.5 3.71094 15.3307 3.32031 14.9922 3.00781C14.6797 2.66927 14.2891 2.5 13.8203 2.5Z"
          fill={color}
        />
      </svg>
    );
  },
);
AssignmentTurnedIn.displayName = "AssignmentTurnedIn";
