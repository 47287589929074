import React from "react";

import type { IconProps } from "../types";

export const Housekeeping = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C8.93913 0 7.92172 0.421427 7.17157 1.17157C6.42143 1.92172 6 2.93913 6 4V9V12.723L0.142507 22.4855C-0.0428512 22.7944 -0.0477054 23.1792 0.1298 23.4927C0.307306 23.8062 0.639728 24 1 24H8H12H19C19.3603 24 19.6927 23.8062 19.8702 23.4927C20.0477 23.1792 20.0429 22.7944 19.8575 22.4855L14 12.723V9V4C14 2.93913 13.5786 1.92172 12.8284 1.17157C12.0783 0.421427 11.0609 0 10 0ZM12 8V4C12 3.46957 11.7893 2.96086 11.4142 2.58579C11.0391 2.21071 10.5304 2 10 2C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V8H12ZM8 10H12V12H8V10ZM13 22V19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19V22H9V19C9 18.4477 8.55229 18 8 18C7.44772 18 7 18.4477 7 19V22H2.76619L7.56619 14H12.4338L17.2338 22H13Z"
        fill={color}
      />
    </svg>
  );
});

Housekeeping.displayName = "Housekeeping";
