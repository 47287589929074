"use client";

import React from "react";
import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "..";
import { Textarea as DumbArea, type TextareaProps } from "../../Textarea";
import type { FormFieldProps } from "../types";

export const Textarea = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  optional,
  placeholder,
  className,
  inputClassName,
  name,
  control,
  shouldUnregister,
  disabled,
  inputRef,
  ...inputProps
}: FormFieldProps<TFieldValues, TName, never, HTMLTextAreaElement> & TextareaProps & { inputClassName?: string }) => {
  const formFieldProps: UseControllerProps<TFieldValues, TName> = { name, control, shouldUnregister, disabled };

  return (
    <FormField
      {...formFieldProps}
      render={function RenderComponent({ field, fieldState }) {
        // biome-ignore lint/correctness/useHookAtTopLevel: it's not conditional so it's fine
        React.useImperativeHandle(field.ref, () => inputRef?.current, [inputRef?.current]);
        return (
          <FormItem className={className}>
            <FormLabel optional={optional}>{label}</FormLabel>
            <FormControl>
              <DumbArea
                className={inputClassName}
                placeholder={placeholder}
                {...inputProps}
                {...field}
                ref={inputRef}
                value={field.value ?? ""}
                invalid={!!fieldState.error}
                onBlur={(e) => {
                  field.onBlur();
                  inputProps.onBlur?.(e);
                }}
              />
            </FormControl>
            {inputProps.maxLength && (
              <FormDescription>
                {field.value?.length ?? 0}/{inputProps.maxLength} characters
              </FormDescription>
            )}
            {!!description && <FormDescription>{description}</FormDescription>}
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

Textarea.displayName = "Textarea";
