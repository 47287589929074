import React from "react";

import type { IconProps } from "./types";

export const Verified = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <title>Verified</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.18 22.7815C13.51 22.9205 13.87 22.9901 14.22 22.9901L14.23 23C15.14 23 16.03 22.5331 16.52 21.7087L17.31 20.3875C17.4 20.2286 17.56 20.1193 17.73 20.0796L19.24 19.7418C20.54 19.4538 21.42 18.2419 21.3 16.9208L21.16 15.391C21.14 15.2122 21.2 15.0235 21.32 14.8943L22.34 13.7421C23.22 12.7487 23.22 11.2488 22.34 10.2554L21.32 9.10316C21.2 8.96409 21.14 8.78529 21.16 8.60648L21.3 7.07674C21.43 5.75559 20.54 4.54372 19.24 4.25565L17.73 3.91791C17.56 3.87818 17.4 3.76891 17.31 3.60998L16.52 2.28883C15.84 1.15642 14.41 0.689554 13.18 1.21602L11.76 1.82196C11.6 1.8915 11.41 1.8915 11.24 1.82196L9.82 1.21602C8.59 0.689554 7.16 1.15642 6.48 2.2789L5.69 3.60004C5.6 3.75898 5.45 3.86825 5.27 3.90798L3.76 4.24572C2.46 4.53378 1.58 5.74566 1.7 7.06681L1.84 8.59655C1.86 8.77535 1.8 8.95415 1.68 9.09322L0.66 10.2455C-0.22 11.2388 -0.22 12.7388 0.66 13.7321L1.68 14.8844C1.8 15.0235 1.86 15.2023 1.84 15.3811L1.7 16.9108C1.57 18.232 2.46 19.4438 3.76 19.7319L5.27 20.0696C5.44 20.1094 5.6 20.2186 5.69 20.3776L6.48 21.6987C7.16 22.8411 8.59 23.3079 9.82 22.7815L11.24 22.1755C11.4 22.106 11.59 22.106 11.76 22.1755L13.18 22.7815ZM16.2071 10.2046C16.5976 9.81405 16.5976 9.18088 16.2071 8.79036C15.8166 8.39984 15.1834 8.39984 14.7929 8.79036L10 13.5833L8.20711 11.7904C7.81658 11.3998 7.18342 11.3998 6.79289 11.7904C6.40237 12.1809 6.40237 12.814 6.79289 13.2046L9.29289 15.7046C9.68342 16.0951 10.3166 16.0951 10.7071 15.7046L16.2071 10.2046Z"
        fill={color}
      />
    </svg>
  );
});
Verified.displayName = "Verified";
