import { phoneNumberSchema, requiredStringSchema } from "@gigpro/ui/Form";
import { z } from "zod";

import { makeApiResource } from "./resource";

export const managerOnDutySchema = makeApiResource({
  fullname: requiredStringSchema.max(80, "The manager's full name can be maximum of 80 characters long"),
  phone_number: phoneNumberSchema,
  location_id: z.number().optional(),
});
export type ManagerOnDuty = z.infer<typeof managerOnDutySchema>;
