import { Button } from "@gigpro/ui/Button";
import React from "react";
import { useRouteError } from "react-router-dom";

import { ErrorStateIcon } from "components/CommonUIStates/icons";
import { env } from "config/env";
import { track } from "utils/analytics";
import { captureException } from "utils/newrelic";

export const ErrorBoundary = () => {
  const error = useRouteError();
  const errorString =
    error instanceof Error ? (error.stack ?? JSON.stringify(error, null, 2)) : JSON.stringify(error, null, 2);
  React.useEffect(() => {
    if (error instanceof Error) captureException(error);
    if (errorString) track("Error", { error: errorString });
  }, [error, errorString]);
  return (
    <div className="flex h-full flex-col items-center justify-center bg-white p-10 text-center">
      <ErrorStateIcon className="mb-8 h-24 w-auto text-black sm:h-32 md:mb-8" />
      <h1 className="mb-2 text-enormous text-gray">Ooops, something went wrong.</h1>
      <p className="mb-4 text-large text-gray">
        Try reloading the page or contacting our{" "}
        <a className="text-black" href={env.REACT_APP_BUSINESS_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
          support team
        </a>
      </p>
      {errorString && (
        <div className="flex max-w-[100vw] flex-col gap-1 px-2">
          <p className="text-left text-normal">Send this error message along to help us fix the problem:</p>
          <pre className="overflow-auto border border-black bg-lightBlue p-1 text-left text-small">{errorString}</pre>
          <Button variant="contained" size="small" onClick={() => navigator.clipboard.writeText(errorString)}>
            Copy error to clipboard
          </Button>
        </div>
      )}
    </div>
  );
};
