import React from "react";

import type { IconProps } from "../types";

export const PrepCook = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 0.292893C18.3166 -0.0976311 17.6834 -0.0976311 17.2929 0.292893L5.29289 12.2929C5.28924 12.2965 5.28563 12.3002 5.28205 12.3039L0.793044 16.7929C-0.149765 17.7357 -0.149765 19.2643 0.793044 20.2071C1.73585 21.1499 3.26445 21.1499 4.20726 20.2071L8.00008 16.4143L10.7929 19.2071C10.9863 19.4005 11.2504 19.5062 11.5238 19.4997C11.7972 19.4932 12.056 19.375 12.2399 19.1727C13.638 17.6348 15.5687 15.3786 17.5561 13.0561L17.5604 13.051C17.959 12.5852 18.3599 12.1167 18.7593 11.6508C22.1348 7.71271 21.0368 2.62259 18.7071 0.292893ZM6.58587 15.0001L6.00008 14.4143L2.20726 18.2071C2.0455 18.3689 2.0455 18.6311 2.20726 18.7929C2.36902 18.9547 2.63128 18.9547 2.79305 18.7929L6.58587 15.0001ZM11.461 17.0468L7.41421 13L17.9187 2.4955C19.1421 4.41428 19.5121 7.6993 17.2407 10.3492C16.8362 10.8212 16.4314 11.2942 16.0299 11.7634L16.029 11.7645L16.0288 11.7646C14.3596 13.7153 12.749 15.5975 11.461 17.0468Z"
        fill={color}
      />
    </svg>
  );
});

PrepCook.displayName = "PrepCook";
