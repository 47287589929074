import React from "react";

export const ArrowRight = React.forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.91074 4.41076C7.23618 4.08533 7.76382 4.08533 8.08925 4.41076L13.0893 9.41076C13.4147 9.7362 13.4147 10.2638 13.0893 10.5893L8.08925 15.5893C7.76382 15.9147 7.23618 15.9147 6.91074 15.5893C6.5853 15.2638 6.5853 14.7362 6.91074 14.4108L11.3215 10L6.91074 5.58928C6.5853 5.26384 6.5853 4.7362 6.91074 4.41076Z"
          fill={color}
        />
      </svg>
    );
  },
);
ArrowRight.displayName = "ArrowRight";
