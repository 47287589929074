"use client";

import type React from "react";
import type { FieldPath, FieldPathValue, FieldValues } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "..";
import { SelectContent, SelectRoot, SelectTrigger, SelectValue } from "../../Select";
import type { FormFieldProps, Transform } from "../types";

export const Select = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  optional,
  placeholder,
  className,
  children,
  transform,
  ...formFieldProps
}: React.PropsWithChildren<
  FormFieldProps<TFieldValues, TName, Transform<string, FieldPathValue<TFieldValues, TName>>>
>) => {
  return (
    <FormField
      {...formFieldProps}
      render={({ field, fieldState }) => (
        <FormItem className={className}>
          <FormLabel optional={optional}>{label}</FormLabel>
          <SelectRoot
            value={transform && field.value != null ? transform.input(field.value) : field.value}
            onValueChange={transform ? (value) => field.onChange(transform.output(value)) : field.onChange}
          >
            <FormControl>
              <SelectTrigger invalid={!!fieldState.error} data-testid={`dropdown_${formFieldProps.name}`}>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>{children}</SelectContent>
          </SelectRoot>
          {!!description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export { SelectItem, SelectSeparator } from "../../Select";

Select.displayName = "Select";
