import { isTwoDayTimeRange as isTwoDayTimeRangeOG } from "@gigpro/utils/datetime";
import { format, parse } from "date-fns";

type CommonDateFormats =
  | "MMM yyyy"
  | "MM/dd/yyyy"
  | "MMM d"
  | "MM/dd"
  | "MM/dd/yyyy- h:mm aaa"
  | "MMM dd, yyyy"
  | "MMMM dd, yyyy"
  | "yyyy-MM-dd"
  | "EEE, MMM d h:mm aaa"
  | "EEE, MMM d"
  | "EEEE, MMMM d"
  | "EEE, MMMM d, yyyy"
  | "EEEE"
  | "EEEEE"
  | "EEEEEE"
  | "MM/dd h:mm aaa"
  | TimeFormats;

type TimeFormats = "h:mm aaa";

const timeFormatLookup = {
  backend: "HH:mm:ss",
  default: "h:mm aaa",
} as const;

/**
 * @deprecated import from @gigpro/utils/datetime instead
 */
export function formatDate({
  date,
  format: formatPattern,
}: {
  date?: Date | string | number;
  format: CommonDateFormats;
}): string {
  if (!date) return "";
  const dateDate = typeof date === "string" ? new Date(date) : date;
  return format(dateDate, formatPattern);
}

type TimeFormatOptions = keyof typeof timeFormatLookup;

/**
 * @deprecated import from @gigpro/utils/datetime instead
 */
export const parseTime = (
  time: string,
  formatString: TimeFormatOptions = "default",
  referenceDate: Date = new Date(),
): Date => parse(time, timeFormatLookup[formatString], referenceDate);

/**
 * @deprecated import from @gigpro/utils/datetime instead
 */
export const formatTime = (time: Date, formatString: TimeFormatOptions = "default") =>
  format(time, timeFormatLookup[formatString]);

/**
 * @deprecated import from @gigpro/utils/datetime instead
 */
export const reformatTime = (time: string, format: { in: TimeFormatOptions; out: TimeFormatOptions }) =>
  formatTime(parseTime(time, format.in), format.out);

/**
 * @deprecated import from @gigpro/utils/datetime instead
 */
export const isTwoDayTimeRange = isTwoDayTimeRangeOG;
