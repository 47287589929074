import React from "react";

import type { IconProps } from "./types";

export const Close = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5892 5.58934C15.9147 5.2639 15.9147 4.73626 15.5892 4.41083C15.2638 4.08539 14.7361 4.08539 14.4107 4.41083L9.99996 8.82157L5.58921 4.41083C5.26378 4.08539 4.73614 4.08539 4.4107 4.41083C4.08527 4.73626 4.08527 5.2639 4.4107 5.58934L8.82145 10.0001L4.4107 14.4108C4.08527 14.7363 4.08527 15.2639 4.4107 15.5893C4.73614 15.9148 5.26378 15.9148 5.58921 15.5893L9.99996 11.1786L14.4107 15.5893C14.7361 15.9148 15.2638 15.9148 15.5892 15.5893C15.9147 15.2639 15.9147 14.7363 15.5892 14.4108L11.1785 10.0001L15.5892 5.58934Z"
        fill={color}
      />
    </svg>
  );
});
Close.displayName = "Close";
