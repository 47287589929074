import React from "react";

import type { IconProps } from "./types";

export const Partners = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        id="Icon"
        d="M2.98828 6.01172H15.0117C15.2227 6.01172 15.3984 6.08203 15.5391 6.22266C15.6797 6.36328 15.75 6.53906 15.75 6.75V14.2383C15.75 14.3086 15.7383 14.3789 15.7148 14.4492C15.6914 14.5195 15.668 14.5898 15.6445 14.6602C15.6211 14.707 15.5859 14.7539 15.5391 14.8008C15.4922 14.8242 15.4336 14.8594 15.3633 14.9062C15.3164 14.9297 15.2578 14.9531 15.1875 14.9766C15.1406 15 15.082 15.0117 15.0117 15.0117H2.98828C2.77734 15.0117 2.60156 14.9414 2.46094 14.8008C2.32031 14.6367 2.25 14.4492 2.25 14.2383V6.75C2.25 6.53906 2.32031 6.36328 2.46094 6.22266C2.60156 6.08203 2.77734 6.01172 2.98828 6.01172ZM2.98828 4.5C2.37891 4.5 1.85156 4.72266 1.40625 5.16797C0.960938 5.58984 0.738281 6.11719 0.738281 6.75V14.2383C0.738281 14.8711 0.960938 15.3984 1.40625 15.8203C1.85156 16.2656 2.37891 16.4883 2.98828 16.4883H15.0117C15.6211 16.4883 16.1484 16.2656 16.5938 15.8203C17.0391 15.3984 17.2617 14.8711 17.2617 14.2383V6.75C17.2617 6.11719 17.0391 5.58984 16.5938 5.16797C16.1484 4.72266 15.6211 4.5 15.0117 4.5H2.98828ZM11.25 15.75C11.25 15.8906 11.2852 16.0195 11.3555 16.1367C11.4258 16.2539 11.5195 16.3359 11.6367 16.3828C11.7539 16.4531 11.8711 16.4883 11.9883 16.4883C12.1992 16.4883 12.375 16.418 12.5156 16.2773C12.6797 16.1367 12.7617 15.9609 12.7617 15.75V3.76172C12.7617 3.12891 12.5391 2.60156 12.0938 2.17969C11.6484 1.73438 11.1211 1.51172 10.5117 1.51172H7.48828C6.87891 1.51172 6.35156 1.73438 5.90625 2.17969C5.46094 2.60156 5.23828 3.12891 5.23828 3.76172V15.75C5.23828 15.8906 5.27344 16.0195 5.34375 16.1367C5.41406 16.2539 5.50781 16.3359 5.625 16.3828C5.74219 16.4531 5.87109 16.4883 6.01172 16.4883C6.19922 16.4883 6.36328 16.418 6.50391 16.2773C6.66797 16.1367 6.75 15.9609 6.75 15.75V3.76172C6.75 3.62109 6.78516 3.49219 6.85547 3.375C6.92578 3.25781 7.01953 3.16406 7.13672 3.09375C7.25391 3.02344 7.37109 2.98828 7.48828 2.98828H10.5117C10.6992 2.98828 10.8633 3.07031 11.0039 3.23438C11.168 3.375 11.25 3.55078 11.25 3.76172V15.75Z"
        fill={color}
      />
    </svg>
  );
});
Partners.displayName = "Partners";
