export const FETCH_TOAST_MESSAGES = "FETCH_TOAST_MESSAGES";
export const MUTATE_TOAST_MESSAGES = "MUTATE_TOAST_MESSAGES";

export const MUTATE_REFERRAL_CODE = "MUTATE_REFERRAL_CODE";
export const MUTATE_REFERRAL_PROMOTION = "MUTATE_REFERRAL_PROMOTION";
export const MUTATE_ASSIGN_REFERRAL_LOCATION = "MUTATE_ASSIGN_REFERRAL";
export const MUTATE_INVITATION = "MUTATE_INVITATION";
export const MUTATE_LOCATION_MANAGER = "MUTATE_LOCATION_MANAGER";
export const MUTATE_MANAGER_NOTIFICATION_SETTINGS = "MUTATE_MANAGER_NOTIFICATION_SETTINGS";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_MARKETS = "FETCH_MARKETS";
export const FETCH_PROMOTIONS = "FETCH_PROMOTIONS";

export const FETCH_INVITATIONS = "FETCH_INVITATIONS";
export const FETCH_LOCATION_MANAGERS = "FETCH_LOCATION_MANAGERS";
export const FETCH_NOTIFICATION_SETTINGS = "FETCH_NOTIFICATION_SETTINGS";
export const FETCH_SMS_MARKETING_CAMPAIGNS = "FETCH_SMS_MARKETING_CAMPAIGNS";

type IToastType = "ERROR" | "INFO" | "SUCCESS" | "WARNING";

export type IToastMessage = {
  id?: string | number;
  type?: IToastType;
  timeout?: number;
  text: string;
};
