import { makeApi } from "@zodios/core";
import { z } from "zod";

import { managerSkillSchema } from "api/resources/ManagerSkills";

export const managersSkillsApi = makeApi([
  {
    method: "get",
    path: "/manager-skills/",
    response: z.array(managerSkillSchema),
    alias: "listManagerSkill",
  },
]);
