import { RecurrenceWithRepeat } from "@gigpro/utils/datetime";
import { z } from "zod";

import { billingSourceSchema } from "api/clients/locationsAndOrganizations";
import { formatTime, reformatTime } from "utils/Dates";

import { GigStatus, GigType } from "./StafferGig";
import { makeApiResource } from "./resource";
import { requirementSchema } from "./skills";

const gigBaseSchema = z.object({
  status: z.nativeEnum(GigStatus),
  start_time: z.coerce.date(),
  end_time: z.coerce.date(),
  allowed_confirmed_pros: z.number(),

  skill_id: z.number(),

  location_address_id: z.number().nullish(),
  location_id: z.number(),
  address_line_1: z.string(),
  city: z.string(),
  zip_code: z.string(),
  state_code: z.string(),
  use_location_address: z.boolean(),

  contact_name: z.string(),
  contact_phone: z.string().transform((phone) => (phone.startsWith("+1") ? phone : `+1${phone}`)),

  description: z.string(),
  no_experience_required: z.boolean(),
  special_inst: z.string().optional().nullable(),
  wage_amount: z.coerce.number(),

  type: z.nativeEnum(GigType),

  auto_select_enabled: z.boolean().nullish(),
});

export const gigPostPayloadSchema = gigBaseSchema.extend({
  timezone: z.string(),
  selected_requirement_ids: z.array(z.number()).optional(),
  recurrence: RecurrenceWithRepeat.optional(),
});
export type GigPostPayload = z.infer<typeof gigPostPayloadSchema>;

export const gigSchema = makeApiResource(
  gigBaseSchema.extend({
    created_ts: z.coerce.date(),
    location: makeApiResource({
      image_public_id: z.string().nullish(),
      market_id: z.number(),
      name: z.string(),
      url: z.string().nullish(),
      stripe_payment_method_type: z.enum(["card", "us_bank_account"]).nullish(),
      stripe_payment_elements_enabled: z.boolean().default(false).nullish(),
      gigpro_fee_percentage: z.coerce.number().nullish(),
      timezone: z.string().nullish(),
      billing_source: billingSourceSchema,
      organization_id: z.string(),
    }),
    skill: z.string(),
    skill_id: z.number(),
    requirements: z.array(requirementSchema).optional(),
    background_check_required: z.boolean().default(false),
    background_checks_fee_percentage: z.number().nullish(),
    chat_token: z.string().nullish(),
    bundle_id: z.number().nullish(),
    number_of_gigs_in_bundle: z.number().nullish(),
    auto_select_enabled: z.boolean().nullish(),
    is_recurring: z.boolean().nullish(),
    boosted_gig_bonus: z.coerce.number().nullish(),
  }).shape,
);

export type Gig = z.infer<typeof gigSchema>;

export const offerableGigSchema = gigSchema
  .pick({ id: true, start_time: true, end_time: true, wage_amount: true, description: true, skill_id: true })
  .extend({
    skill: z.string(),
    location: gigSchema.shape.location.pick({ id: true, image_public_id: true, name: true, timezone: true }),
    has_applied: z.boolean(),
    is_selected: z.boolean().nullable(),
    is_confirmed: z.boolean().nullable(),
    is_cancelled: z.boolean().nullable(),
    is_filled: z.boolean().optional().nullable(),
  });
export type OfferableGig = z.infer<typeof offerableGigSchema>;

export const shiftTemplateSchema = makeApiResource({
  location_id: z.number(),
  start_time: z.string().transform((time) => reformatTime(time, { in: "backend", out: "default" })),
  end_time: z.string().transform((time) => reformatTime(time, { in: "backend", out: "default" })),
});
export type ShiftTemplate = z.infer<typeof shiftTemplateSchema>;

export const shiftTemplatePayloadSchema = z.object({
  location_id: z.number(),
  start_time: z.date().transform((date) => formatTime(date, "backend")),
  end_time: z.date().transform((date) => formatTime(date, "backend")),
});
