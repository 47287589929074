import { makeApi } from "@zodios/core";

import { proFavoritePayloadSchema } from "api/resources/proFavorite";

const proFavoritePath = "/favorite-pros/";

export const proFavoriteApi = makeApi([
  {
    method: "post",
    path: proFavoritePath,
    response: proFavoritePayloadSchema,
    parameters: [{ name: "Pro favorite", type: "Body", schema: proFavoritePayloadSchema.omit({ id: true }) }],
    alias: "addFavoritePro",
  },
  {
    method: "delete",
    path: `${proFavoritePath}:id/`,
    response: proFavoritePayloadSchema,
    alias: "deleteFavoritePro",
  },
  {
    method: "delete",
    path: "/gigster-profiles/:id/unfavorite-all/",
    response: proFavoritePayloadSchema,
    alias: "deleteProFavoriteFromAllLocation",
  },
]);
