import { RestClient } from "api/django/RestClient";

/**
 * @deprecated in favor of Zodios
 * @see src/api/client.ts
 */
export abstract class ApiClient<T extends Record<string, any>, Paginated extends boolean = true> extends RestClient<
  T,
  Paginated
> {
  readonly apiBase = "api";
  abstract readonly endpoint: () => string;

  _getBaseUrl(): string {
    return `${this.apiBase}${this.endpoint()}`.replaceAll("//", "/");
  }
}
