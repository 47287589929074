import { makeApi } from "@zodios/core";
import { compareAsc } from "date-fns";
import { z } from "zod";

import { GIGPRO_LOCATION_HEADER } from "api/constants";
import { makeClientWithHooks } from "api/helpers";
import { shiftTemplatePayloadSchema, shiftTemplateSchema } from "api/resources/gigs";
import { locationAddressSchema } from "api/resources/locationAddress";
import { managerOnDutySchema } from "api/resources/managerOnDuty";
import { gigTemplatePayloadSchema, gigTemplateSchema } from "api/resources/templates";
import { formatTime, parseTime } from "utils/Dates";

const managersOnDutyPath = "/managers-on-duty/";
const managersOnDutyApi = makeApi([
  {
    method: "get",
    path: managersOnDutyPath,
    response: z
      .array(managerOnDutySchema)
      .transform((managersOnDuty) => managersOnDuty.toSorted((a, b) => a.fullname.localeCompare(b.fullname))),
    parameters: [
      {
        type: "Header",
        name: GIGPRO_LOCATION_HEADER,
        schema: z.number(),
      },
    ],
    alias: "listManagerOnDuty",
  },
  {
    method: "post",
    path: managersOnDutyPath,
    response: managerOnDutySchema,
    parameters: [
      {
        name: "Manager on Duty Create Payload",
        type: "Body",
        schema: managerOnDutySchema.omit({ id: true }),
      },
    ],
    alias: "createManagerOnDuty",
  },
  {
    method: "delete",
    path: `${managersOnDutyPath}:id/`,
    response: z.object({}),
    alias: "deleteManagerOnDuty",
  },
]);

const shiftTemplateApi = makeApi([
  {
    method: "get",
    path: "/shifts/",
    response: z
      .array(
        shiftTemplateSchema.omit({ start_time: true, end_time: true }).extend({
          start_time: z.string().transform((time) => parseTime(time, "backend")),
          end_time: z.string().transform((time) => parseTime(time, "backend")),
        }),
      )
      .transform((shifts) =>
        shifts
          .toSorted((a, b) => compareAsc(a.start_time, b.start_time))
          .map(({ start_time, end_time, ...shift }) => ({
            ...shift,
            start_time: formatTime(start_time),
            end_time: formatTime(end_time),
          })),
      ),
    parameters: [{ name: "location_id", type: "Query", schema: z.number() }],
    alias: "shiftTemplates",
  },
  {
    method: "post",
    path: "/shifts/",
    response: shiftTemplateSchema,
    parameters: [{ name: "Shift Template Create Payload", type: "Body", schema: shiftTemplatePayloadSchema }],
    alias: "createShiftTemplate",
  },
  {
    method: "put",
    path: "/shifts/:id/",
    response: shiftTemplateSchema,
    parameters: [{ name: "Shift Template Update Payload", type: "Body", schema: shiftTemplatePayloadSchema }],
    alias: "updateShiftTemplate",
  },
]);

const proRequestTemplatesPath = "/staffer/gigs/templates/";
const proRequestTemplatesApi = makeApi([
  {
    method: "get",
    path: proRequestTemplatesPath,
    response: z.array(gigTemplateSchema),
    parameters: [{ name: "location_id", type: "Query", schema: z.number() }],
    alias: "gigTemplates",
  },
  {
    method: "get",
    path: `${proRequestTemplatesPath}:id/`,
    response: gigTemplateSchema,
    alias: "gigTemplate",
  },
  {
    method: "post",
    path: proRequestTemplatesPath,
    response: gigTemplatePayloadSchema,
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: gigTemplatePayloadSchema.omit({ id: true }).transform(({ requirements, requirement_ids, ...data }) => ({
          ...data,
          requirement_ids: requirement_ids ?? requirements?.map((r) => r.id) ?? [],
        })),
      },
    ],
    alias: "createGigTemplate",
  },
  {
    method: "patch",
    path: `${proRequestTemplatesPath}:id/`,
    response: gigTemplatePayloadSchema,
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: gigTemplatePayloadSchema.partial().transform(({ requirements, requirement_ids, ...data }) => ({
          ...data,
          requirement_ids: requirement_ids ?? requirements?.map((r) => r.id),
        })),
      },
    ],
    alias: "updateGigTemplate",
  },
]);

const locationAddressPath = "/locations/:locationId/addresses/" as const;
const locationAddressApi = makeApi([
  {
    method: "get",
    path: locationAddressPath,
    response: z.array(locationAddressSchema),
    description: "List all location addresses",
    alias: "locationAddresses",
  },
  {
    method: "get",
    path: `${locationAddressPath}:id/`,
    response: locationAddressSchema,
    alias: "locationAddress",
    description: "Get a location address",
  },
  {
    method: "post",
    path: locationAddressPath,
    response: locationAddressSchema,
    parameters: [
      {
        name: "Location Address Create Payload",
        type: "Body",
        schema: locationAddressSchema.omit({ id: true, type: true }),
      },
    ],
    alias: "createLocationAddress",
  },
  {
    method: "delete",
    path: `${locationAddressPath}:id/`,
    response: locationAddressSchema,
    alias: "deleteLocationAddress",
  },
]);

export const { client, hooks } = makeClientWithHooks("gigPostTemplates", [
  ...managersOnDutyApi,
  ...shiftTemplateApi,
  ...proRequestTemplatesApi,
  ...locationAddressApi,
] as const);
