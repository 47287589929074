import React from "react";

import type { IconProps } from "./types";

export const Check = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path d="M1 5.33301L5.66667 9.33301L13 1.33301" stroke={color} strokeWidth="2" />
    </svg>
  );
});
Check.displayName = "Check";
