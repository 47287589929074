import { z } from "zod";

import { shortProProfileSchema } from "./pros";
import { makeApiResource } from "./resource";

export const proGroupSummarySchema = makeApiResource({
  id: z.number(),
  name: z.string(),
  members_count: z.number(),
  member_previews: z.array(shortProProfileSchema),
  is_autogenerated: z.boolean(),
  type: z.string(),
  skill_id: z.number().nullish(),
});
export type ProGroupSummary = z.infer<typeof proGroupSummarySchema>;

export const proGroupPayloadSchema = z.object({
  name: z.string().nonempty("Group name is required"),
  pros: z.array(z.number()).optional(),
  location_id: z.number(),
  id: z.number().optional(),
});
export type ProGroupPayload = z.infer<typeof proGroupPayloadSchema>;
