import { makeApi } from "@zodios/core";
import { z } from "zod";
import { networkInviteSchema } from "./networkInvite";
import { networkInvitePayloadSchema } from "./networkPayload";
import { networkProSchema } from "./networkPro";

const orgNetworksPath = "/networks/:orgId/";

export const orgNetworkApi = makeApi([
  {
    method: "post",
    path: `${orgNetworksPath}invitations/`,
    alias: "inviteToNetwork",
    parameters: [
      {
        name: "orgId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "Network invitation",
        type: "Body",
        schema: networkInvitePayloadSchema,
      },
    ],
    response: networkInvitePayloadSchema,
  },
  {
    method: "get",
    path: `${orgNetworksPath}invitations/`,
    alias: "getNetworkInvitations",
    response: z.array(networkInviteSchema),
  },
  {
    method: "post",
    path: `${orgNetworksPath}invitations/:id/resend/`,
    alias: "resendNetworkInvite",
    response: z.array(networkInviteSchema),
  },
  {
    method: "delete",
    path: `${orgNetworksPath}invitations/:id/`,
    alias: "deleteNetworkInvite",
    response: {},
  },
  {
    method: "get",
    path: orgNetworksPath,
    response: z.array(networkProSchema),
    alias: "getNetworkPros",
  },
]);
