import React from "react";

import type { IconProps } from "./types";

export const Delete = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74404 2.744C7.90032 2.58772 8.11228 2.49992 8.33329 2.49992H11.6666C11.8876 2.49992 12.0996 2.58772 12.2559 2.744C12.4122 2.90028 12.5 3.11224 12.5 3.33325V4.16659H7.49996V3.33325C7.49996 3.11224 7.58776 2.90028 7.74404 2.744ZM5.83329 4.16659V3.33325C5.83329 2.67021 6.09669 2.03433 6.56553 1.56548C7.03437 1.09664 7.67025 0.833252 8.33329 0.833252H11.6666C12.3297 0.833252 12.9656 1.09664 13.4344 1.56548C13.9032 2.03433 14.1666 2.67021 14.1666 3.33325V4.16659H15.8327L15.8333 4.16658L15.8339 4.16659H17.5C17.9602 4.16659 18.3333 4.53968 18.3333 4.99992C18.3333 5.46016 17.9602 5.83325 17.5 5.83325H16.6666V16.6666C16.6666 17.3296 16.4032 17.9655 15.9344 18.4344C15.4656 18.9032 14.8297 19.1666 14.1666 19.1666H5.83329C5.17025 19.1666 4.53437 18.9032 4.06553 18.4344C3.59668 17.9655 3.33329 17.3296 3.33329 16.6666V5.83325H2.49996C2.03972 5.83325 1.66663 5.46016 1.66663 4.99992C1.66663 4.53968 2.03972 4.16659 2.49996 4.16659H4.16599L4.16663 4.16658L4.16726 4.16659H5.83329ZM6.66663 5.83325H13.3333L15 5.83325V16.6666C15 16.8876 14.9122 17.0996 14.7559 17.2558C14.5996 17.4121 14.3876 17.4999 14.1666 17.4999H5.83329C5.61228 17.4999 5.40032 17.4121 5.24404 17.2558C5.08776 17.0996 4.99996 16.8876 4.99996 16.6666V5.83325L6.66663 5.83325ZM8.33329 8.33325C8.79353 8.33325 9.16663 8.70635 9.16663 9.16658V14.1666C9.16663 14.6268 8.79353 14.9999 8.33329 14.9999C7.87306 14.9999 7.49996 14.6268 7.49996 14.1666V9.16658C7.49996 8.70635 7.87306 8.33325 8.33329 8.33325ZM12.5 14.1666V9.16658C12.5 8.70635 12.1269 8.33325 11.6666 8.33325C11.2064 8.33325 10.8333 8.70635 10.8333 9.16658V14.1666C10.8333 14.6268 11.2064 14.9999 11.6666 14.9999C12.1269 14.9999 12.5 14.6268 12.5 14.1666Z"
        fill={color}
      />
    </svg>
  );
});
Delete.displayName = "Delete";
