import React from "react";

import type { IconComponent } from "./types";

export const Pin: IconComponent = React.forwardRef(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22874 2.89543C5.22894 1.89524 6.58549 1.33333 7.99998 1.33333C9.41447 1.33333 10.771 1.89524 11.7712 2.89543C12.7714 3.89562 13.3333 5.25218 13.3333 6.66667C13.3333 8.73204 11.9895 10.7362 10.516 12.2915C9.79291 13.0548 9.06753 13.6796 8.5222 14.1139C8.31924 14.2755 8.14196 14.4101 7.99998 14.5151C7.858 14.4101 7.68072 14.2755 7.47776 14.1139C6.93243 13.6796 6.20705 13.0548 5.48395 12.2915C4.01047 10.7362 2.66665 8.73204 2.66665 6.66667C2.66665 5.25218 3.22855 3.89562 4.22874 2.89543ZM7.62982 15.8878C7.63001 15.8879 7.63018 15.888 7.99998 15.3333L7.63018 15.888C7.85411 16.0373 8.14585 16.0373 8.36978 15.888L7.99998 15.3333C8.36978 15.888 8.36995 15.8879 8.37014 15.8878L8.37064 15.8875L8.37205 15.8865L8.37649 15.8835L8.39177 15.8732C8.40475 15.8644 8.42326 15.8517 8.44694 15.8353C8.49429 15.8025 8.56234 15.7548 8.64812 15.6928C8.81962 15.5689 9.06241 15.3881 9.35276 15.1569C9.93242 14.6954 10.7071 14.0286 11.4839 13.2085C13.0105 11.5972 14.6666 9.26796 14.6666 6.66667C14.6666 4.89856 13.9643 3.20286 12.714 1.95262C11.4638 0.702379 9.76809 0 7.99998 0C6.23187 0 4.53618 0.702379 3.28593 1.95262C2.03569 3.20286 1.33331 4.89856 1.33331 6.66667C1.33331 9.26796 2.98949 11.5972 4.51601 13.2085C5.29291 14.0286 6.06753 14.6954 6.6472 15.1569C6.93755 15.3881 7.18034 15.5689 7.35184 15.6928C7.43762 15.7548 7.50567 15.8025 7.55302 15.8353C7.5767 15.8517 7.59521 15.8644 7.60819 15.8732L7.62347 15.8835L7.62791 15.8865L7.62932 15.8875L7.62982 15.8878ZM6.66665 6.66667C6.66665 5.93029 7.2636 5.33333 7.99998 5.33333C8.73636 5.33333 9.33331 5.93029 9.33331 6.66667C9.33331 7.40305 8.73636 8 7.99998 8C7.2636 8 6.66665 7.40305 6.66665 6.66667ZM7.99998 4C6.52722 4 5.33331 5.19391 5.33331 6.66667C5.33331 8.13943 6.52722 9.33333 7.99998 9.33333C9.47274 9.33333 10.6666 8.13943 10.6666 6.66667C10.6666 5.19391 9.47274 4 7.99998 4Z"
        fill={color}
      />
    </svg>
  );
});
Pin.displayName = "Pin";
Pin.alias = ["location"];
