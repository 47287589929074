import React from "react";

import type { IconProps } from "./types";

export const List = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33334 2.50004H16.6667C17.1269 2.50004 17.5 2.87314 17.5 3.33337V6.66671C17.5 7.12695 17.1269 7.50004 16.6667 7.50004H3.33334C2.87311 7.50004 2.50001 7.12695 2.50001 6.66671V3.33337C2.50001 2.87314 2.87311 2.50004 3.33334 2.50004ZM0.833344 3.33337C0.833344 1.95266 1.95263 0.833374 3.33334 0.833374H16.6667C18.0474 0.833374 19.1667 1.95266 19.1667 3.33337V6.66671C19.1667 8.04742 18.0474 9.16671 16.6667 9.16671H3.33334C1.95263 9.16671 0.833344 8.04742 0.833344 6.66671V3.33337ZM3.33334 12.5H16.6667C17.1269 12.5 17.5 12.8731 17.5 13.3334V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H3.33334C2.87311 17.5 2.50001 17.1269 2.50001 16.6667V13.3334C2.50001 12.8731 2.87311 12.5 3.33334 12.5ZM0.833344 13.3334C0.833344 11.9527 1.95263 10.8334 3.33334 10.8334H16.6667C18.0474 10.8334 19.1667 11.9527 19.1667 13.3334V16.6667C19.1667 18.0474 18.0474 19.1667 16.6667 19.1667H3.33334C1.95263 19.1667 0.833344 18.0474 0.833344 16.6667V13.3334Z"
        fill={color}
      />
    </svg>
  );
});
List.displayName = "List";
