import React from "react";

import type { IconProps } from "./types";

export const Circle = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <rect width="10" height="10" rx="5" fill={color} />
    </svg>
  );
});
Circle.displayName = "Circle";
