import { z } from "zod";

export const ratingTypeSchema = z.object({
  rank: z.coerce.number(),
  name: z.string(),
  label: z.string(),
  is_overall_type: z.boolean(),
});
export type RatingType = z.infer<typeof ratingTypeSchema>;

export const ratingSummarySchema = ratingTypeSchema
  .pick({
    rank: true,
    label: true,
    name: true,
    is_overall_type: true,
  })
  .extend({
    avg_rating: z.coerce.number(),
    rating_count: z.coerce.number(),
  });
export type RatingSummary = z.infer<typeof ratingSummarySchema>;
