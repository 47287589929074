import { useEventListener } from "@react-hookz/web";
import React from "react";
import { useBlocker as useBlockerReactRouter, useSearchParams as useSearchParamsRouter } from "react-router-dom";
import type { z } from "zod";

export const makeLazyRoute = <TComponentName extends string>(
  componentImport: () => Promise<{ [Key in TComponentName]: React.ComponentType }>,
  componentName: TComponentName,
): (() => Promise<{ Component: React.ComponentType }>) => {
  return () => componentImport().then((module) => ({ Component: module[componentName] }));
};

export const stripScheme = (value: string): string | undefined => {
  if (!value) return value;
  const asLowerCase = value.toLowerCase();
  if (asLowerCase.startsWith("http://")) return value.substring(7);
  if (asLowerCase.startsWith("https://")) return value.substring(8);
  return value;
};

export const useBlocker = (when: boolean) => {
  useEventListener(window, "beforeunload", (e: BeforeUnloadEvent) => {
    if (!when) return;
    e.preventDefault();
    // included for legacy support
    e.returnValue = "You have unsaved changes, are you sure?";
  });
  return useBlockerReactRouter(when);
};

export const useSearchParams = <
  TSchema extends z.ZodType<any, any>,
  TParams extends string | number | symbol = keyof TSchema["_output"],
  TReturn = TSchema["_output"],
>(
  schema: TSchema,
) => {
  const [searchParams, setSearchParams] = useSearchParamsRouter();

  return React.useMemo(() => {
    const parsed = schema.safeParse(Object.fromEntries(searchParams.entries()));
    const params: TReturn = parsed.success ? parsed.data : {};

    const set = <T extends TParams>(key: T, value: string | number | undefined) => {
      if (value === undefined) {
        searchParams.delete(String(key));
      } else {
        searchParams.set(String(key), String(value));
      }
      setSearchParams(searchParams);
    };

    return [params, set] as const;
  }, [schema, searchParams, setSearchParams]);
};
