import React from "react";

import type { IconProps } from "./types";

export const Warning = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01874 1.522C7.31826 1.35336 7.6562 1.26477 7.99993 1.26477C8.34367 1.26477 8.6816 1.35336 8.98113 1.522C9.28065 1.69063 9.53166 1.93362 9.70993 2.22751L9.71185 2.23068L15.3585 11.6574L15.3639 11.6666C15.5386 11.969 15.631 12.3119 15.6319 12.6611C15.6329 13.0103 15.5424 13.3537 15.3695 13.6571C15.1966 13.9605 14.9472 14.2133 14.6462 14.3904C14.3452 14.5675 14.0031 14.6627 13.6539 14.6666L13.6466 14.6666L2.34594 14.6666C1.99674 14.6628 1.65463 14.5675 1.35365 14.3904C1.05266 14.2133 0.803301 13.9605 0.630365 13.6571C0.45743 13.3537 0.36695 13.0103 0.367928 12.6611C0.368906 12.3119 0.461307 11.969 0.635939 11.6666L0.641354 11.6574L6.28802 2.23069L6.85992 2.57326L6.28993 2.22751C6.4682 1.93362 6.71921 1.69063 7.01874 1.522ZM7.43074 2.91769L1.78839 12.3372C1.73161 12.437 1.70158 12.5499 1.70126 12.6648C1.70093 12.7812 1.73109 12.8957 1.78874 12.9968C1.84638 13.0979 1.9295 13.1822 2.02983 13.2413C2.12928 13.2998 2.2422 13.3315 2.35753 13.3333H13.6423C13.7577 13.3315 13.8706 13.2998 13.97 13.2413C14.0704 13.1822 14.1535 13.0979 14.2111 12.9968C14.2688 12.8957 14.2989 12.7812 14.2986 12.6648C14.2983 12.5499 14.2683 12.437 14.2115 12.3372L8.56993 2.91902L8.56913 2.91769C8.50975 2.82032 8.42639 2.7398 8.327 2.68385C8.22716 2.62763 8.11451 2.5981 7.99993 2.5981C7.88535 2.5981 7.77271 2.62763 7.67287 2.68385C7.57348 2.7398 7.49011 2.82032 7.43074 2.91769ZM7.99992 5.33329C8.36811 5.33329 8.66659 5.63177 8.66659 5.99996V8.66663C8.66659 9.03482 8.36811 9.33329 7.99992 9.33329C7.63173 9.33329 7.33325 9.03482 7.33325 8.66663V5.99996C7.33325 5.63177 7.63173 5.33329 7.99992 5.33329ZM7.99992 10.6666C7.63173 10.6666 7.33325 10.9651 7.33325 11.3333C7.33325 11.7015 7.63173 12 7.99992 12H8.00659C8.37477 12 8.67325 11.7015 8.67325 11.3333C8.67325 10.9651 8.37477 10.6666 8.00659 10.6666H7.99992Z"
        fill={color}
      />
    </svg>
  );
});
Warning.displayName = "Warning";
