import { z } from "zod";

export const networkInvitePayloadSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  phone_number: z.string(),
  hourly_wage: z.string().refine((s) => !s.includes("-"), { message: "Must be a positive number." }),
});

export type NetworkInvitePayload = z.infer<typeof networkInvitePayloadSchema>;
