import { usersHooks } from "api/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { ROUTES, buildRoute } from "routing/Routes";

type UseLinkableAccountsStatusProps = {
  signUpRequired: boolean;
};

export function useLinkableAccountsStatus({ signUpRequired }: UseLinkableAccountsStatusProps) {
  const navigate = useNavigate();

  const [searchParams] = useTypedSearchParams(ROUTES.SIGN_UP);
  const isAccountLinkingEnabled = !searchParams.skipAccountLinking && signUpRequired;

  const linkableAccounts = usersHooks.useLinkableAccounts(undefined, {
    enabled: isAccountLinkingEnabled,
  });
  const linkingRequired = !linkableAccounts.isLoading && linkableAccounts.data?.length !== 0;

  React.useEffect(() => {
    if (!isAccountLinkingEnabled) return;
    if (linkingRequired) navigate(buildRoute(ROUTES.ACCOUNT_LINKING, {}));
  }, [linkingRequired, navigate, isAccountLinkingEnabled]);

  if (!isAccountLinkingEnabled) return "idle";
  if (linkableAccounts.isLoading) return "loading";
  if (linkingRequired) return "linking-required";
  return linkableAccounts.status;
}
