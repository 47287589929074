"use client";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import { cn } from "tailwind-config";

import { useIsFocused } from "../../utils/useIsFocused";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Apply error styling to the input.
   */
  invalid?: boolean;

  /**
   * The className value to pass to the div that wraps the input.
   */
  className?: string;

  /**
   * The className value to pass to the `input`.
   */
  inputClassName?: string;

  /**
   * A react node that renders at the start of the input inside of the wrapper.
   * Note: Use `InputAdornment` for the best experience. It will provide some sensible defaults for
   * styling around whatever you render in the input.
   */
  startAdornment?: React.ReactNode;

  /**
   * A react node that renders at the end of the input inside of the wrapper.
   * Note: Use `InputAdornment` for the best experience. It will provide some sensible defaults for
   * styling around whatever you render in the input.
   */
  endAdornment?: React.ReactNode;

  asChild?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      disabled,
      readOnly,
      invalid,
      startAdornment,
      endAdornment,
      inputClassName,
      className,
      onFocus,
      onBlur,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const { isFocused, ...focusHandlers } = useIsFocused({ onFocus, onBlur });
    const Comp = asChild ? Slot : "input";
    return (
      <div
        className={cn(
          "inline-flex w-full items-center rounded border-2 bg-white",
          {
            "bg-lightBlue [&>input]:bg-lightBlue": disabled || readOnly,
            /**
             * Order of the border color definitions below matters.
             * Focused should take priority over default, and error should take priority over both.
             */
            "border-mediumGray": !invalid || disabled || readOnly,
            "border-brand-purple": isFocused,
            "border-error": invalid && !disabled,
            "pl-2": !!startAdornment,
            "pr-2": !!endAdornment,
          },
          className,
        )}
      >
        {startAdornment}
        <Comp
          type={type}
          className={cn(
            "w-full rounded px-3 py-2 text-large font-regular leading-5 outline-none placeholder:text-mediumGray",
            inputClassName,
          )}
          ref={ref}
          disabled={disabled}
          readOnly={readOnly}
          aria-invalid={invalid}
          {...props}
          {...focusHandlers}
        />
        {endAdornment}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
