import React from "react";

import type { IconProps } from "../types";

export const Maintenance = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.49 0.000341305C17.1942 -0.00738513 17.8938 0.116133 18.5528 0.364581C18.8706 0.484411 19.1059 0.757399 19.1775 1.08945C19.2491 1.4215 19.1473 1.7672 18.9071 2.0074L16.6142 4.30029L18.7 6.38608L20.9929 4.09319C21.2338 3.85225 21.5809 3.75058 21.9137 3.8234C22.2466 3.89622 22.5195 4.13351 22.6378 4.45304C22.894 5.145 23.0169 5.8792 22.9999 6.61683C22.9828 8.33091 22.2943 9.9701 21.0821 11.1823C19.8683 12.3961 18.2264 13.0848 16.51 13.1002C16.0453 13.1053 15.5827 13.0533 15.1324 12.9459L6.75716 22.6535C6.57557 22.864 6.31456 22.9894 6.03677 22.9996C5.75897 23.0098 5.48946 22.904 5.29289 22.7074L0.292894 17.7074C0.095737 17.5102 -0.0101546 17.2397 0.000768702 16.9611C0.011692 16.6825 0.138438 16.4211 0.350426 16.24L10.1586 7.86019C10.0552 7.41631 10.0018 6.96146 10 6.50419L9.99996 6.4915L10 6.4915C10.0151 4.77461 10.7039 3.13231 11.9179 1.91824C13.1317 0.704443 14.7736 0.0157452 16.49 0.000341305ZM16.0596 2.02644C15.0326 2.13748 14.0689 2.59571 13.3322 3.33245C12.4904 4.17425 12.0122 5.31243 12 6.50268C12.0025 6.96606 12.084 7.42564 12.2409 7.86168C12.3817 8.25293 12.2657 8.69049 11.9496 8.96059L2.47192 17.058L5.94598 20.5321L14.0428 11.1471C14.3169 10.8294 14.7602 10.7166 15.1528 10.8646C15.5796 11.0255 16.0327 11.1055 16.4888 11.1004L16.4912 11.1003C17.6838 11.0898 18.8245 10.6114 19.6678 9.76814C20.431 9.00501 20.8953 7.99831 20.9844 6.93006L19.4071 8.5074C19.0166 8.89792 18.3834 8.89792 17.9929 8.5074L14.4929 5.0074C14.1024 4.61687 14.1024 3.98371 14.4929 3.59319L16.0596 2.02644Z"
        fill={color}
      />
    </svg>
  );
});

Maintenance.displayName = "Maintenance";
