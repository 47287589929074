import { isAxiosError } from "axios";
import React from "react";
import { Outlet, useNavigate, useLocation as useRouterLocation } from "react-router-dom";

import { usersHooks } from "api/client";
import type { ManagerProfile } from "api/clients/users";
import type { User } from "api/resources/User";
import { LoaderLogo } from "components/Loader";
import { identify as ldIdentify } from "providers/LaunchDarkly/client";
import * as Auth from "services/auth";
import { type AnalyticsUserTraits, identify as analyticsIdentify, getAnalyticsUserTraits } from "utils/analytics";
import { HTTP_STATUS } from "utils/httpStatus";
import { useLinkableAccountsStatus } from "./useLinkableAccountStatus";

interface IAuthContextData {
  loaded: boolean;
  user: User;
  managerProfile?: ManagerProfile;
  analyticsUser?: AnalyticsUserTraits;
  hasLocations?: boolean;

  signUpRequired: boolean;
  locationRequired: boolean;
  linkableAccountsStatus: "idle" | "loading" | "linking-required" | "success" | "error";
}

interface IAuthContext extends IAuthContextData {
  logout: typeof Auth.logout;
  refreshUser: () => Promise<{ user: User; managerProfile: ManagerProfile } | undefined> | null;
}

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export const useAuth = () => React.useContext(AuthContext);
export const useIsAdmin = () => !!useAuth().user?.is_gigpro_admin;

const expectedErrors = [HTTP_STATUS.UNAUTHORIZED, HTTP_STATUS.NOT_FOUND];
const isExpectedError = (error: unknown) =>
  isAxiosError(error) && error.response?.status && expectedErrors.includes(error.response?.status);

export function AuthProvider() {
  const location = useRouterLocation();
  const navigate = useNavigate();

  const authChecked = React.useRef(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const {
    data: user,
    error: userError,
    refetch: refetchUser,
  } = usersHooks.useAuthUser(undefined, {
    enabled: isAuthenticated,
    retry: (count, error) => (isExpectedError(error) ? false : count <= 1),
    staleTime: 5 * 60 * 100,
  });
  const {
    data: managerProfile,
    refetch: refetchManagerProfile,
    isLoading: profileLoading,
  } = usersHooks.useAuthManager(undefined, {
    enabled: isAuthenticated,
    retry: (count, error) => (isExpectedError(error) ? false : count < 1),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 100,
  });
  const loaded = !!user && !profileLoading;
  const signUpRequired = loaded && !(user?.app_mode && managerProfile?.id);
  const linkableAccountsStatus = useLinkableAccountsStatus({ signUpRequired });

  const globalLoading = !isAuthenticated || !user || linkableAccountsStatus === "loading";

  const hasLocations = managerProfile?.has_locations;

  const analyticsUser = React.useMemo(() => {
    if (!user) return;
    return getAnalyticsUserTraits(user, managerProfile);
  }, [user, managerProfile]);

  React.useEffect(() => {
    if (!analyticsUser) return;
    analyticsIdentify(analyticsUser.id, analyticsUser);
    ldIdentify({ user: analyticsUser });
  }, [analyticsUser]);

  React.useEffect(() => {
    const checkAuth = async () => {
      if (authChecked.current) return;
      authChecked.current = true;
      try {
        const pathname = await Auth.checkAuthentication();
        if (pathname && pathname !== location.pathname + location.search) {
          navigate(pathname, { replace: true });
        }
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
        Auth.logout();
      }
    };
    checkAuth();
  }, [location.pathname, navigate, location.search]);

  React.useEffect(() => {
    if (userError) {
      if (isAxiosError(userError) && userError.response?.status === HTTP_STATUS.UNAUTHORIZED) {
        Auth.logout();
      }
    }
  }, [userError]);

  const contextValue: IAuthContext | undefined = React.useMemo(() => {
    if (globalLoading) return undefined;

    return {
      loaded,
      linkableAccountsStatus,
      signUpRequired,
      locationRequired: loaded && !hasLocations && !user.is_gigpro_admin,
      user,
      managerProfile,
      analyticsUser,
      hasLocations,
      logout: Auth.logout,
      refreshUser: async () => {
        const [user, managerProfile] = await Promise.all([refetchUser(), refetchManagerProfile()]);
        if (!(user.data && managerProfile.data)) return;
        return { user: user.data, managerProfile: managerProfile.data };
      },
    };
  }, [
    globalLoading,
    linkableAccountsStatus,
    user,
    managerProfile,
    analyticsUser,
    hasLocations,
    signUpRequired,
    loaded,
    refetchUser,
    refetchManagerProfile,
  ]);

  return contextValue ?
      <AuthContext.Provider value={contextValue}>
        <Outlet />
      </AuthContext.Provider>
    : <LoaderLogo />;
}
