import { LDProvider, useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import type { Location } from "api/resources/Location";
import { env } from "config/env";
import { useAuth } from "providers/Auth";
import { useActiveLocation } from "services/locationOrgContext";

import { context, identify, ldClientPromise, options } from "./client";
import { type FeatureFlagKey, type FeatureFlagValue, defaultFlags } from "./featureFlags";

export const LaunchDarklyProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <LDProvider
      ldClient={ldClientPromise}
      context={context}
      options={options}
      clientSideID={env.REACT_APP_LAUNCH_DARKLY_ID}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      flags={defaultFlags}
    >
      {children}
    </LDProvider>
  );
};

export const useFeatureFlag = <FlagKey extends FeatureFlagKey>(feature: FlagKey): FeatureFlagValue[FlagKey] => {
  const flags = useFlags<FeatureFlagValue>();
  return flags[feature] ?? defaultFlags[feature];
};

export const usePageLocationContext = (location?: Location) => {
  const { analyticsUser } = useAuth();
  const [activeLocation] = useActiveLocation();

  React.useEffect(() => {
    if (!analyticsUser || !location || location.id === activeLocation?.id) return;
    identify({ user: analyticsUser, location });
    return () => {
      identify({ user: analyticsUser, location: activeLocation });
    };
  }, [activeLocation, location, analyticsUser]);
};
