import { Zodios } from "@zodios/axios";
import type { ZodiosEndpointDefinition } from "@zodios/core";
import { pluginToken } from "@zodios/plugins";
import { ZodiosHooks } from "@zodios/react";
import axios from "axios";

import { env } from "config/env";
import { getTokenSilently } from "services/auth";

type MakeClientOptions = {
  skipAuth?: boolean;
};

export const makeClientWithHooks = <Api extends readonly ZodiosEndpointDefinition[]>(
  name: string,
  api: Api,
  options?: MakeClientOptions,
) => {
  const client = new Zodios(`${env.REACT_APP_SG_API_URL}api`, api, { axiosInstance: axios });
  if (!options?.skipAuth) client.use(pluginToken({ getToken: getTokenSilently }));
  return { client, hooks: new ZodiosHooks(name, client) };
};
