import React from "react";

import type { IconComponent } from "./types";

export const Send: IconComponent = React.forwardRef(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M18.9062 2.26562C19.0885 2.08333 19.1797 1.88802 19.1797 1.67969C19.1797 1.44531 19.0885 1.25 18.9062 1.09375C18.75 0.911458 18.5547 0.820312 18.3203 0.820312C18.112 0.820312 17.9167 0.911458 17.7344 1.09375L8.59375 10.2344C8.41146 10.4167 8.32031 10.625 8.32031 10.8594C8.32031 11.0677 8.39844 11.263 8.55469 11.4453C8.73698 11.6016 8.93229 11.6797 9.14062 11.6797C9.375 11.6797 9.58333 11.5885 9.76562 11.4062L18.9062 2.26562ZM3.94531 7.57812L16.9922 3.00781L12.4219 16.0547L9.92188 10.5078C9.84375 10.2995 9.70052 10.1562 9.49219 10.0781L3.94531 7.57812ZM0.820312 7.5C0.820312 7.86458 0.989583 8.125 1.32812 8.28125L8.55469 11.4453L11.7188 18.6719C11.875 19.0104 12.1354 19.1797 12.5 19.1797C12.8906 19.1797 13.151 18.9844 13.2812 18.5938L19.1016 1.95312C19.2318 1.61458 19.1667 1.32812 18.9062 1.09375C18.6719 0.833333 18.3854 0.768229 18.0469 0.898438L1.40625 6.71875C1.01562 6.84896 0.820312 7.10938 0.820312 7.5Z"
        fill={color}
      />
    </svg>
  );
});
Send.displayName = "Send";
