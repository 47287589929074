import { type Breakpoint, type SxProps, type Theme, useMediaQuery, useTheme } from "@mui/material";
import get from "lodash/get";

import type { Path } from "types/utils";

import type { ThemeColor } from "./types";

type ThemeArgs = Theme | { theme: Theme };

type ThemeSelector<P, R> = (selector: P) => (args: ThemeArgs) => R;

const getTheme = (args: ThemeArgs): Theme => (args as any).theme ?? args;

export const spacingSelector: ThemeSelector<number, string> = (spacing) => (args) => getTheme(args).spacing(spacing);

export const colorSelector: ThemeSelector<ThemeColor, string> = (color) => (args) =>
  `${get(getTheme(args).palette, color)}`;
export const useThemeColor = (color: ThemeColor) => colorSelector(color)(useTheme());

export const breakpointSelector: ThemeSelector<Breakpoint, string> = (breakpoint) => (args) =>
  getTheme(args).breakpoints.up(breakpoint);
export const breakpointSelectorDown: ThemeSelector<Breakpoint, string> = (breakpoint) => (args) =>
  getTheme(args).breakpoints.down(breakpoint);
export const useBreakpoint = (breakpoint: Breakpoint) => useMediaQuery(breakpointSelector(breakpoint)(useTheme()));

export const typographySelector: ThemeSelector<Path<Theme["typography"]>, any> = (selector) => (args) =>
  get(getTheme(args).typography, selector);

export const normalizeSx = (sx: SxProps<Theme> = []): any[] => (Array.isArray(sx) ? sx : [sx]);
