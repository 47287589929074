"use client";

import type { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { cn } from "tailwind-config";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "..";
import { Checkbox as CheckboxPrimitive, type CheckboxProps } from "../../Checkbox";
import type { FormFieldProps } from "../types";

export const Checkbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  description,
  optional,
  className,
  name,
  control,
  shouldUnregister,
  ...checkboxProps
}: FormFieldProps<TFieldValues, TName> & CheckboxProps) => {
  const formFieldProps: UseControllerProps<TFieldValues> = { name, control, shouldUnregister };
  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => (
        <FormItem className={cn("space-y-1.5", className)}>
          <div className="flex items-center gap-2">
            <FormControl>
              <CheckboxPrimitive {...checkboxProps} checked={!!field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <FormLabel optional={optional}>{label}</FormLabel>
          </div>
          <FormMessage />
          <FormDescription>{description}</FormDescription>
        </FormItem>
      )}
    />
  );
};

Checkbox.displayName = "Checkbox";
