import { CloudinaryImage } from "@cloudinary/url-gen";
import type { BorderAction } from "@cloudinary/url-gen/actions/border";
import { format, quality } from "@cloudinary/url-gen/actions/delivery";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { max } from "@cloudinary/url-gen/actions/roundCorners";
import { auto as autoFormat } from "@cloudinary/url-gen/qualifiers/format";
import { auto as autoQuality } from "@cloudinary/url-gen/qualifiers/quality";

import { env } from "config/env";

export const getCloudinaryImage = (
  publicId: string,
  options?: {
    width?: number;
    height?: number;
    circle?: boolean;
    border?: BorderAction;
  },
) => {
  let image = new CloudinaryImage(publicId, { cloudName: env.REACT_APP_CLOUDINARY_CLOUD_NAME })
    .delivery(quality(autoQuality()))
    .delivery(format(autoFormat()));

  if (options?.border) image = image.border(options.border);

  if (!options) return image;

  const { width, height, circle = false } = options;
  if (width && height) image = image.resize(scale(width, height));
  else if (width) image = image.resize(scale(width));
  else if (height) image = image.resize(scale().height(height));

  if (circle) image = image.roundCorners(max());

  return image;
};

export const uploadImage = async (file: File): Promise<{ public_id: string }> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "location_images");
  const response = await fetch(`https://api.cloudinary.com/v1_1/${env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, {
    method: "POST",
    body: formData,
  });
  const data = await response.json();
  return data;
};

export const makeIdToCloudinaryUrl = (image_public_id: string | undefined | null) =>
  image_public_id ? getCloudinaryImage(image_public_id, { circle: true, width: 100, height: 100 }).toURL() : undefined;

export const defaultImage = makeIdToCloudinaryUrl(env.REACT_APP_DEFAULT_LOCATION_IMAGE_ID);
