import React from "react";

import type { IconProps } from "./types";

export const Lock = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M4.17969 10H15.8203C16.0547 10 16.25 10.0781 16.4062 10.2344C16.5885 10.3906 16.6797 10.5859 16.6797 10.8203V16.6797C16.6797 16.9141 16.5885 17.1094 16.4062 17.2656C16.25 17.4219 16.0547 17.5 15.8203 17.5H4.17969C3.94531 17.5 3.73698 17.4219 3.55469 17.2656C3.39844 17.1094 3.32031 16.9141 3.32031 16.6797V10.8203C3.32031 10.5859 3.39844 10.3906 3.55469 10.2344C3.73698 10.0781 3.94531 10 4.17969 10ZM4.17969 8.32031C3.47656 8.32031 2.8776 8.56771 2.38281 9.0625C1.91406 9.55729 1.67969 10.1432 1.67969 10.8203V16.6797C1.67969 17.3568 1.91406 17.9427 2.38281 18.4375C2.8776 18.9323 3.47656 19.1797 4.17969 19.1797H15.8203C16.5234 19.1797 17.1094 18.9323 17.5781 18.4375C18.0729 17.9427 18.3203 17.3568 18.3203 16.6797V10.8203C18.3203 10.1432 18.0729 9.55729 17.5781 9.0625C17.1094 8.56771 16.5234 8.32031 15.8203 8.32031H4.17969ZM5 9.17969C5 9.38802 5.07812 9.58333 5.23438 9.76562C5.41667 9.92188 5.61198 10 5.82031 10C5.97656 10 6.11979 9.96094 6.25 9.88281C6.38021 9.80469 6.48438 9.70052 6.5625 9.57031C6.64062 9.4401 6.67969 9.3099 6.67969 9.17969V5.82031C6.67969 4.90885 7.00521 4.1276 7.65625 3.47656C8.30729 2.82552 9.08854 2.5 10 2.5C10.9115 2.5 11.6927 2.82552 12.3438 3.47656C12.9948 4.1276 13.3203 4.90885 13.3203 5.82031V9.17969C13.3203 9.38802 13.3984 9.58333 13.5547 9.76562C13.737 9.92188 13.9323 10 14.1406 10C14.375 10 14.5703 9.92188 14.7266 9.76562C14.9089 9.58333 15 9.38802 15 9.17969V5.82031C15 4.4401 14.5052 3.26823 13.5156 2.30469C12.5521 1.3151 11.3802 0.820312 10 0.820312C8.61979 0.820312 7.4349 1.3151 6.44531 2.30469C5.48177 3.26823 5 4.4401 5 5.82031V9.17969Z"
        fill={color}
      />
    </svg>
  );
});
Lock.displayName = "Lock";
