import React from "react";

import type { IconProps } from "./types";

export const Star = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M10.0001 0.833374C10.3172 0.833374 10.6069 1.01343 10.7473 1.29785L13.1286 6.1221L18.4539 6.90047C18.7677 6.94634 19.0283 7.16634 19.1261 7.46801C19.2239 7.76969 19.142 8.10073 18.9148 8.32201L15.0622 12.0745L15.9714 17.3758C16.025 17.6885 15.8965 18.0045 15.6398 18.1909C15.3832 18.3774 15.043 18.4019 14.7622 18.2543L10.0001 15.7499L5.23794 18.2543C4.95718 18.4019 4.61694 18.3774 4.36029 18.1909C4.10364 18.0045 3.9751 17.6885 4.02872 17.3758L4.93796 12.0745L1.08529 8.32201C0.858106 8.10073 0.776212 7.76969 0.874027 7.46801C0.971841 7.16634 1.23241 6.94634 1.54621 6.90047L6.87151 6.1221L9.25281 1.29785C9.3932 1.01343 9.68288 0.833374 10.0001 0.833374Z"
        fill={color}
      />
    </svg>
  );
});
Star.displayName = "Star";
