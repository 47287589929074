import { makeApi } from "@zodios/core";

import { locationAddOnSchema, mutatedLocationAddOnSchema } from "api/resources/LocationAddOn";
import { makePaginatedResult } from "api/resources/resource";

export const locationAddOnApi = makeApi([
  {
    method: "get",
    path: "locations/:locationId/add-ons/",
    response: makePaginatedResult(locationAddOnSchema),
    alias: "locationAddOns",
  },
  {
    method: "post",
    path: "locations/:locationId/add-ons/",
    response: locationAddOnSchema,
    parameters: [{ name: "Create Location Add-On", type: "Body", schema: mutatedLocationAddOnSchema }],
    alias: "createLocationAddOn",
  },
  {
    method: "patch",
    path: "locations/:locationId/add-ons/:id/",
    response: locationAddOnSchema,
    parameters: [{ name: "Update Location Add-On", type: "Body", schema: locationAddOnSchema.partial() }],
    alias: "partialUpdateLocationAddOn",
  },
]);
