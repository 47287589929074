import { useIntervalEffect } from "@react-hookz/web";
import { useState } from "react";

import { GigproLogo } from "@gigpro/ui/Icons";
import { loaderInterval, loaderNext, loaderOptions } from "./constants";

export const LoaderLogo = () => {
  const [loader, setLoader] = useState(loaderNext());
  useIntervalEffect(() => {
    setLoader(loaderNext());
  }, loaderInterval);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <GigproLogo className="h-auto max-h-40 w-[45vw] max-w-72 animate-size-pulse fill-black sm:w-[26vw] md:w-[22vw] lg:w-[22vw] xl:w-[15vw]" />
      <p className="mt-4 text-large xl:mt-6">{loaderOptions[loader][1]}</p>
    </div>
  );
};
