import React from "react";

import type { IconProps } from "./types";

export const Money = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M8.65776 0.65625C8.65776 0.489583 8.59512 0.34375 8.46984 0.21875C8.34456 0.0729167 8.18796 0 8.00004 0C7.87476 0 7.75992 0.03125 7.65552 0.09375C7.55112 0.15625 7.4676 0.239583 7.40496 0.34375C7.3632 0.447917 7.34232 0.552083 7.34232 0.65625V15.3438C7.34232 15.5104 7.40496 15.6562 7.53024 15.7812C7.65552 15.9271 7.81212 16 8.00004 16C8.18796 16 8.34456 15.9271 8.46984 15.7812C8.59512 15.6562 8.65776 15.5104 8.65776 15.3438V0.65625ZM11.3513 4C11.5183 4 11.6645 3.9375 11.7898 3.8125C11.9359 3.66667 12.009 3.51042 12.009 3.34375C12.009 3.15625 11.9359 3 11.7898 2.875C11.6645 2.72917 11.5183 2.65625 11.3513 2.65625H6.34009C5.50489 2.65625 4.79497 2.95833 4.21033 3.5625C3.62569 4.14583 3.33337 4.85417 3.33337 5.6875C3.33337 6.5 3.62569 7.19792 4.21033 7.78125C4.79497 8.36458 5.50489 8.65625 6.34009 8.65625H9.66C10.1194 8.65625 10.5161 8.82292 10.8502 9.15625C11.1842 9.48958 11.3513 9.88542 11.3513 10.3438C11.3513 10.8021 11.1842 11.1979 10.8502 11.5312C10.5161 11.8438 10.1194 12 9.66 12H3.99109C3.80317 12 3.64657 12.0729 3.52129 12.2188C3.39601 12.3438 3.33337 12.5 3.33337 12.6875C3.33337 12.8542 3.39601 13.0104 3.52129 13.1562C3.64657 13.2812 3.80317 13.3438 3.99109 13.3438H9.66C10.4952 13.3438 11.2051 13.0521 11.7898 12.4688C12.3744 11.8646 12.6667 11.1562 12.6667 10.3438C12.6667 9.51042 12.3744 8.80208 11.7898 8.21875C11.2051 7.63542 10.4952 7.34375 9.66 7.34375H6.34009C5.88073 7.34375 5.48401 7.17708 5.14993 6.84375C4.81585 6.51042 4.64881 6.11458 4.64881 5.65625C4.64881 5.19792 4.81585 4.8125 5.14993 4.5C5.48401 4.16667 5.88073 4 6.34009 4H11.3513Z"
        fill={color}
      />
    </svg>
  );
});
Money.displayName = "Money";
