export const loaderInterval = 1_200;
export const loaderOptions = [
  ["🌶", "Dicing peppers"],
  ["🔪", "Sharpening knives"],
  ["👩🏽‍🍳", "Starching aprons"],
  ["🥒", "Sautéing veggies"],
  ["🍳", "Frying eggs"],
  ["🧀", "Cutting cheese"],
  ["🥓", "Crisping bacon"],
  ["🌽", "Shucking corn"],
  ["🍚", "Steaming rice"],
  ["🍞", "Baking bread"],
  ["🍷", "Uncorking wine"],
  ["🍽", "Plating dishes"],
  ["🍤", "Frying shrimp"],
  ["🥐", "Toasting croissants"],
  ["🍋", "Deseeding lemons"],
  ["🥕", "Peeling carrots"],
  ["🍲", "Stirring soups"],
  ["🐸🍵", "Spilling tea"],
  ["🍕", "Firing pizzas"],
  ["🔥", "Lighting burners"],
  ["🥔", "Roasting potatoes"],
  ["🤔", "Testing tastes"],
  ["🍊", "Squeezing oranges"],
  ["💧", "Pouring water"],
  ["🍦", "Freezing ice cream"],
  ["🍨", "Scooping desserts"],
  ["🥗", "Mixing salads"],
  ["🥚", "Cracking eggs"],
  ["🥞", "Stacking pancakes"],
  ["🍒", "Putting a cherry on top"],
  ["🍗", "Seasoning wings"],
  ["🍅", "Crushing tomatoes"],
  ["🍍", "Chunking pineapples"],
  ["💖", "Adding a pinch of something perfect"],
  ["📝", "Writing recipes"],
  ["🗓", "Planning menus"],
  ["🌰", "Cracking nuts"],
  ["🍿", "Popping popcorn"],
  ["🍾", "Popping champers"],
  ["🍆", "Planting eggs"],
  ["🥑", "Mashing avocados"],
  ["🍌", "Peeling bananas"],
  ["🍔", "Flipping burgers"],
  ["🍻", "Clinking glasses"],
  ["🍴", "Polishing silverware"],
  ["🍪", "Baking cookies"],
  ["🍣", "Rolling sushi"],
  ["🍶", "Warming sake"],
  ["🍱", "Boxing bento"],
  ["🌯", "Wrapping burritos"],
  ["☕️", "Brewing coffee"],
  ["🍏", "Coring apples"],
  ["🌿", "Growing herbs"],
  ["🍰", "Slicing cakes"],
  ["🍟", "Frying fries"],
  ["🍑", "Peeling peaches"],
  ["🍛", "Spicing curry"],
  ["🌮", "Assembling tacos"],
  ["📸", "Photographing plates"],
  ["🛎", "Dinging bells"],
  ["🍫", "Melting chocolate"],
  ["🍜", "Cooking ramen"],
  ["🥖", "Baking baguettes"],
  ["❄️", "Shaving ice"],
  ["🥛", "Blending milkshakes"],
  ["🍄", "Chopping mushrooms"],
  ["🐟", "Searing fish"],
  ["😋", "Licking lips"],
  ["🤞🏾", "Crossing fingers"],
  ["👊🏿", "Bumping fists"],
  ["☝🏽", "Throwing in one last thing"],
  ["😙👌", "Doing that Italian chef thing"],
  ["👃🏻", "Smellin’ smells"],
  ["🍹", "Mixing drinks"],
  ["✨", "Making magic"],
  ["🌱", "Planting seeds"],
  ["⚗️", "Testing something new"],
];
export const loaderNext = () => Math.floor(Math.random() * loaderOptions.length);
