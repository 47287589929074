import { makeParameters } from "@zodios/core";
import { z } from "zod";

import type { Resource } from "api/django/Resource";

export interface ApiResource {
  /**
   * ID unique to this resource type
   */
  id: number;
}

/**
 * Utility type removing the `id` field from an {@linkcode ApiResource} since it's unknown at the time of creation
 */
export type CreateResource<T extends ApiResource | Resource> = Omit<T, "id">;
/**
 * Utility type making all properties except for `id` optional for partial updates
 */
export type UpdateResource<T extends ApiResource> = ApiResource & Partial<CreateResource<T>>;

export const isCreateResource = <T extends ApiResource>(
  resource: UpdateResource<T> | CreateResource<T> | Partial<T>,
): resource is CreateResource<T> => !(resource as any).id;

export const makeApiResource = <T extends z.ZodRawShape>(schema: T) =>
  z.object({
    id: z.coerce.number().nonnegative(),
    ...schema,
  });

const paginationFields = z.object({
  count: z.number().nonnegative(),
  next: z
    .string()
    .nullable()
    .transform((next) => (next ? Number(new URLSearchParams(new URL(next).search).get("page")) : null)),
  previous: z
    .string()
    .nullable()
    .transform((prev) => (prev ? Number(new URLSearchParams(new URL(prev).search).get("page")) : null)),
});

export type PaginationResponse<T> = z.infer<typeof paginationFields> & { results: Array<T> };

export const makePaginatedResult = <T extends z.ZodTypeAny>(schema: T) =>
  paginationFields.extend({
    results: z.array(schema),
  });

export const paginationParams = makeParameters([
  {
    name: "page",
    type: "Query",
    description: "Page number",
    schema: z.number().gte(1).optional(),
  },
  {
    name: "page_size",
    type: "Query",
    schema: z.number().gte(1).lte(50).optional(),
  },
]);

export const getInfiniteQueryOptions = <TData>() => ({
  getPageParamList: () => ["page"],
  getNextPageParam: (lastPage: PaginationResponse<TData>) =>
    lastPage.next ? { queries: { page: lastPage.next } } : undefined,
});

const errorBaseResponse = z.object({
  code: z.string(),
  message: z.string(),
  target: z.string().nullish(),
  target_value: z.union([z.number(), z.string()]).nullish(),
});

export const errorResponse = z.object({
  error: errorBaseResponse.extend({
    details: z.array(errorBaseResponse).optional(),
  }),
});
