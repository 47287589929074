import { useIntervalEffect } from "@react-hookz/web";
import { useState } from "react";
import { cn } from "tailwind-config";

import { loaderInterval, loaderNext, loaderOptions } from "./constants";
import { Emoji, Loader, Text } from "./styles";

export const EmojiLoader: React.FC<{ className?: string }> = ({ className }) => {
  const [loader, setLoader] = useState(loaderNext());
  useIntervalEffect(() => {
    setLoader(loaderNext());
  }, loaderInterval);
  return (
    <Loader className={cn("flex h-full flex-col items-center justify-center overflow-hidden", className)}>
      <Emoji>{loaderOptions[loader][0]}</Emoji>
      <Text>{loaderOptions[loader][1]}</Text>
    </Loader>
  );
};
