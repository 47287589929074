import React from "react";

import type { IconProps } from "./types";

export const Info = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99996 0.666668C3.94987 0.666668 0.666626 3.94991 0.666626 8C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 8C15.3333 3.94991 12.05 0.666668 7.99996 0.666668ZM7.33329 5.33333C7.33329 4.96514 7.63177 4.66667 7.99996 4.66667H8.00663C8.37481 4.66667 8.67329 4.96514 8.67329 5.33333C8.67329 5.70152 8.37481 6 8.00663 6H7.99996C7.63177 6 7.33329 5.70152 7.33329 5.33333ZM7.99996 7.33333C8.36815 7.33333 8.66663 7.63181 8.66663 8V10.6667C8.66663 11.0349 8.36815 11.3333 7.99996 11.3333C7.63177 11.3333 7.33329 11.0349 7.33329 10.6667V8C7.33329 7.63181 7.63177 7.33333 7.99996 7.33333Z"
        fill={color}
      />
    </svg>
  );
});
Info.displayName = "Info";
