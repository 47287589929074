import { getWeekOfMonth, intlFormat, setDay } from "date-fns";
import { z } from "zod";

import { modulo } from "..";

import { browserTimezone, convertToTimezone, formatDate, getDateTimezoneOffsetInDays } from ".";

export const weekdayNames = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"] as const;
export const weekdayOptions = Object.keys(weekdayNames).map(Number);
export type Weekday = (typeof weekdayNames)[number];

export const frequencyOptions = ["MONTHLY", "WEEKLY", "DAILY"] as const;
export type Frequency = (typeof frequencyOptions)[number];

export const Recurrence = z.object({
  weekdays: z.array(z.number()),
  frequency: z.enum(frequencyOptions),
  interval: z.number(),
  occurrence: z.number(),
  until: z.date().optional(),
});
export type Recurrence = z.infer<typeof Recurrence>;

export const repeatOptions = ["repeat", "no-repeat"] as const;
export type RepeatOption = (typeof repeatOptions)[number];

export const RecurrenceWithRepeat = Recurrence.extend({ repeat: z.enum(repeatOptions) });
export type RecurrenceWithRepeat = z.infer<typeof RecurrenceWithRepeat>;
export const getDefaultRecurrence = (baseDate: Date): RecurrenceWithRepeat => ({
  frequency: "WEEKLY",
  repeat: "no-repeat",
  weekdays: [baseDate.getDay()],
  interval: 1,
  occurrence: getWeekOfMonth(baseDate) > 4 ? -1 : getWeekOfMonth(baseDate),
});

const frequencyVerbiageLookup: Record<Frequency, string> = {
  MONTHLY: "month",
  WEEKLY: "week",
  DAILY: "day",
};
const numberVerbiageLookup = ["", "first", "second", "third", "fourth"] as const;
const getOccurrenceVerbiage = (occurrence: number) => (occurrence > 0 ? numberVerbiageLookup[occurrence] : "last");
const getWeekdayVerbiage = (weekday: number) => intlFormat(setDay(new Date(), weekday), { weekday: "short" });

/**
 * Converts a {@linkcode timezone} weekday number to a UTC weekday number
 * @param weekday weekday number
 * @param date date in {@linkcode timezone} to derive offset from
 * @param timezone timezone to derive UTC offset from
 * @returns weekday number in UTC
 */
export const convertWeekdayToTimezone = (weekday: number, date: Date, timezone: string) =>
  modulo(weekday - getDateTimezoneOffsetInDays(date, timezone), 7);

/**
 * Converts a UTC weekday number to a {@linkcode timezone} weekday number
 * @param weekday weekday number
 * @param date date in UTC to derive offset from
 * @param timezone timezone to derive UTC offset from
 * @returns weekday number in {@linkcode timezone}
 */
export const convertWeekdayToUTC = (weekday: number, date: Date, timezone: string) =>
  modulo(weekday + getDateTimezoneOffsetInDays(date, timezone), 7);

type Options = { timezone: string; baseDate: Date };

export function getRecurrenceVerbiage(recurrence: RecurrenceWithRepeat, options?: Options) {
  const { occurrence, weekdays, frequency, interval, until, repeat } = recurrence;
  const { timezone, baseDate } = options ?? {};
  if (repeat === "no-repeat") return "Does not repeat";
  const frequencyVerbiage = frequencyVerbiageLookup[frequency];
  let intervalVerbiage = interval === 1 ? `each ${frequencyVerbiage}` : `every ${interval} ${frequencyVerbiage}s`;
  const allWeek = weekdays.length === 7;
  const weekdaysVerbiage =
    allWeek ? "Every day" : (
      weekdays
        .sort()
        .map((day) => (baseDate && timezone ? convertWeekdayToTimezone(day, baseDate, timezone) : day))
        .map(getWeekdayVerbiage)
        .join(", ")
    );
  const untilString =
    until ? `until ${formatDate(convertToTimezone(until, timezone ?? browserTimezone), "MMM d")}` : "";

  let verbiage = "";

  if (frequency === "MONTHLY") {
    if (weekdays.length === 1) {
      verbiage = `Every ${getOccurrenceVerbiage(occurrence)} ${weekdaysVerbiage} ${intervalVerbiage} ${untilString}`;
    } else {
      intervalVerbiage = interval === 1 ? "of the month" : intervalVerbiage;
      verbiage = `${weekdaysVerbiage} every ${getOccurrenceVerbiage(
        occurrence,
      )} week ${intervalVerbiage} ${untilString}`;
    }
  } else {
    verbiage = `${weekdaysVerbiage} ${allWeek ? "of the week" : ""} ${intervalVerbiage} ${untilString}`;
  }

  return verbiage.replace(/\s+/g, " ").trim();
}
