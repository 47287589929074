import { makeApi } from "@zodios/core";
import { z } from "zod";

import { proGroupPayloadSchema, proGroupSummarySchema } from "api/resources/proGroups";
import { proHireProSchema } from "api/resources/pros";

const proGroupsPath = "/pro-groups/";
const proGroupsMembersPath = `${proGroupsPath}:groupId/members/` as const;
const proGroupsMemberPath = `${proGroupsMembersPath}:memberId/` as const;

export const proGroupsApi = makeApi([
  {
    method: "get",
    path: proGroupsPath,
    parameters: [
      { name: "organization_id", type: "Query", schema: z.string().optional() },
      { name: "market_id", type: "Query", schema: z.number().optional() },
    ],
    response: z.array(proGroupSummarySchema).transform((groups) =>
      groups.reduce(
        (acc, group) => {
          if (group.is_autogenerated) {
            acc.autogenerated.push(group);
            return acc;
          }
          acc.user.push(group);
          return acc;
        },
        { all: groups, user: [] as typeof groups, autogenerated: [] as typeof groups },
      ),
    ),
    alias: "proGroups",
  },
  {
    method: "post",
    path: proGroupsPath,
    response: proGroupPayloadSchema,
    parameters: [{ name: "Pro group", type: "Body", schema: proGroupPayloadSchema }],
    alias: "createProGroup",
  },
  {
    method: "delete",
    path: `${proGroupsPath}:id/`,
    response: proGroupPayloadSchema,
    alias: "deleteProGroup",
  },
  {
    method: "patch",
    path: `${proGroupsPath}:id/`,
    response: proGroupPayloadSchema,
    parameters: [{ name: "Rename Pro group", type: "Body", schema: proGroupPayloadSchema }],
    alias: "renameProGroup",
  },
  {
    method: "get",
    path: proGroupsMembersPath,
    response: z.array(proHireProSchema),
    alias: "proGroupMembers",
  },
  {
    method: "post",
    path: proGroupsMembersPath,
    response: z.object({ pro_id: z.number() }),
    parameters: [{ name: "Pro ID", type: "Body", schema: z.object({ pro_id: z.number() }) }],
    alias: "addMemberToProGroup",
  },
  {
    method: "delete",
    path: proGroupsMemberPath,
    response: proHireProSchema,
    alias: "removeMemberFromProGroup",
  },
]);
