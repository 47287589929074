import { useAtomValue } from "jotai";
import React, { Suspense } from "react";

import { LoaderLogo } from "components/Loader";
import { activeLicenseAtom } from "services/globalState";

const HireRouter = React.lazy(() => import("./HireRouter").then((module) => ({ default: module.HireRouter })));
const MarketplaceRouter = React.lazy(() =>
  import("./MarketplaceRouter").then((module) => ({ default: module.MarketplaceRouter })),
);

export function ModeGuard() {
  const activeLicense = useAtomValue(activeLicenseAtom);

  return (
    <Suspense fallback={<LoaderLogo />}>
      {activeLicense === "hire" && <HireRouter />}
      {activeLicense === "marketplace" && <MarketplaceRouter />}
    </Suspense>
  );
}
