import React from "react";

import type { IconProps } from "./types";

export const Automation = React.forwardRef<SVGSVGElement, IconProps>(({ color = "#262626", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9323 0.721796C9.20378 0.839641 9.36506 1.12235 9.32836 1.41602L8.75536 6H14.0002C14.2588 6 14.4942 6.14964 14.6039 6.38389C14.7136 6.61815 14.6779 6.89473 14.5123 7.09345L7.84565 15.0935C7.65619 15.3208 7.33952 15.396 7.06804 15.2782C6.79657 15.1603 6.63528 14.8776 6.67199 14.584L7.24498 10H2.00017C1.74149 10 1.50616 9.85036 1.39644 9.6161C1.28672 9.38184 1.32242 9.10526 1.48802 8.90654L8.15469 0.90654C8.34415 0.679184 8.66082 0.60395 8.9323 0.721796ZM3.42353 8.66666H8.00017C8.19139 8.66666 8.37341 8.74877 8.49996 8.89213C8.62651 9.03548 8.68541 9.22628 8.66169 9.41602L8.27706 12.493L12.5768 7.33333H8.00017C7.80895 7.33333 7.62694 7.25122 7.50039 7.10786C7.37384 6.96451 7.31493 6.77372 7.33865 6.58397L7.72328 3.50697L3.42353 8.66666Z"
        fill={color}
      />
    </svg>
  );
});
Automation.displayName = "Lightening";
