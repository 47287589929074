import React from "react";

import type { IconProps } from "./types";

export const Link = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M6.65625 8C6.40625 8 6.20833 8.125 6.0625 8.375C5.9375 8.60417 5.95833 8.83333 6.125 9.0625C6.9375 10.1458 8.01042 10.6875 9.34375 10.6875C10.4479 10.6875 11.3854 10.2917 12.1562 9.5L14.1875 7.5C14.9167 6.70833 15.2812 5.78125 15.2812 4.71875C15.2812 3.61458 14.8854 2.67708 14.0938 1.90625C13.3229 1.11458 12.3854 0.71875 11.2812 0.71875C10.2188 0.71875 9.29167 1.09375 8.5 1.84375L7.375 2.96875C7.22917 3.11458 7.15625 3.27083 7.15625 3.4375C7.15625 3.5625 7.1875 3.67708 7.25 3.78125C7.3125 3.88542 7.39583 3.96875 7.5 4.03125C7.60417 4.09375 7.71875 4.125 7.84375 4.125C8.01042 4.125 8.16667 4.0625 8.3125 3.9375L9.4375 2.78125C9.95833 2.28125 10.5729 2.03125 11.2812 2.03125C12.0312 2.03125 12.6667 2.29167 13.1875 2.8125C13.7083 3.33333 13.9688 3.96875 13.9688 4.71875C13.9688 5.42708 13.7188 6.04167 13.2188 6.5625L11.2188 8.5625C10.6979 9.08333 10.0729 9.34375 9.34375 9.34375C8.44792 9.34375 7.72917 8.98958 7.1875 8.28125C7.0625 8.09375 6.88542 8 6.65625 8ZM9.34375 8C9.59375 8 9.78125 7.88542 9.90625 7.65625C10.0521 7.40625 10.0417 7.16667 9.875 6.9375C9.0625 5.85417 7.98958 5.3125 6.65625 5.3125C5.55208 5.3125 4.61458 5.70833 3.84375 6.5L1.8125 8.5C1.08333 9.29167 0.71875 10.2188 0.71875 11.2812C0.71875 12.3854 1.10417 13.3229 1.875 14.0938C2.66667 14.8854 3.61458 15.2812 4.71875 15.2812C5.78125 15.2812 6.70833 14.9062 7.5 14.1562L8.625 13.0312C8.75 12.8854 8.8125 12.7292 8.8125 12.5625C8.8125 12.375 8.75 12.2188 8.625 12.0938C8.5 11.9479 8.34375 11.875 8.15625 11.875C7.96875 11.875 7.8125 11.9375 7.6875 12.0625L6.5625 13.2188C6.04167 13.7188 5.42708 13.9688 4.71875 13.9688C4.21875 13.9688 3.76042 13.8542 3.34375 13.625C2.94792 13.375 2.625 13.0417 2.375 12.625C2.14583 12.2083 2.03125 11.7604 2.03125 11.2812C2.03125 10.5729 2.28125 9.95833 2.78125 9.4375L4.78125 7.4375C5.30208 6.91667 5.92708 6.65625 6.65625 6.65625C7.55208 6.65625 8.27083 7.01042 8.8125 7.71875C8.9375 7.90625 9.11458 8 9.34375 8Z"
        fill={color}
      />
    </svg>
  );
});
Link.displayName = "Link";
