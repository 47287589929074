type REACT_ENVIRONMENT = {
  IS_LOCAL: boolean;
  IS_DEV: boolean;
  IS_PROD: boolean;
  REACT_APP_ENV: string;
  REACT_APP_SG_API_URL: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_STRIPE_KEY: string;
  REACT_APP_CLOUDINARY_CLOUD_NAME: string;
  REACT_APP_DEFAULT_LOCATION_IMAGE_ID: string;
  REACT_APP_GTM_ID: string;
  REACT_APP_GTM_AUTH: string;
  REACT_APP_GTM_ENV: string;

  REACT_APP_SENDBIRD_APP_ID: string;
  REACT_APP_LAUNCH_DARKLY_ID: string;
  REACT_APP_E2E_LAUNCH_DARKLY_FLAG_SOURCE: string | undefined;

  REACT_APP_NR_ACCOUNT_ID: string;
  REACT_APP_NR_APPLICATION_ID: string;
  REACT_APP_NR_LICENSE_KEY: string;
  REACT_APP_NR_BEACON: string;
  REACT_APP_BUSINESS_SUPPORT_URL: string;
  REACT_APP_CONTACT_SUPPORT_URL: string;

  REACT_APP_SEGMENT_WRITE_KEY: string;
  REACT_APP_GA_MEASUREMENT_ID: string;
  REACT_APP_BRAZE_KEY: string;
  REACT_APP_BRAZE_ENDPOINT: string;
  REACT_APP_GOOGLE_API_KEY: string;

  REACT_APP_TESTING_API_ACCESS_KEY?: string;
};

export const env: REACT_ENVIRONMENT = {
  IS_LOCAL: import.meta.env.DEV,
  IS_DEV: import.meta.env.REACT_APP_ENV !== "production" && import.meta.env.REACT_APP_ENV !== "staging",
  IS_PROD: import.meta.env.REACT_APP_ENV === "production",
  REACT_APP_ENV: import.meta.env.REACT_APP_ENV,
  REACT_APP_SG_API_URL: import.meta.env.REACT_APP_SG_API_URL,
  REACT_APP_AUTH0_DOMAIN: import.meta.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_AUDIENCE: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID: import.meta.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_STRIPE_KEY: import.meta.env.REACT_APP_STRIPE_KEY,
  REACT_APP_CLOUDINARY_CLOUD_NAME: import.meta.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_DEFAULT_LOCATION_IMAGE_ID: import.meta.env.REACT_APP_DEFAULT_LOCATION_IMAGE_ID,
  REACT_APP_GTM_ID: import.meta.env.REACT_APP_GTM_ID,
  REACT_APP_GTM_AUTH: import.meta.env.REACT_APP_GTM_AUTH,
  REACT_APP_GTM_ENV: import.meta.env.REACT_APP_GTM_ENV,
  REACT_APP_SENDBIRD_APP_ID: import.meta.env.REACT_APP_SENDBIRD_APP_ID,
  REACT_APP_LAUNCH_DARKLY_ID: import.meta.env.REACT_APP_LAUNCH_DARKLY_ID,
  REACT_APP_E2E_LAUNCH_DARKLY_FLAG_SOURCE: import.meta.env.REACT_APP_E2E_LAUNCH_DARKLY_FLAG_SOURCE,
  REACT_APP_NR_ACCOUNT_ID: import.meta.env.REACT_APP_NR_ACCOUNT_ID,
  REACT_APP_NR_APPLICATION_ID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
  REACT_APP_NR_LICENSE_KEY: import.meta.env.REACT_APP_NR_LICENSE_KEY,
  REACT_APP_NR_BEACON: import.meta.env.REACT_APP_NR_BEACON,
  REACT_APP_BUSINESS_SUPPORT_URL: import.meta.env.REACT_APP_BUSINESS_SUPPORT_URL,
  REACT_APP_CONTACT_SUPPORT_URL: import.meta.env.REACT_APP_CONTACT_SUPPORT_URL,
  REACT_APP_SEGMENT_WRITE_KEY: import.meta.env.REACT_APP_SEGMENT_WRITE_KEY,
  REACT_APP_GA_MEASUREMENT_ID: import.meta.env.REACT_APP_GA_MEASUREMENT_ID,
  REACT_APP_BRAZE_KEY: import.meta.env.REACT_APP_BRAZE_KEY,
  REACT_APP_BRAZE_ENDPOINT: import.meta.env.REACT_APP_BRAZE_ENDPOINT,
  REACT_APP_GOOGLE_API_KEY: import.meta.env.REACT_APP_GOOGLE_API_KEY,
  REACT_APP_TESTING_API_ACCESS_KEY: import.meta.env.REACT_APP_TESTING_API_ACCESS_KEY,
};
