import { useAtom } from "jotai";
import React from "react";
import { type NavigateFunction, matchPath, useLocation, useNavigate } from "react-router-dom";

import { locationOrgHooks } from "api/client";
import { ROUTES, locationRoutes } from "routing/Routes";
import { activeLocationIdAtom } from "./globalState";

export function useActiveLocationId() {
  return useAtom(activeLocationIdAtom);
}

const updateLocationInPath = (locationId: number, navigate: NavigateFunction) => {
  if (location.pathname.startsWith(ROUTES.LOCATIONS.path)) {
    const pathId = Number(location.pathname.split("/")[2]);
    if (!Number.isNaN(pathId) && pathId !== locationId && !location.pathname.endsWith(`${pathId}`)) {
      navigate(location.pathname.replace(`${pathId}`, `${locationId}`), { replace: true });
    }
  }
};

export function useActiveLocation() {
  const [activeLocationId, setActiveLocationId] = useAtom(activeLocationIdAtom);
  const activeLocation = locationOrgHooks.useLocation(
    { params: { locationId: activeLocationId ?? -1 } },
    { enabled: !!activeLocationId },
  ).data;

  const location = useLocation();
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate updates with route changes
  const setActiveLocation = React.useCallback((locationId: number) => {
    setActiveLocationId(locationId);
    updateLocationInPath(locationId, navigate);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate updates with route changes
  React.useEffect(() => {
    if (!activeLocation?.id) return;
    // skip redirect for location routes
    if (locationRoutes.some(({ exact, route }) => matchPath({ path: route.path, end: exact }, location.pathname))) {
      return;
    }

    // update path with id in case the one found was invalid
    setTimeout(() => updateLocationInPath(activeLocation.id, navigate), 0);
  }, [activeLocation?.id]);

  // biome-ignore lint/style/noNonNullAssertion: the active location is guaranteed to be set by a loading guard
  return React.useMemo(() => [activeLocation!, setActiveLocation] as const, [activeLocation, setActiveLocation]);
}

export function useActiveOrganization() {
  const [activeLocation] = useActiveLocation();

  const { data: organizations } = locationOrgHooks.useOrganizations(undefined, { staleTime: Number.POSITIVE_INFINITY });
  const activeOrganization = React.useMemo(
    () => organizations?.find((org) => org.id === activeLocation?.organization_id) ?? organizations?.[0],
    [organizations, activeLocation?.organization_id],
  );

  return activeOrganization;
}

export function useActiveMarketId() {
  return useActiveLocation()[0]?.market_id;
}
