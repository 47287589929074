import React from "react";

import type { IconProps } from "./types";

export const BookmarkOutline = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M5 4.17969C5 4.02344 5.03906 3.88021 5.11719 3.75C5.19531 3.61979 5.29948 3.51562 5.42969 3.4375C5.5599 3.35938 5.6901 3.32031 5.82031 3.32031H14.1797C14.388 3.32031 14.5703 3.41146 14.7266 3.59375C14.9089 3.75 15 3.94531 15 4.17969V15.8984L10.4688 12.6562C10.1562 12.4219 9.84375 12.4219 9.53125 12.6562L5 15.8984V4.17969ZM16.6797 17.5V4.17969C16.6797 3.47656 16.4323 2.89062 15.9375 2.42188C15.4427 1.92708 14.8568 1.67969 14.1797 1.67969H5.82031C5.14323 1.67969 4.55729 1.92708 4.0625 2.42188C3.56771 2.89062 3.32031 3.47656 3.32031 4.17969V17.5C3.32031 17.8385 3.46354 18.0859 3.75 18.2422C4.0625 18.3984 4.36198 18.3724 4.64844 18.1641L10 14.375L15.3516 18.1641C15.638 18.3724 15.9245 18.3984 16.2109 18.2422C16.5234 18.0859 16.6797 17.8385 16.6797 17.5Z"
          fill={color}
        />
      </svg>
    );
  },
);
BookmarkOutline.displayName = "BookmarkOutline";
