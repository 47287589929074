import {
  type UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { locationOrgHooks } from "api/client";
import { locationClient } from "api/clients/legacy/LocationClient";
import type { PageResult } from "api/django/PageResult";
import type { BillingInfo, Location, LocationStatus } from "api/resources/Location";

import type { PaginatedQueryHook, PaginationOptions, QueryHook } from "./types";
import { getNextPageParam } from "./utils";

export const LOCATIONS_BASE_KEY = "locations";
export const LOCATIONS_LIST = `${LOCATIONS_BASE_KEY}_LIST`;
export const LOCATIONS_GET = `${LOCATIONS_BASE_KEY}_GET`;
const LOCATIONS_GET_PAYMENT_METHOD = `${LOCATIONS_BASE_KEY}_GET_PAYMENT_METHOD`;

export type LocationQueryOptions = {
  name?: string;
  city?: string;
  creator_firstname?: string;
  creator_lastname?: string;
  organization_id?: string;
  pro_id?: number;
  market_id?: number;
  approval_status?: LocationStatus;
  ordering?: string;
  query?: "managed" | "unmanaged";
  id?: number;
};

export type PaginatedLocationQueryOptions = LocationQueryOptions & PaginationOptions;

const usePrefillLocationCache = () => {
  const queryClient = useQueryClient();

  // prefill cache with locations from the list query
  return (locationsPage: PageResult<Location>) => {
    locationsPage.results.forEach((location) => {
      const queryKey = locationOrgHooks.getKeyByAlias("location", { params: { locationId: location.id } });
      queryClient.setQueryData(queryKey, { ...queryClient.getQueryData(queryKey), ...location });
    });
    return locationsPage;
  };
};

export const useLocationsPaginated: PaginatedQueryHook<Location, PaginatedLocationQueryOptions> = (params, options) => {
  const prefillCache = usePrefillLocationCache();
  const paramsWithDefaults = { ordering: "created_ts", query: "managed", ...params, use_pagination: true };
  return useQuery({
    queryKey: [LOCATIONS_LIST, paramsWithDefaults],
    queryFn: () => locationClient.list(paramsWithDefaults).then(prefillCache),
    ...options,
  });
};

type InfiniteLocationQueryOptions = PaginatedLocationQueryOptions & { use_pagination: true };
export const useLocationsInfinite = (
  params?: LocationQueryOptions,
  options?: Omit<
    UseInfiniteQueryOptions<
      PageResult<Location>,
      unknown,
      PageResult<Location>,
      PageResult<Location>,
      Array<string | InfiniteLocationQueryOptions>
    >,
    "queryKey" | "queryFn" | "getNextPageParam"
  >,
) => {
  const prefillCache = usePrefillLocationCache();

  const paramsWithDefaults: InfiniteLocationQueryOptions = {
    ordering: "created_ts",
    query: "managed",
    page: 1,
    use_pagination: true,
    ...params,
  };
  return useInfiniteQuery({
    queryKey: [LOCATIONS_LIST, paramsWithDefaults],
    queryFn: ({ pageParam = paramsWithDefaults }) => locationClient.list(pageParam).then(prefillCache),
    getNextPageParam,
    ...options,
  });
};

export const locationQueryOptions = (locationId: number) => ({
  queryKey: locationOrgHooks.getKeyByAlias("location", { params: { locationId } }),
  queryFn: () => locationClient.retrieve(locationId),
});

export const useLocation: QueryHook<Location, { id?: number }> = ({ id }, options) => {
  return useQuery({
    ...locationQueryOptions(id!),
    enabled: !!id,
    ...(options ?? {}),
  });
};

export function useLocationUpdate() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["MUTATE_LOCATION"],
    mutationFn: (location: Partial<Location>) => locationClient.partialUpdate(location),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [LOCATIONS_LIST],
      }),
  });
  return mutation;
}

export const useBillingInfo: QueryHook<BillingInfo, { id?: number }> = ({ id }, options) =>
  useQuery({
    queryKey: [LOCATIONS_GET_PAYMENT_METHOD, id],
    queryFn: () => locationClient.getBillingInfo(id!),
    enabled: !!id,
    ...(options ?? {}),
  });
