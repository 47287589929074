import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import {
  jobPostSchema,
  jobSchema,
  stafferInterviewPostSchema,
  stafferInterviewSchema,
  stafferReviewSchema,
} from "api/resources/hire";
import { proContractorTypeSchema } from "api/resources/proPreferences";
import { makePaginatedResult, paginationParams } from "api/resources/resource";
import { skillSchema } from "api/resources/skills";

const jobsEndpoint = "/job-postings/";
const interviewsEndpoint = "hire/staffer/interviews/";

const hireApi = makeApi([
  {
    method: "get",
    path: jobsEndpoint,
    response: makePaginatedResult(jobSchema),
    parameters: [
      ...paginationParams,
      {
        name: "ordering",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    alias: "hireJobs",
  },
  {
    method: "post",
    path: jobsEndpoint,
    response: jobSchema,
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: jobPostSchema,
      },
    ],
    alias: "hirePostJob",
  },
  {
    method: "put",
    path: `${jobsEndpoint}:id/`,
    response: jobSchema,
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: jobPostSchema,
      },
    ],
    alias: "hireUpdateJob",
  },
  {
    method: "post",
    path: interviewsEndpoint,
    parameters: [
      {
        name: "Interview",
        type: "Body",
        schema: stafferInterviewPostSchema,
      },
    ],
    response: stafferInterviewPostSchema.extend({ id: z.string() }),
    alias: "interviewPost",
  },
  {
    method: "get",
    path: interviewsEndpoint,
    response: makePaginatedResult(stafferInterviewSchema),
    parameters: [
      ...paginationParams,
      {
        name: "ordering",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "job_posting_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "location_id",
        type: "Query",
        schema: z.number().optional(),
      },
      {
        name: "status",
        type: "Query",
        schema: stafferInterviewSchema.shape.status.optional(),
      },
    ],
    alias: "interviews",
  },
  {
    method: "get",
    path: `${interviewsEndpoint}upcoming/`,
    response: makePaginatedResult(stafferInterviewSchema),
    parameters: [
      ...paginationParams,
      {
        name: "ordering",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "status",
        type: "Query",
        schema: stafferInterviewSchema.shape.status.optional(),
      },
    ],
    alias: "interviewsUpcoming",
  },
  {
    method: "get",
    path: `${interviewsEndpoint}pay-due/`,
    response: makePaginatedResult(stafferInterviewSchema),
    parameters: [
      ...paginationParams,
      {
        name: "ordering",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "status",
        type: "Query",
        schema: stafferInterviewSchema.shape.status.optional(),
      },
    ],
    alias: "interviewsPayDue",
  },
  {
    method: "post",
    path: `${interviewsEndpoint}:interviewId/reviews/`,
    response: stafferReviewSchema,
    parameters: [
      {
        name: "Review",
        type: "Body",
        schema: stafferReviewSchema,
      },
    ],
    alias: "interviewsPostReview",
  },
  {
    method: "get",
    path: `${interviewsEndpoint}history/`,
    response: makePaginatedResult(stafferInterviewSchema),
    parameters: [
      ...paginationParams,
      {
        name: "ordering",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "status",
        type: "Query",
        schema: stafferInterviewSchema.shape.status.optional(),
      },
    ],
    alias: "interviewsHistory",
  },
  {
    method: "get",
    path: "/skills/?license=hire",
    response: z.array(skillSchema),
    alias: "hirePositions",
  },
  {
    method: "get",
    path: "/pro-contractor-type/?license=hire",
    parameters: paginationParams,
    response: makePaginatedResult(proContractorTypeSchema).transform(({ results }) => results),
    alias: "hireContractTypes",
  },
]);

export const { client, hooks } = makeClientWithHooks("hire", hireApi);
