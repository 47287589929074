import React from "react";

import type { IconProps } from "../types";

export const BartenderBeerAndWine = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <g clipPath="url(#clip0_2624_15878)">
          <path
            d="M9.59323 7.37052L9.57551 7.3528H9.50463L9.46919 7.55658L9.50463 7.33508C9.45147 7.32622 9.44261 7.29078 9.44261 7.29078V1.89489C9.44261 1.39871 9.0439 1 8.54772 1H5.26943C4.77326 1 4.37455 1.39871 4.37455 1.89489V7.29964C4.37455 7.29964 4.37455 7.29964 4.36569 7.31736C4.35683 7.32622 4.33025 7.34394 4.2948 7.3528C3.01893 7.60975 2 8.67298 2 10.0286V20.2179C2 21.8039 3.35562 23 4.95932 23H8.86669C10.4704 23 11.826 21.8039 11.826 20.2179V10.0286C11.826 8.74386 10.8691 7.66291 9.59323 7.37052ZM3.78091 10.064C3.78091 9.55014 4.2505 9.07169 4.95046 9.07169C5.60612 9.07169 6.15546 8.51349 6.15546 7.86669V5.97946H7.64398V7.82239C7.64398 8.15022 7.75916 8.46033 7.98067 8.6907C8.20218 8.92106 8.52114 9.02739 8.84897 9.02739C9.54007 9.02739 10.0185 9.54128 10.0185 10.0197V20.2533C10.0185 20.7672 9.54893 21.2457 8.84897 21.2457H4.95046C4.25936 21.2457 3.78091 20.7318 3.78091 20.2533V10.064ZM7.64398 3.01128V4.18083H6.15546V2.78977H7.63512V3.01128H7.64398Z"
            fill={color}
          />
          <path
            d="M21.767 20.9267H19.0469V17.7459C19.0469 17.7459 19.0647 17.7282 19.1001 17.7193C21.2797 17.1966 22.8214 14.9461 22.8214 12.3146C22.8214 11.2868 22.591 10.2501 22.1214 9.38181V9.35523L22.086 9.30207C21.9265 9.03626 21.643 8.86792 21.324 8.86792H15.0067C14.6877 8.86792 14.4042 9.03626 14.2447 9.30207L14.2092 9.35523V9.38181C13.7396 10.259 13.5093 11.2868 13.5093 12.3146C13.5093 14.9106 15.051 17.1877 17.2306 17.7193C17.2749 17.737 17.2749 17.7636 17.2749 17.7548V20.9356H14.6257C14.1295 20.9356 13.7308 21.3343 13.7308 21.8305C13.7308 22.3266 14.1295 22.7254 14.6257 22.7254H21.767C22.2632 22.7254 22.6619 22.3266 22.6619 21.8305C22.6619 21.3343 22.2632 20.9356 21.767 20.9356V20.9267ZM15.6092 10.64H20.7658C20.9607 11.1539 21.0759 11.7121 21.0759 12.2968C21.0759 14.441 19.7026 16.027 18.1964 16.027C16.6901 16.027 15.3168 14.441 15.3168 12.2968C15.3168 11.7209 15.4142 11.1361 15.6092 10.64Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2624_15878">
            <rect width="20.8216" height="22" fill="white" transform="translate(2 1)" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

BartenderBeerAndWine.displayName = "BartenderBeerAndWine";
