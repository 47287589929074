import React from "react";

import type { IconComponent } from "./types";

export const Add: IconComponent = React.forwardRef(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M13.7394 4.26064C15.0734 5.57128 15.7404 7.15106 15.7404 9C15.7404 10.8489 15.0734 12.4404 13.7394 13.7745C12.4287 15.0851 10.8489 15.7404 9 15.7404C7.15106 15.7404 5.55957 15.0851 4.22553 13.7745C2.91489 12.4404 2.25957 10.8489 2.25957 9C2.25957 7.15106 2.91489 5.57128 4.22553 4.26064C5.55957 2.9266 7.15106 2.25957 9 2.25957C10.8489 2.25957 12.4287 2.9266 13.7394 4.26064ZM14.8277 14.8277C16.4426 13.2128 17.25 11.2702 17.25 9C17.25 6.72979 16.4426 4.78723 14.8277 3.17234C13.2128 1.55745 11.2702 0.75 9 0.75C6.72979 0.75 4.78723 1.55745 3.17234 3.17234C1.55745 4.78723 0.75 6.72979 0.75 9C0.75 11.2702 1.55745 13.2128 3.17234 14.8277C4.78723 16.4426 6.72979 17.25 9 17.25C11.2702 17.25 13.2128 16.4426 14.8277 14.8277ZM9.73723 6.01596C9.73723 5.87553 9.70213 5.74681 9.63192 5.62979C9.58511 5.51277 9.50319 5.41915 9.38617 5.34894C9.26915 5.27872 9.14043 5.24362 9 5.24362C8.78936 5.24362 8.61383 5.32553 8.4734 5.48936C8.33298 5.62979 8.26277 5.80532 8.26277 6.01596V11.984C8.26277 12.1947 8.33298 12.3819 8.4734 12.5457C8.61383 12.6862 8.78936 12.7564 9 12.7564C9.21064 12.7564 9.38617 12.6862 9.5266 12.5457C9.66702 12.3819 9.73723 12.1947 9.73723 11.984V6.01596ZM6.01596 8.26277C5.80532 8.26277 5.61809 8.33298 5.45426 8.4734C5.31383 8.61383 5.24362 8.78936 5.24362 9C5.24362 9.14043 5.27872 9.26915 5.34894 9.38617C5.41915 9.50319 5.51277 9.59681 5.62979 9.66702C5.74681 9.71383 5.87553 9.73723 6.01596 9.73723H11.984C12.1947 9.73723 12.3702 9.66702 12.5106 9.5266C12.6745 9.38617 12.7564 9.21064 12.7564 9C12.7564 8.78936 12.6745 8.61383 12.5106 8.4734C12.3702 8.33298 12.1947 8.26277 11.984 8.26277H6.01596Z"
        fill={color}
      />
    </svg>
  );
});
Add.displayName = "Add";
Add.alias = ["plus"];
