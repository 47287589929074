import React from "react";

import type { IconProps } from "../types";

export const EventSetup = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.916667C0 0.410406 0.410406 0 0.916667 0H3.20833C3.71459 0 4.125 0.410406 4.125 0.916667V15.7162C5.15982 16.0242 5.97583 16.8402 6.28381 17.875H21.083C21.5893 17.875 21.9997 18.2854 21.9997 18.7917C21.9997 19.2979 21.5893 19.7083 21.083 19.7083H6.2838C5.88939 21.0336 4.66172 22 3.20833 22C1.43642 22 0 20.5636 0 18.7917C0 17.3383 0.966401 16.1106 2.29167 15.7162V1.83333H0.916667C0.410406 1.83333 0 1.42293 0 0.916667ZM4.5832 18.7727L4.58301 18.7917L4.5832 18.8107C4.57303 19.5613 3.96138 20.1667 3.20833 20.1667C2.44894 20.1667 1.83333 19.5511 1.83333 18.7917C1.83333 18.0323 2.44894 17.4167 3.20833 17.4167C3.96138 17.4167 4.57303 18.022 4.5832 18.7727ZM6.875 8.24999H5.95866C5.4524 8.24999 5.04199 7.83959 5.04199 7.33333V4.12499C5.04199 3.61873 5.4524 3.20833 5.95866 3.20833H21.0837C21.5899 3.20833 22.0003 3.61873 22.0003 4.12499V7.33333C22.0003 7.83959 21.5899 8.24999 21.0837 8.24999H20.1667V16.0417C20.1667 16.5479 19.7563 16.9583 19.25 16.9583H7.79167C7.28541 16.9583 6.875 16.5479 6.875 16.0417V8.24999ZM18.3333 8.24999V15.125H8.70833V8.24999H18.3333ZM6.87533 6.41666V5.04166H20.167V6.41666H6.87533Z"
        fill={color}
      />
    </svg>
  );
});

EventSetup.displayName = "EventSetup";
