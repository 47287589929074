import React from "react";

import type { IconProps } from "./types";

export const History = React.forwardRef<SVGSVGElement, IconProps>(({ color = "currentColor", ...props }, ref) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.26652 2.48361C1.4307 2.62412 1.51279 2.78806 1.51279 2.97541V5.75697L3.72921 3.67799C5.18337 2.226 6.94243 1.5 9.0064 1.5C11.0704 1.5 12.8294 2.2377 14.2836 3.71311C15.7612 5.16511 16.5 6.93326 16.5 9.01756C16.5 11.0785 15.7612 12.8466 14.2836 14.322C12.8294 15.774 11.0704 16.5 9.0064 16.5C7.38806 16.5 5.9339 16.0316 4.64392 15.0948C3.35394 14.1581 2.45096 12.952 1.93497 11.4766C1.84115 11.219 1.86461 10.9965 2.00533 10.8091C2.16951 10.5984 2.3806 10.493 2.63859 10.493C2.99041 10.493 3.22495 10.6569 3.34222 10.9848C3.76439 12.1557 4.49147 13.1159 5.52345 13.8653C6.55544 14.6148 7.71642 14.9895 9.0064 14.9895C10.6716 14.9895 12.0906 14.404 13.2633 13.233C14.436 12.0621 15.0224 10.6452 15.0224 8.98244C15.0224 7.90515 14.7527 6.90984 14.2132 5.99649C13.6738 5.05972 12.935 4.33372 11.9968 3.8185C11.0821 3.27986 10.0853 3.01054 9.0064 3.01054C7.34115 3.01054 5.92217 3.59602 4.74947 4.76698L2.65221 6.73419H5.242C5.45309 6.73419 5.629 6.81616 5.76972 6.98009C5.9339 7.12061 6.01599 7.29625 6.01599 7.50703C6.01599 7.69438 5.9339 7.87002 5.76972 8.03396C5.629 8.17447 5.45309 8.24473 5.242 8.24473H0.738806C0.551173 8.24473 0.375267 8.17447 0.211087 8.03396C0.0703625 7.87002 0 7.68267 0 7.4719V2.97541C0 2.78806 0.0703625 2.62412 0.211087 2.48361C0.375267 2.31967 0.551173 2.2377 0.738806 2.2377C0.949893 2.2377 1.1258 2.31967 1.26652 2.48361ZM9.14062 4.71094C9.28125 4.85156 9.35156 5.02734 9.35156 5.23828V9.28125L11.9531 10.582C12.2344 10.6992 12.375 10.9219 12.375 11.25C12.375 11.5078 12.2578 11.7188 12.0234 11.8828C11.7891 12.0234 11.543 12.0352 11.2852 11.918L8.26172 10.4062C8.00391 10.2656 7.875 10.043 7.875 9.73828V5.23828C7.875 5.02734 7.94531 4.85156 8.08594 4.71094C8.22656 4.57031 8.40234 4.5 8.61328 4.5C8.82422 4.5 9 4.57031 9.14062 4.71094Z"
        fill={color}
      />
    </svg>
  );
});
History.displayName = "History";
