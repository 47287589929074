import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInQuarters,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  toDate,
} from "date-fns";
import {
  secondsInDay,
  secondsInHour,
  secondsInMinute,
  secondsInMonth,
  secondsInQuarter,
  secondsInWeek,
  secondsInYear,
} from "date-fns/constants";

/**
 * The {@link formatRelative} function options.
 */
export interface IntlFormatDistanceOptions {
  /** The locale matching algorithm to use. Other value: 'lookup'. See MDN for details [Locale identification and negotiation](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation) */
  localeMatcher?: Intl.RelativeTimeFormatLocaleMatcher;
  /** The output message format. The values are 'auto' (e.g. `yesterday`), 'always'(e.g. `1 day ago`) */
  numeric?: Intl.RelativeTimeFormatNumeric;
  /** The length of the result. The values are: 'long' (e.g. `1 month`), 'short' (e.g. 'in 1 mo.'), 'narrow' (e.g. 'in 1 mo.'). The narrow one could be similar to the short one for some locales. */
  style?: Intl.RelativeTimeFormatStyle;
}

/**
 * @name intlFormatDistance
 * @category Common Helpers
 * @summary Formats distance between two dates in a human-readable format
 *
 * Adaption of {@link https://date-fns.org/docs/intlFormatDistance} to use days instead of calendar days.
 * @description
 * The function calculates the difference between two dates and formats it as a human-readable string.
 *
 * The function will pick the most appropriate unit depending on the distance between dates. For example, if the distance is a few hours, it might return `x hours`. If the distance is a few months, it might return `x months`.
 *
 * You can also specify a unit to force using it regardless of the distance to get a result like `123456 hours`.
 *
 * See the table below for the unit picking logic:
 *
 * | Distance between dates | Result (past)  | Result (future) |
 * | ---------------------- | -------------- | --------------- |
 * | 0 seconds              | now            | now             |
 * | 1-59 seconds           | X seconds ago  | in X seconds    |
 * | 1-59 minutes           | X minutes ago  | in X minutes    |
 * | 1-23 hours             | X hours ago    | in X hours      |
 * | 1 day                  | yesterday      | tomorrow        |
 * | 2-6 days               | X days ago     | in X days       |
 * | 7 days                 | last week      | next week       |
 * | 8 days-1 month         | X weeks ago    | in X weeks      |
 * | 1 month                | last month     | next month      |
 * | 2-3 months             | X months ago   | in X months     |
 * | 1 quarter              | last quarter   | next quarter    |
 * | 2-3 quarters           | X quarters ago | in X quarters   |
 * | 1 year                 | last year      | next year       |
 * | 2+ years               | X years ago    | in X years      |
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments. Allows to use extensions like [`UTCDate`](https://github.com/date-fns/utc).
 *
 * @param date - The date
 * @param baseDate - The date to compare with.
 * @param options - An object with options.
 * See MDN for details [Locale identification and negotiation](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation)
 * The narrow one could be similar to the short one for some locales.
 *
 * @returns The distance in words according to language-sensitive relative time formatting.
 *
 * @throws {RangeError} `date` must not be Invalid Date
 * @throws {RangeError} `baseDate` must not be Invalid Date
 * @throws {RangeError} `options.locale` must not be invalid locale
 * @throws {RangeError} `options.localeMatcher` must not be invalid localeMatcher
 * @throws {RangeError} `options.numeric` must not be invalid numeric
 * @throws {RangeError} `options.style` must not be invalid style
 *
 * @example
 * // What is the distance between the dates when the fist date is after the second?
 * intlFormatDistance(
 *   new Date(1986, 3, 4, 11, 30, 0),
 *   new Date(1986, 3, 4, 10, 30, 0)
 * )
 * //=> 'in 1 hour'
 *
 * // What is the distance between the dates when the fist date is before the second?
 * intlFormatDistance(
 *   new Date(1986, 3, 4, 10, 30, 0),
 *   new Date(1986, 3, 4, 11, 30, 0)
 * )
 * //=> '1 hour ago'
 *
 * @example
 * // Use the unit option to force the function to output the result in quarters. Without setting it, the example would return "next year"
 * intlFormatDistance(
 *   new Date(1987, 6, 4, 10, 30, 0),
 *   new Date(1986, 3, 4, 10, 30, 0),
 *   { unit: 'quarter' }
 * )
 * //=> 'in 5 quarters'
 *
 * @example
 * // Use the locale option to get the result in Spanish. Without setting it, the example would return "in 1 hour".
 * intlFormatDistance(
 *   new Date(1986, 3, 4, 11, 30, 0),
 *   new Date(1986, 3, 4, 10, 30, 0),
 *   { locale: 'es' }
 * )
 * //=> 'dentro de 1 hora'
 *
 * @example
 * // Use the numeric option to force the function to use numeric values. Without setting it, the example would return "tomorrow".
 * intlFormatDistance(
 *   new Date(1986, 3, 5, 11, 30, 0),
 *   new Date(1986, 3, 4, 11, 30, 0),
 *   { numeric: 'always' }
 * )
 * //=> 'in 1 day'
 *
 * @example
 * // Use the style option to force the function to use short values. Without setting it, the example would return "in 2 years".
 * intlFormatDistance(
 *   new Date(1988, 3, 4, 11, 30, 0),
 *   new Date(1986, 3, 4, 11, 30, 0),
 *   { style: 'short' }
 * )
 * //=> 'in 2 yr'
 */
export function formatRelative<DateType extends Date>(
  date: DateType | number,
  baseDate: DateType | number,
  options?: IntlFormatDistanceOptions,
): string {
  let value = 0;
  let unit: Intl.RelativeTimeFormatUnit;
  const dateLeft = toDate(date);
  const dateRight = toDate(baseDate);

  // Get the unit based on diffInSeconds calculations if no unit is specified
  const diffInSeconds = differenceInSeconds(dateLeft, dateRight); // The smallest unit

  if (Math.abs(diffInSeconds) < secondsInMinute) {
    value = differenceInSeconds(dateLeft, dateRight);
    unit = "second";
  } else if (Math.abs(diffInSeconds) < secondsInHour) {
    value = differenceInMinutes(dateLeft, dateRight);
    unit = "minute";
  } else if (Math.abs(diffInSeconds) < secondsInDay && Math.abs(differenceInDays(dateLeft, dateRight)) < 1) {
    value = differenceInHours(dateLeft, dateRight);
    unit = "hour";
  } else if (
    Math.abs(diffInSeconds) < secondsInWeek &&
    // biome-ignore lint/suspicious/noAssignInExpressions: copied from date-fns
    (value = differenceInDays(dateLeft, dateRight)) &&
    Math.abs(value) < 7
  ) {
    unit = "day";
  } else if (Math.abs(diffInSeconds) < secondsInMonth) {
    value = differenceInWeeks(dateLeft, dateRight);
    unit = "week";
  } else if (Math.abs(diffInSeconds) < secondsInQuarter) {
    value = differenceInMonths(dateLeft, dateRight);
    unit = "month";
  } else if (Math.abs(diffInSeconds) < secondsInYear) {
    if (differenceInQuarters(dateLeft, dateRight) < 4) {
      // To filter out cases that are less than a year but match 4 quarters
      value = differenceInQuarters(dateLeft, dateRight);
      unit = "quarter";
    } else {
      value = differenceInYears(dateLeft, dateRight);
      unit = "year";
    }
  } else {
    value = differenceInYears(dateLeft, dateRight);
    unit = "year";
  }

  const rtf = new Intl.RelativeTimeFormat(undefined, {
    localeMatcher: options?.localeMatcher,
    numeric: options?.numeric ?? "auto",
    style: options?.style,
  });

  return rtf.format(value, unit);
}
