import { type ZodDefault, type ZodNumber, z } from "zod";

import { offerableGigSchema } from "./gigs";
import { makeApiResource } from "./resource";

export type PROfileExperienceData = z.infer<typeof proProfileExperienceSectionSchema>;
export const proProfileExperienceSectionSchema = z.array(
  makeApiResource({
    business_name: z.string(),
    start_date: z.string().transform((dateString) => new Date(dateString)),
    end_date: z
      .string()
      .nullable()
      .transform((dateString) => (dateString ? new Date(dateString) : new Date())),
    position_skill_object: z
      .object({
        id: z.number(),
        name: z.string(),
      })
      .nullable(),
    position: z.string().nullable(),
    created_ts: z.string(),
  }),
);

const stars = [1, 2, 3, 4, 5] as const;
type Rating = (typeof stars)[number];
const categories = ["punctuality", "preparedness", "skillset"] as const;
type Category = (typeof categories)[number];

export type PROfileReviewsData = z.infer<typeof proProfileReviewsSectionSchema>;
export const proProfileReviewsSectionSchema = z.object({
  count: z.number().default(0),
  avg_rating: z.coerce.number().default(0),
  ...categories.reduce(
    (acc, cat) => {
      acc[`${cat}_avg_rating`] ??= z.coerce.number().default(0);
      return acc;
    },
    {} as {
      [key in `${Category}_avg_rating`]: ZodDefault<ZodNumber>;
    },
  ),
  ...stars.reduce(
    (acc, star) => {
      acc[`overall_percentage_${star}_stars`] ??= z.coerce.number().default(0);
      return acc;
    },
    {} as {
      [key in `overall_percentage_${Rating}_stars`]: ZodDefault<ZodNumber>;
    },
  ),
});

export const proGigHistorySummarySchema = z.array(
  z.object({
    skill_id: z.number(),
    skill_name: z.string(),
    gigs_worked: z.number(),
    avg_wage_amount: z.number(),
    rating: z.number(),
  }),
);
export type ProGigHistorySummary = z.infer<typeof proGigHistorySummarySchema>;

export const proHistoryGigSchema = offerableGigSchema
  .pick({ id: true, start_time: true, wage_amount: true, description: true, is_filled: true })
  .extend({
    rating: z.coerce.number(),
    location: offerableGigSchema.shape.location.pick({ id: true, image_public_id: true, name: true }),
  });
export type ProHistoryGig = z.infer<typeof proHistoryGigSchema>;
